import { thunk } from "easy-peasy";
import moment from "moment";

import {
  listUserProjectTasks,
  listProjects,
  listProjectTasks,
  loadTask,
  removeTasksFromProject,
  listNonRoutineOptions,
  addNonRoutineTaskToProject,
  searchAndQualify,
  addInspectionRejection,
  getTaskCard,
  searchProjectTasksServerSideApiCall,
} from "../../services/cfbsKiosk";
import { createPutInspectionRejectionPayload } from "./payloadBuilder";
import { listProjectTasksAndSetFilter } from "./helpers";
import { handleAcumaticaError } from "../../utils/handleAcumaticaError";
import createNonRoutineTaskCard from "../../reports/nonRoutine";
import {
  addServicePackageApiCall,
  addServiceProfileApiCall,
  getServicePackagesApiCall,
  getTreeServiceProfileBottomApiCall,
  getTreeServiceProfileTopApiCall,
} from "../../services/nonroutine";
import { toggleBillableTaskApiCall } from "../../services/task";
const projectThunks = {
  /* #region  getProjectTask */
  getProjectTask: thunk(async () => ({})),
  /* #endregion */

  /* #region  getProjectTasks */
  getProjectTasks: thunk(async (actions, param, helpers) => {
    let employeeId;
    let fromLogin = false;
    let kioskScanTimeLimit = null;

    //check for scan time limit
    if (helpers.getState().user.kioskScanTimeLimit) {
      kioskScanTimeLimit = helpers.getState().user.kioskScanTimeLimit;
    }

    //check if param is an object from login, if not param is a string
    if (typeof param === "object") {
      employeeId = param.employeeId;
      fromLogin = param.fromLogin;
    } else {
      employeeId = param;
    }

    try {
      const url = helpers.getState().apiBaseURL;
      const myProjects = await listUserProjectTasks(url, employeeId);

      //lock the queue to prevent future scans of new task cards if setup requires
      fromLogin &&
        kioskScanTimeLimit &&
        myProjects.data.length &&
        actions.setQueueLocked();

      const curProjectCount = helpers.getState().projectTasks;

      myProjects.data.length === 1 &&
        kioskScanTimeLimit &&
        !fromLogin &&
        (!curProjectCount || curProjectCount.length === 0) &&
        actions.setQueueScanCountDownIsActive(true);

      myProjects.data.length === 0 &&
        kioskScanTimeLimit &&
        !fromLogin &&
        (!curProjectCount || curProjectCount.length === 0) &&
        actions.setQueueScanCountDownIsActive(false) &&
        actions.setQueueScanLockSeconds(kioskScanTimeLimit) &&
        actions.clearQueueLocked();

      actions.setProjectTasks(myProjects.data);
      // actions.setLoading(false);
    } catch (err) {
      console.log(err);
      const msg = handleAcumaticaError(err);
      actions.setAlerts({ error: msg, type: "danger" });
    }
  }),
  /* #endregion */

  /* #region  searchProjects */
  searchProjects: thunk(async (actions, searchValue, helpers) => {
    try {
      const url = helpers.getState().apiBaseURL;
      const foundProjects = await listProjects(url, searchValue);
      actions.setFoundProjects(foundProjects.data);
      actions.setLoading(false);
    } catch (err) {
      console.log(err);
      const msg = handleAcumaticaError(err);
      actions.setAlerts({ error: msg, type: "danger" });
    }
  }),
  /* #endregion */

  /* #region  getTask */
  getTask: thunk(async (actions, taskId, helpers) => {
    try {
      //
      actions.setLoading(true);
      const url = helpers.getState().apiBaseURL;
      const task = await loadTask(url, taskId);
      if (task.data && task.data.length) {
        actions.setPage("search");

        const foundProjects = await listProjects(url, task.data[0].AircraftCD);
        actions.setFoundProjects(foundProjects.data);

        actions.setFilteredFoundProject(task.data[0].ProjectCD);

        await listProjectTasksAndSetFilter(
          url,
          task.data[0].ProjectCD,
          actions
        );
        actions.setFilteredTasks(task.data[0].TaskCD);
      }

      actions.setLoading(false);
    } catch (err) {
      actions.setLoading(false);
      console.log(err);
      const msg = handleAcumaticaError(err);
      actions.setAlerts({ error: msg, type: "danger" });
    }
  }),
  /* #endregion */

  /* #region  getTaskForFilteredProject */
  getTaskForFilteredProject: thunk(async (actions, projectId, helpers) => {
    try {
      const {
        apiBaseURL: url,
        user,
        taskServerSideSearchValue,
      } = helpers.getState();

      let tasks;
      if (user.searchTasksServerSide) {
        tasks = await searchProjectTasksServerSideApiCall(url, {
          SearchProject: projectId,
          SearchValue: taskServerSideSearchValue,
        });

        if (tasks.data.length) {
          actions.setFilteredFoundProject(projectId);
          actions.setChosenProjectIdAction(null);
          actions.setTaskServerSideSearchValueAction("");
        }
      } else {
        tasks = await listProjectTasks(url, projectId);
      }

      actions.setTasksByFilteredFoundProject(tasks.data);
      actions.setLoading(false);
    } catch (err) {
      console.log(err);
      const msg = handleAcumaticaError(err);
      actions.setAlerts({ error: msg, type: "danger" });
    }
  }),
  /* #endregion */

  /* #region  addTaskToProjectTasksByCode */
  addTaskToProjectTasksByCode: thunk(async (actions, barcode, helpers) => {
    actions.setLoading(true);

    try {
      const url = helpers.getState().apiBaseURL;
      const qualifications = helpers.getState().qualifications;
      const tenant = helpers.getState().tenant;
      const accountId = helpers.getState().user.AccountID;
      const employeeId = helpers.getState().user.EmployeeID;
      const contactId = helpers.getState().user.ContactID;
      const employeeRefNo = helpers.getState().user.EmployeeRefNo;
      const acmVersion = helpers.getState().user.ACMVersion;
      const inspector = helpers.getState().user.Inspector;
      const bypassQuals = helpers.getState().user.BypassQuals;

      let projectId = null;
      const curProjects = helpers.getState().projectTasks;
      curProjects &&
        curProjects.length &&
        (projectId = curProjects[0].WorkOrderID);

      const newTask = await searchAndQualify(url, {
        barcode,
        qualifications,
        tenant,
        accountId,
        employeeId,
        employeeRefNo,
        contactId,
        acmVersion,
        projectId,
        inspector,
        bypassQuals,
      });

      if (newTask.data.length === 0) {
        actions.setAlerts({
          error: "No task was found using this barcode",
          type: "warning",
        });
      } else {
        //we only get here is there is a projectTask to add to queue
        const curProjectCount = helpers.getState().projectTasks;
        const queueScanLockSeconds = helpers.getState().queueScanLockSeconds;
        if (
          (!curProjectCount || curProjectCount.length === 0) &&
          queueScanLockSeconds
        ) {
          actions.setQueueScanCountDownIsActive(true);
        }
        actions.setProjectTasks(newTask.data);
      }
    } catch (err) {
      console.error(err);
      actions.setAlerts({ error: handleAcumaticaError(err), type: "danger" });
    }

    actions.setBarCode("");
    actions.setLoading(false);
  }),
  /* #endregion */

  /* #region  removeTasksFromProjectTasks */
  removeTasksFromProjectTasks: thunk(async (actions, project, helpers) => {
    actions.setLoading(true);

    let { EmployeeID, EmployeeRefNo, ContactID, LegalName, Approver } =
      helpers.getState().user;

    try {
      project.EmployeeRefNo = { value: EmployeeRefNo };
      project.ContactID = { value: ContactID };
      project.EmployeeID = { value: EmployeeID };
      project.LegalName = { value: LegalName };
      Approver && (project.Approver = { value: Approver });

      const url = helpers.getState().apiBaseURL;

      await removeTasksFromProject(url, project);

      actions.setProjectTasks([]);

      // test to give Acumatica a second to catch up
      //await removeTasksFromProject(url, EmployeeID, projectsToDelete);
      await actions.getProjectTasks(EmployeeID);
      // added for testing

      actions.cleanupSearch();
      actions.cleanupCurrentTask();
      if (project.signout) {
        actions.signOut();
      }
      // actions.setLoading(false);
    } catch (err) {
      console.log(err);
      const msg = handleAcumaticaError(err);
      actions.setAlerts({ error: msg, type: "danger" });
    }

    actions.setLoading(false);
  }),
  /* #endregion */

  /* #region  getNonRoutineOptions */
  getNonRoutineOptions: thunk(async (actions, placeholder, helpers) => {
    try {
      const url = helpers.getState().apiBaseURL;
      const options = await listNonRoutineOptions({ url });
      actions.setNonRoutineOptions(options.data);
      //actions.setLoading(false);
    } catch (err) {
      console.log(err);
      const msg = handleAcumaticaError(err);
      actions.setAlerts({ error: msg, type: "danger" });
    }
  }),
  /* #endregion */

  /* #region  addNonRoutineTask */
  addNonRoutineTask: thunk(async (actions, task, helpers) => {
    task.ParentTaskRefID =
      helpers.getState().currentTask.ProjectTaskIDInt.value;
    task.WorkOrder = helpers.getState().currentTask.ProjectIDInt.value;
    //task.Inspector = helpers.getState().user.EmployeeID;
    helpers.getState().currentTask.ReleaseStatement &&
      helpers.getState().currentTask.ReleaseStatement.value &&
      (task.ReleaseStatement =
        helpers.getState().currentTask.ReleaseStatement.value);

    const printNonRoutineTaskCard =
      helpers.getState().currentTask.printNonRoutineTaskCard;

    try {
      const url = helpers.getState().apiBaseURL;
      const nonRoutineTask = await addNonRoutineTaskToProject(url, task);

      const taskId =
        nonRoutineTask.data && nonRoutineTask.data.NonRoutineTaskCD
          ? nonRoutineTask.data.NonRoutineTaskCD.value
          : "";
      task.NonRoutineTaskCD = taskId;
      actions.clearNonRoutine();
      actions.setSTSZoneAction(
        helpers.getState().currentTask.STSZone
          ? helpers.getState().currentTask.STSZone.value
          : ""
      );
      actions.setLoading(false);
      actions.setAlerts({
        error: `Non Routine ${taskId} Created Successfully`,

        type: "success",
      });
      if (printNonRoutineTaskCard) {
        const Station =
          helpers.getState().currentTask.Station &&
          helpers.getState().currentTask.Station.value
            ? helpers.getState().currentTask.Station.value
            : "";
        task.Station = Station;

        const dateFormat = helpers.getState().user.dateFormat;
        dateFormat &&
          (task.DateRaised = moment(new Date(task.DateRaised)).format(
            dateFormat
          ));

        // task.RaisedBy = helpers.getState().user.LastName;
        const [blob, barcodeBlob] = await Promise.all([
          actions.getReportImage(),
          actions.getBarcodeImageThunk(taskId),
        ]);
        task.RaisedBy = helpers.getState().user.LastName;
        createNonRoutineTaskCard(blob, barcodeBlob, {
          ...task,
          stsZone: helpers.getState().user.stsZone,
        });
      }
    } catch (err) {
      console.log(err);
      const msg = handleAcumaticaError(err);
      actions.setAlerts({ error: msg, type: "danger" });
    }
  }),
  putInspectionRejection: thunk(async (actions, history, helpers) => {
    const currentTask = helpers.getState().currentTask;
    const currentTaskId = currentTask.ProjectTaskID.value;
    const rejectedReason = helpers.getState().rejectedReason;

    try {
      actions.setShowInspectionRejectModal(false);
      actions.setLoading(true);
      let url = helpers.getState().apiBaseURL;
      const payload = await createPutInspectionRejectionPayload({
        rejectionDescription: rejectedReason,
        projectNoteId: currentTask.ProjectNoteID.value,
        taskNoteId: currentTask.TaskNoteID.value,
      });
      const inspectionRejection = await addInspectionRejection(
        url,
        {
          ...payload,
          process: "inspectionReject",
          RefNoteID: currentTask.RefNoteID,
          EmployeeCD: currentTask.EmployeeCD,
        },
        currentTaskId
      );

      if (
        inspectionRejection.data.RejectedReason &&
        inspectionRejection.data.RejectedReason.value
      ) {
        const updatePayload = {
          reason: inspectionRejection.data.RejectedReason.value,
          projectTasks: helpers.getState().projectTasks,
          currentTaskId,
        };
        actions.updateRejectedReasonForTask(updatePayload);
      }

      actions.setProjectTasks([]);

      await actions.getProjectTasks(helpers.getState().user.EmployeeID);
      actions.cleanupSearch();
      actions.setPage("home");
      history.push("/");

      actions.setLoading(false);
      actions.setRejectedReason("");
    } catch (err) {
      const msg = handleAcumaticaError(err);
      actions.setAlerts({ error: msg, type: "danger" });
    }
  }),
  /* #endregion */

  getTaskCardPDF: thunk(async (actions, task, helpers) => {
    actions.setLoading(true);
    let url = helpers.getState().apiBaseURL;
    await getTaskCard(url);
    actions.setLoading(false);
  }),
  addServiceTaskThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoading(true);

    try {
      const serviceType = helpers.getState().serviceType;
      const ProjectID = helpers.getState().currentTask.WorkOrderID.value;

      let data;
      if (serviceType === "Service Packages") {
        data = await addServicePackageApiCall({
          ProjectID,
          ServicePackagesToAdd:
            helpers.getState().chosenService.ServicePackageCD,
        });
      } else if (serviceType === "Service Profiles") {
        data = await addServiceProfileApiCall({
          ProjectID,
          ServiceProfilesToAdd: helpers
            .getState()
            .serviceProfileList.filter((svcProfile) => svcProfile.chosen)
            .map((svcProfile) => svcProfile.SvcProfileDescription)
            .join(","),
        });
      }

      actions.setAlerts({
        error: `New task added for Project ID ${data.ProjectID.value}`,
        type: "success",
      });
      actions.setServiceTypeAction(serviceType);
    } catch (err) {
      console.error(err);
      actions.setAlerts({
        error: handleAcumaticaError(err),
        type: "error",
      });
    }

    actions.setLoading(false);
  }),
  getServicePackageListThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoading(true);

    try {
      actions.setServiceTypeAction("Service Packages");
      actions.setServicePackageListAction(await getServicePackagesApiCall());
      actions.setChosenServiceAction(null);
    } catch (err) {
      console.error(err);
      actions.setAlerts({
        error: handleAcumaticaError(err),
        type: "error",
      });
    }

    actions.setLoading(false);
  }),
  getRootServiceProfileOptionsThunk: thunk(async (actions) => {
    actions.setLoading(true);

    try {
      actions.setServiceTypeAction("Service Profiles");
      actions.setRootServiceProfileOptionsAction(
        await getTreeServiceProfileTopApiCall()
      );
      actions.setChosenRootServiceProfileOptionAction({
        Description: "",
        RootID: "",
        Sortrder: 0,
        ServiceProfileCategoryID: "",
      });
      actions.setChosenServiceAction(null);
    } catch (err) {
      console.error(err);
      actions.setAlerts({
        error: handleAcumaticaError(err),
        type: "error",
      });
    }

    actions.setLoading(false);
  }),
  chooseRootServiceProfileOptionThunk: thunk(
    async (actions, chosenOption, helpers) => {
      actions.setLoading(true);

      try {
        actions.setServiceOptionListAction(chosenOption.SvcProfileDescription);

        const options = await getTreeServiceProfileBottomApiCall(
          chosenOption.RootId
        );
        actions.setChildServiceProfileOptionsAction(options);
        actions.setChosenRootServiceProfileOptionAction(chosenOption);

        const childOptions = options.filter(
          (option) => option.ParentId === chosenOption.RootId
        );
        childOptions.length &&
          actions.setTreeChildServiceProfileDropdownsAction({
            index: 0,
            options: childOptions,
          });

        actions.setChosenServiceAction(null);
      } catch (err) {
        console.error(err);
        actions.setAlerts({
          error: handleAcumaticaError(err),
          type: "error",
        });
      }

      actions.setLoading(false);
    }
  ),
  chooseChildServiceProfileOptionThunk: thunk(
    async (actions, { index, chosenOption }, helpers) => {
      actions.setLoading(true);

      try {
        actions.setServiceOptionListAction(chosenOption.SvcProfileDescription);

        const filteredChildDropdownOptions = helpers
          .getState()
          .childServiceProfileOptions.filter(
            (option) => option.ParentId === chosenOption.CategoryId
          );

        actions.setTreeChildServiceProfileDropdownsAction({
          index: index + 1,
          options: filteredChildDropdownOptions,
        });
        actions.setChosenTreeChildServiceProfileOptionsAction({
          index,
          option: chosenOption,
        });
        actions.setChosenServiceAction(null);
      } catch (err) {
        console.error(err);
        actions.setAlerts({
          error: handleAcumaticaError(err),
          type: "error",
        });
      }

      actions.setLoading(false);
    }
  ),
  toggleBillableTaskThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoading(true);

    try {
      const { NonBillable, WorkOrderID, ProjectTaskID } =
        helpers.getState().currentTask;

      await toggleBillableTaskApiCall({
        NonBillable: NonBillable ? !NonBillable.value : true,
        ProjectID: WorkOrderID.value,
        ProjectTaskID: ProjectTaskID.value,
      });
      actions.toggleCurrentBillableTaskAction();
      await actions.getProjectTasks(helpers.getState().user.EmployeeID);
    } catch (err) {
      console.error(err);
      actions.setAlerts({
        error: handleAcumaticaError(err),
        type: "error",
      });
    }

    actions.setLoading(false);
  }),
};

export default projectThunks;
