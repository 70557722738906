import { axios } from ".";

const azureAxios = axios.create();

// Employee Endpoints
export const loadEmployee = async (url, query) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios.defaults.baseURL = url;
      const employee = await axios.get(`/api/v1/employee?${query}`);

      if (employee && employee.data) {
        return resolve(employee);
      }
      return reject("No employee data was returned");
    } catch (error) {
      return reject(error);
    }
  });
};

// Centrik Endpoints
export const listValidities = async (url, email) => {
  return new Promise(async (resolve, reject) => {
    try {
      let validities;
      if (email) {
        axios.defaults.baseURL = url;
        validities = await axios.get(`/api/v1/centrik/${email}`);
      }
      if (validities) {
        return resolve(validities);
      }
      return reject("No validities found");
    } catch (error) {
      return reject(error);
    }
  });
};

// Project and Task Endpoints
export const loadProjectById = async (url, projectId) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios.defaults.baseURL = url;
      const project = await axios.get(`/api/v1/project/project/${projectId}`);

      if (project && project.data) {
        return resolve(project);
      }
      return reject("No projects were found");
    } catch (error) {
      return reject(error);
    }
  });
};

export const listProjects = async (url, searchValue) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios.defaults.baseURL = url;
      const projects = await axios.get(`/api/v1/project/find/${searchValue}`);

      if (projects && projects.data) {
        return resolve(projects);
      }
      return reject("No projects were found");
    } catch (error) {
      return reject(error);
    }
  });
};

export const listProjectTasks = async (url, projectId) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios.defaults.baseURL = url;
      const projectTasks = await axios.get(`/api/v1/project/${projectId}`);

      if (projectTasks && projectTasks.data) {
        return resolve(projectTasks);
      }
      return reject("No project tasks were found");
    } catch (error) {
      return reject(error);
    }
  });
};

export const searchProjectTasksServerSideApiCall = async (
  url,
  { SearchProject, SearchValue }
) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios.defaults.baseURL = url;
      const projectTasks = await axios.get(
        `/api/v1/project/task/serversearch?SearchProject=${SearchProject}&SearchValue=${SearchValue}`
      );

      if (projectTasks && projectTasks.data) {
        return resolve(projectTasks);
      }
      return reject("No project tasks were found during search on server side");
    } catch (error) {
      return reject(error);
    }
  });
};

export const loadTask = async (url, taskId) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios.defaults.baseURL = url;
      const task = await axios.get(`/api/v1/project/task/${taskId}`);

      if (task && task.data) {
        return resolve(task);
      }
      return reject("No project tasks were found");
    } catch (error) {
      return reject(error);
    }
  });
};

export const searchAndQualify = async (url, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios.defaults.baseURL = url;
      const task = await axios({
        method: "put",
        url: `/api/v1/project/task/${payload.barcode}`,
        data: {
          qualifications: payload.qualifications,
          tenant: payload.tenant,
          accountId: payload.accountId,
          employeeId: payload.employeeId,
          employeeRefNo: payload.employeeRefNo,
          acmVersion: payload.acmVersion,
          contactId: payload.contactId,
          projectId: payload.projectId,
          inspector: payload.inspector,
          bypassQuals: payload.bypassQuals,
        },
      });
      if (task.status === 206) {
        return reject(task.data[0].message);
      }
      if (task && task.data) {
        return resolve(task);
      }
      return reject("No project tasks were found");
    } catch (error) {
      return reject(error);
    }
  });
};

export const listUserProjectTasks = async (url, employeeId) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios.defaults.baseURL = url;
      const projectTasks = await axios.get(`/api/v1/me/${employeeId}`);

      if (projectTasks && projectTasks.data) {
        return resolve(projectTasks);
      }
      return reject("No project tasks found");
    } catch (error) {
      return reject(error);
    }
  });
};

export const addTaskToProject = async (url, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios.defaults.baseURL = url;
      const projects = await axios({
        method: "put",
        url: "/api/v1/project",
        data: payload,
      });

      if (projects.data) {
        return resolve(projects);
      }
      return reject("Failed to upload task to project");
    } catch (error) {
      return reject(error);
    }
  });
};

export const removeTasksFromProject = async (url, project) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios.defaults.baseURL = url;
      const projectRes = await axios({
        method: "delete",
        url: `/api/v1/project/${project.EmployeeID.value}`,
        data: project,
      });

      return resolve(projectRes);
    } catch (error) {
      return reject(error);
    }
  });
};

export const listNonRoutineOptions = async (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios.defaults.baseURL = obj.url;

      let urlWithParams = "/api/v1/nonRoutineTask/dropdowns";

      const options = await axios.get(urlWithParams);

      if (options && options.data) {
        return resolve(options);
      }
      return reject("Unable to load non-routine options");
    } catch (error) {
      return reject(error);
    }
  });
};

export const addNonRoutineTaskToProject = async (url, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios.defaults.baseURL = url;
      const nonRoutineTask = await axios({
        method: "put",
        url: "/api/v1/nonRoutineTask",
        data: payload,
      });

      return resolve(nonRoutineTask);
    } catch (error) {
      return reject(error);
    }
  });
};

export const searchItemsDB = async (url, { searchValue, cust, comp }) => {
  return new Promise(async (resolve, reject) => {
    try {
      //const encodedText = encodeURIComponent(searchValue);
      axios.defaults.baseURL = url;

      //Pass in body and make it a post request
      const items = await axios({
        method: "post",
        url: `/api/v1/stockItem/find`,
        data: {
          searchValue: searchValue,
          cust: cust,
          comp: comp,
        },
      });

      // const items = await axios.get(
      //   `/api/v1/stockItem/find/${encodedText}?cust=${cust}&comp=${comp}`
      // );

      if (items && items.data) {
        return resolve(items);
      }
      return reject("No items found");
    } catch (error) {
      return reject(error);
    }
  });
};

export const addStockItem = async (url, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios.defaults.baseURL = url;
      const stockItem = await axios({
        method: "put",
        url: "/api/v1/stockItem",
        data: payload,
      });

      if (stockItem.status === 206) {
        return reject(stockItem.data.message);
      }

      return resolve(stockItem);
    } catch (error) {
      return reject(error);
    }
  });
};

// Part Request Endpoints
export const addPartRequest = async (url, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios.defaults.baseURL = url;

      const part = await axios({
        method: "put",
        url: "/api/v1/partReq",
        data: payload,
      });

      if (part.status === 206) {
        return reject(part.data.message);
      }

      return resolve(part);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getPartRequest = async (url, { taskId, cust, comp }) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios.defaults.baseURL = url;
      const parts = await axios.get(
        `/api/v1/partReq/${taskId}?cust=${cust}&comp=${comp}`
      );
      if (parts && parts.data) {
        return resolve(parts);
      }
    } catch (error) {
      return reject(error);
    }
  });
};

export const getPartReqManifest = async (url, taskId) => {
  // return new Promise(async (resolve, reject) => {
  axios.defaults.baseURL = url;
  try {
    const manifest = await axios.get(`/api/v1/partReq/manifest/${taskId}`);
    if (manifest && manifest.data) {
      return manifest;
    }
  } catch (err) {
    return err;
  }
  // });
};

export const getPriorityLevels = async (url) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios.defaults.baseURL = url;
      const priorityLevels = await axios.get("/api/v1/partReq/priorityLevels");

      return resolve(priorityLevels);
    } catch (error) {
      return reject(error);
    }
  });
};

export const addInspectionRejection = async (url, payload, taskId) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios.defaults.baseURL = url;
      const projectTask = await axios({
        method: "put",
        url: `/api/v1/project/task/inspectionReject/${taskId}`,
        data: payload,
      });

      if (projectTask.data) {
        return resolve(projectTask);
      }
      return reject("Failed to upload the inspection rejection");
    } catch (error) {
      return reject(error);
    }
  });
};

export const getTaskCard = async (url) => {
  return new Promise(async (resolve, reject) => {
    try {
      axios.defaults.baseURL = url;
      const pdfBlob = await axios.get("/api/v1/task/print/2-101200-02AC-01", {
        responseType: "blob",
      });

      if (pdfBlob.data) {
        const file = new Blob([pdfBlob.data], { type: "application/pdf" });
        const newFile = new File([file], "test.pdf", {
          type: "application/pdf",
          lastModified: Date.now(),
        });
        const fileURL = URL.createObjectURL(newFile);

        const pdfWindow = window.open();

        return resolve((pdfWindow.location.href = fileURL));
      }
      return reject("Get PDF for printing failed");
    } catch (err) {
      console.log(err);
      return reject(err);
    }
  });
};

export const getBrandingImage = async (imageUrl) => {
  // axios.defaults.baseURL = url;
  try {
    const data = { imageUrl };
    const image = await axios({
      method: "get",
      responseType: "arraybuffer",
      url: "/api/v1/image",
      data,
    });

    return image.data;
  } catch (err) {
    console.log(err);
    return null;
  }
};
export const removeUnservicablePart = async (url, payload) => {
  axios.defaults.baseURL = url;
  return axios.put("/api/v1/unsvcPart", payload);
};

export const login = (url, loginInput) => {
  axios.defaults.baseURL = url;
  return axios.post("/api/v1/auth/login", loginInput);
};

export const addUnsvcStockItem = (url, unsvcStockItem) => {
  axios.defaults.baseURL = url;
  return axios.put("/api/v1/stockItem/unserviceable", unsvcStockItem);
};

export const getBarcodeImageAPICall = async (ProjectTaskID) => {
  try {
    const image = await axios({
      method: "get",
      responseType: "arraybuffer",
      url: `/api/v1/image/barcode?taskid=${ProjectTaskID}`,
    });

    return image.data;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const postClockinAPICall = (data) =>
  axios({
    method: "post",
    url: `${process.env.REACT_APP_FACIALRECOG_BACKEND}/cognitive/identify`,
    data,
  });

export const biometricAlternativeAPICall = (data) =>
  axios({
    method: "post",
    url: `${process.env.REACT_APP_FACIALRECOG_BACKEND}/cognitive/biometricalternative`,
    data,
  });

export const uploadToAzureAPICall = async (dataUri, url) => {
  return azureAxios.put(
    url,
    new Buffer.from(dataUri.replace(/^data:image\/\w+;base64,/, ""), "base64"),
    {
      headers: {
        "Content-Type": "image/png",
        "Content-Encoding": "base64",
        "x-ms-blob-type": "BlockBlob",
      },
    }
  );
};
