import React, { Fragment, useEffect } from 'react';

import ProjectItem from './ProjectItem';
import Tasks from './selectTask/Tasks';
import Spinner from '../layout/Spinner';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { v4 } from 'uuid';

const Projects = () => {
  const { foundProjects, loading, user, filteredFoundProjects } = useStoreState(
    (state) => ({
      foundProjects: state.foundProjects,
      loading: state.loading,
      user: state.user,
      filteredFoundProjects: state.filteredFoundProjects,
    })
  );

  const { getEmployee } = useStoreActions((actions) => ({
    getEmployee: actions.getEmployee,
  }));

  useEffect(() => {
    const userStorage = JSON.parse(localStorage.getItem('user'));

    if (!user && userStorage && userStorage.EmployeeRefNo) {
      getEmployee(userStorage.EmployeeRefNo);
    }
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Fragment>
      {!loading && foundProjects !== null && !filteredFoundProjects && (
        <h3>
          To find a <span className="text-primary">Task</span>, select a{' '}
          <span className="text-primary">Project</span> Below
        </h3>
      )}
      <TransitionGroup>
        {!loading &&
        foundProjects !== null &&
        foundProjects.length !== 0 &&
        !filteredFoundProjects ? (
          foundProjects.map((p, index) => (
            <CSSTransition
              exit={false}
              key={p.ProjectID}
              timeout={500}
              classNames="item"
            >
              <ProjectItem
                project={p}
                index={index}
                backgroundColor={'light'}
              />
            </CSSTransition>
          ))
        ) : filteredFoundProjects ? (
          filteredFoundProjects.map((p, index) => (
            <CSSTransition
              exit={false}
              key={p.ProjectID}
              timeout={500}
              classNames="item"
            >
              <ProjectItem
                project={p}
                index={index}
                backgroundColor={'success'}
              />
            </CSSTransition>
          ))
        ) : (
          <CSSTransition
            enter={false}
            exit={false}
            key={v4()}
            timeout={500}
            classNames="item"
          >
            <div className="card items">
              <h2>
                No <span className="text-primary">Project</span> Tasks found
              </h2>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
      <Tasks />
    </Fragment>
  );
};

export default Projects;
