import React, { useCallback, useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import CameraPhoto from 'jslib-html5-camera-photo';
import { Button } from 'reactstrap';
import Popup from '../userWarningModal/Popup';

const videoRef = React.createRef();
let cameraPhoto = null;

function ClockIn(props) {
  const [cameraId, setCameraId] = useState();
  const [devices, setDevices] = useState([]);
  const {
    client,
    isRecognized,
    isNotRecognized,
    ipAddress,
    isClockingIn,
    popupMessageHeader,
    popupMessage,
    showPopup,
    alertColor,
    showClockInOutButton,
  } = useStoreState((state) => state);

  const {
    setLoading,
    setDataUriAction,
    setImgClassAction,
    submitRecogThunk,
    showClockInOutButtonAction,
  } = useStoreActions((actions) => actions);

  const startCamera = useCallback(async () => {
    cameraPhoto = new CameraPhoto(videoRef.current);
    try {
      await cameraPhoto.startCamera(cameraId, {
        width: 320,
        height: 240,
      });
      console.log('Camera is started!');
    } catch (error) {
      console.error('Camera not started!', error);
    }
  }, [cameraId]);

  function takePhoto() {
    setLoading(true);

    const cameraDataUri = cameraPhoto.getDataUri({
      sizeFactor: 1,
      imageCompression: 0.95,
    });

    setDataUriAction(cameraDataUri);
    setImgClassAction('taken');
    setTimeout(() => setImgClassAction('hidden'), 2500);
    setTimeout(
      () =>
        submitRecogThunk({
          client,
          dataUri: cameraDataUri,
          ipAddress,
        }),
      500
    );
  }

  const handleDevices = useCallback(
    (mediaDevices) => {
      const deviceList = mediaDevices.filter(
        ({ kind }) => kind === 'videoinput'
      );
      setDevices(deviceList);
      if (deviceList.length === 1) {
        setCameraId(deviceList[0].deviceId);
      }
    },
    [setDevices]
  );

  useEffect(() => {
    async function getDevices() {
      await navigator.mediaDevices.getUserMedia({ video: true });
      await navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }
    getDevices();
  }, [handleDevices]);

  useEffect(() => {
    if (cameraId) {
      localStorage.setItem('cameraId', cameraId);
      startCamera();
    }
  }, [cameraId, startCamera]);

  useEffect(() => {
    const cameraId = localStorage.getItem('cameraId');
    if (cameraId) {
      setCameraId(cameraId);
    }
  }, []);

  return (
    <div
      className={`select-custom-wrapper ${isRecognized && 'alert-success'} ${
        isNotRecognized && 'alert-danger'
      }`}
      {...props}
    >
      <div className='p-4'>
        <div>
          <div>
            <select
              onChange={(e) => setCameraId(e.target.value)}
              name='SelectCamera'
              value={cameraId || 'DEFAULT'}
            >
              <option value='DEFAULT' disabled>
                Select Camera
              </option>
              {devices?.map((device) => (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.label}
                </option>
              ))}
            </select>
          </div>
          <div>
            <video
              width='320'
              height='240'
              ref={videoRef}
              autoPlay
              playsInline
              onPlay={() => showClockInOutButtonAction(true)}
            />
          </div>
        </div>
        {showClockInOutButton && (
          <Button
            color='success'
            className='w-100 flex'
            onClick={() => takePhoto()}
          >
            {isClockingIn ? 'Clock-in' : 'Clock-out'}
          </Button>
        )}
      </div>
      <Popup
        isOpen={showPopup}
        alertColor={alertColor}
        popupMessage={popupMessage}
        popupMessageHeader={popupMessageHeader}
      />
    </div>
  );
}

export default ClockIn;
