import {
  listProjectTasks,
} from '../../services/cfbsKiosk';

export const listProjectTasksAndSetFilter = async (url, projectId, actions) => {
  return new Promise(async (resolve, reject) => {
    try {
      const tasks = await listProjectTasks(url, projectId);
      actions.setTasksByFilteredFoundProject(tasks.data);
      return resolve(tasks);
    } catch (error) {
      return reject(error);
    }
  })
}