const { DEFAULT_FONT } = require("../nonRoutine/utilities/document");
const moment = require("moment");

exports.DESCRIPTION_FONT_SIZE = 6;

exports.makeNewPage = (doc, pageNum, itemReq, blob, title, totalPage) => {
  const page = doc.addPage({ layout: "landscape" });
  //const { CreatedAt } = itemReq;

  page.image(blob, 15, 30, {
    fit: [75, 75],
  });

  page.font("Helvetica-Bold");
  page.fontSize(16).text(`${title}`, 230, 40);
  page.fontSize(10);
  page.font("Helvetica");

  page.fontSize(this.DESCRIPTION_FONT_SIZE - 2);
  page.text("Date:", 680, 40, 80);
  page.text(moment().format("MMMM Do YYYY, h:mm a"), 700, 40, 100);
  page.text("Page:", 680, 47, 80);
  page.text(`${pageNum} of ${totalPage}`, 700, 47);

  return page;
};

exports.makeSection = (page, sectionName, x, y) => {
  return (
    page
      .font("Helvetica-Bold")
      .fontSize(10)
      .text(sectionName, x, y) //, {
      // underline: true,
      //})
      .font("Helvetica")
      .fontSize(this.DESCRIPTION_FONT_SIZE)
  );
};

// Create a subsection that wraps text
exports.makeWrappedSubsection = (page, name, x, y, width = 300) => {
  return page
    .font("Helvetica")
    .fontSize(this.DESCRIPTION_FONT_SIZE)
    .text(name, x, y, {
      width: width,
      align: "justify",
      linebreak: true,
    });
};

exports.makeSubsection = (page, name, x, y, width = 300) => {
  return page.text(name, x, y, {
    // underline: true,
    width,
  });
};

exports.makeSubsectionHeading = (page, name, x, y, width = 300) => {
  return page
    .font("Helvetica-Bold")
    .text(name, x, y, {
      // underline: true,
      width,
    })
    .font("Helvetica");
};

exports.makeLine = (page, width, start, end) => {
  return page
    .lineWidth(width)
    .moveTo(start[0], start[1])
    .lineTo(end[0], end[1])
    .stroke();
};

exports.underlineText = (doc, text, x, y) => {
  doc
    .text(text, x, y)
    .rect(x, y + 10, doc.widthOfString(text), 0)
    .stroke();
};

exports.centerText = (doc, text, width, x, y, options = {}) => {
  doc
    .font(options.bold ? "Helvetica-Bold" : DEFAULT_FONT)
    .fontSize(options.fontSize || 10)
    .text(text, x + (width - doc.widthOfString(text)) / 2, y, options)
    .font(DEFAULT_FONT);
};

exports.wrapText = (doc, text, maxWidth) => {
  const words = `${text}`.split(" ");
  let lines = [];
  let currentLine = words[0];

  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    const widthWithSpace = doc.widthOfString(currentLine + " " + word);

    if (widthWithSpace <= maxWidth) {
      currentLine += " " + word;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }

  lines.push(currentLine);
  return lines;
};
