import React from "react";
import PropTypes from "prop-types";
import { useStoreState, useStoreActions } from "easy-peasy";
import createItemRequestPDF from "../../../reports/itemRequest";
import moment from "moment";

const PartRequestListItem = ({ part }) => {
  const { currentTask, company, partRequestList } = useStoreState((state) => ({
    user: state.user,
    currentTask: state.currentTask,
    company: state.user.CompanyAbrv,
    partRequestList: state.partRequestList,
    filteredStockItems: state.filteredStockItems,
    stockItem: state.stockItem,
  }));

  const { getReportImage, setLoading, setReportingItemReq, getPartReq } =
    useStoreActions((actions) => ({
      getReportImage: actions.getReportImage,
      setLoading: actions.setLoading,
      setReportingItemReq: actions.setReportingItemReq,
      getPartReq: actions.getPartReq,
    }));

  const {
    InventoryCD,
    Qty,
    Description,
    UsrPriorityLevel,
    UsrNeedBy,
    PartStatus,
    ItemReqNumber,
    RequestedBy,
    OrderNbr,
    SimpleItemRequest,
  } = part;

  const defineClass = (value) => {
    let textClass = "text-success";
    parseInt(value, 10) <= 0 && (textClass = "text-danger");
    return textClass;
  };

  const getTime = (timeInput) => {
    const offset = new Date().getTimezoneOffset();

    const offsetCorrectedTime = moment(
      new Date(timeInput).toUTCString()
    ).subtract(offset, "minutes");
    return `${offsetCorrectedTime.format("l")} ${offsetCorrectedTime.format(
      "LT"
    )}`;
  };

  const buildItemReq = async (OrderNbr) => {
    const partRequestItem = partRequestList.filter(
      (p) => p.OrderNbr === OrderNbr
    );
    const reportingItemReq = {
      ItemReqID: partRequestItem[0].ItemReqID,
      OrderNbr: partRequestItem[0].OrderNbr,
      ContractCD: partRequestItem[0].ProjectID,
      TaskCD: partRequestItem[0].TaskID,
      InventoryCD: partRequestItem[0].InventoryCD,
      ItemDescr: partRequestItem[0].Description,
      ProjectDescription: currentTask.ProjectDescription.value,
      TaskDescription: currentTask.ProjectTaskDescription.value,
      CustEquipCD: partRequestItem[0].NNumber,
      NeedBy: partRequestItem[0].UsrNeedBy,
      EmployeeName: partRequestItem[0].RequestedBy,
      CustomerName: partRequestItem[0].CustomerName,
      CreatedAt: getTime(partRequestItem[0].CreatedAt),
      Zone: partRequestItem[0].Zone,
      UOM: partRequestItem[0].UOM,
      Qty: partRequestItem[0].Qty,
      Priority: partRequestItem[0].UsrPriorityLevel,
    };
    setReportingItemReq(reportingItemReq);
    return reportingItemReq;
  };

  const printItemRequest = async (itemReq) => {
    const reportingItemReq = await buildItemReq(itemReq);
    const blob = await getReportImage();

    createItemRequestPDF(blob, reportingItemReq);

    setLoading(false);
  };

  return (
    <div className="light card-slim bg-light my-3">
      <div className="grid-5 my-3">
        <div className="text-primary mt-3 ml-3 text-left">
          {InventoryCD} - {Description}
        </div>
        <div className="text-primary mt-3 ml-3 text-left">
          {UsrPriorityLevel}
        </div>
        <div className="text-primary mt-3 ml-3 text-left">{UsrNeedBy}</div>
        <div className="text-primary mt-3 ml-3 text-left">
          {parseInt(Qty, 10)}
        </div>
        <div className="text-primary mt-3 ml-3 text-left">{PartStatus}</div>
      </div>
      <div className="grid-3 text-dark">
        {!SimpleItemRequest ? (
          <div className="ml-3 text-center ">
            On Hand: {company}
            {" ("}
            <span className={defineClass(part.CompanyQty)}>
              {parseInt(part.CompanyQty, 10) || 0}
            </span>
            {") / "}
            {part.CustomerName} (
            <span className={defineClass(part.CustomerQty)}>
              {parseInt(part.CustomerQty, 10) || 0}
            </span>
            {")"}
            {currentTask.ShowTotalQty && currentTask.ShowTotalQty.value ? (
              <>
                {" / Total Available: ("}
                <span className={defineClass(part.TotalQty)}>
                  {parseInt(part.TotalQty) || 0}
                </span>
                {")"}
              </>
            ) : null}
          </div>
        ) : (
          <div className="ml-3 text-center ">
            On Hand:{" "}
            <span className={defineClass(part.CompanyQty)}>
              {parseInt(part.CompanyQty, 10) || 0}
            </span>
          </div>
        )}

        {!SimpleItemRequest ? (
          <div>
            {OrderNbr ? (
              <i
                className="fa fa-print text-success medium"
                title="Print ticket"
                onClick={() => {
                  console.log(ItemReqNumber);
                  printItemRequest(ItemReqNumber);
                }}
              ></i>
            ) : (
              <i
                className="fa text-warning medium"
                title="Check for ticket"
                onClick={() => getPartReq()}
              >
                &#xf021;
              </i>
            )}
          </div>
        ) : null}

        {!SimpleItemRequest ? (
          <div className="mr-3 text-right ">
            Request: {ItemReqNumber}
            <br></br>
            {RequestedBy && `by: ${RequestedBy}`}
          </div>
        ) : null}
      </div>
    </div>
  );
};

PartRequestListItem.propTypes = {
  part: PropTypes.object.isRequired,
};

export default PartRequestListItem;
