import { underlineText } from "../utilities/document";
import { drawBox } from "./utilities/document"

export const showRectification = (doc, startX, startY) => {
    const boxHeight = 135;

    drawBox(doc, startX, startY, 395, boxHeight);

    const [startTextX, endY] = [startX + 5, startY + boxHeight];

    underlineText(doc, "Rectification / Work Carried Out:", startTextX, startY + 6);

    underlineText(doc, "Manuals:", startTextX, endY - 40);

    underlineText(doc, "Manuals Revision Date:", startTextX, endY - 25);
}