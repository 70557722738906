export const sumQtyAvail = (p) => {
  const keys = Object.keys(p);
  let totalOnHand = 0;
  keys.forEach((k) => {
    k.substring(0, 3) === 'Qty' &&
      k.length > 3 &&
      (totalOnHand = totalOnHand + parseFloat(p[k], 5));
    p.TotalOnHand = totalOnHand;
  });
  return p;
};
