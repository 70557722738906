import { thunk } from "easy-peasy";
import setAuthToken from "../../utils/setAuthToken";
import {
  uploadToAzureAPICall,
  postClockinAPICall,
  biometricAlternativeAPICall,
} from "../../services/cfbsKiosk";
import { v4 } from "uuid";
import { createPostClockinPayload } from "./payloadBuilder";
import { handleAcumaticaError } from "../../utils/handleAcumaticaError";

const thunks = {
  submitRecogThunk: thunk(async (actions, { dataUri, ipAddress }, helpers) => {
    const data = {
      client: localStorage.getItem("token"),
      dataUri,
      ipAddress,
    };

    let txName = `TX${parseInt(Math.random() * 10000).toString()}`;
    actions.setTxNameAction(txName);
    actions.createLogStepAction("Frontend");

    const { isClockingIn, user } = helpers.getState();

    data.BAccountID = user.BAccountID;
    data.isClockingIn = isClockingIn;
    data.EmployeeRefNo = user.EmployeeRefNo;

    try {
      actions.createLogStepAction("Uploadmage");

      const imageBaseUrl = `${
        helpers.getState().user.blobContainerUrl
      }${v4()}.png`;
      const sas = helpers.getState().user.blobContainerSAS;
      const url = `${imageBaseUrl}?${sas}`;
      data.fileUrl = imageBaseUrl;
      await uploadToAzureAPICall(dataUri, url);

      actions.createLogStepAction("Uploadmage");

      actions.createLogStepAction("PostClockin");

      data.logObj = helpers.getState().logObj;
      data.txName = helpers.getState().txName;

      // send to backend cognitive/identity
      const clockinPayload = await createPostClockinPayload(data);
      setAuthToken(data.client);

      const res = await postClockinAPICall(clockinPayload);

      if (
        res.data.logObj.Person.PersonID !==
        helpers.getState().user.FacialRecogID
      )
        throw new Error("No matching facial recognition ID");

      actions.setLogObjAction(res.data.logObj);

      res.data.ipAddress = data.ipAddress;
      if (res.data.recog) {
        actions.processChoochResSuccessAction(res.data.timeEntry);
      } else {
        actions.processChoochResFailAction(res.data);
      }
      actions.createLogStepAction("PostClockin");
      actions.createLogStepAction("Frontend");

      setTimeout(
        () => {
          const logObj = helpers.getState().logObj;
          actions.processCleanupAction(res.data);
          console.log(JSON.stringify(logObj, null, 2));
        },
        res.data.recog ? 2000 : 5000
      );

      actions.setUser({
        ...helpers.getState().user,
        KronosClockIn: isClockingIn ? true : false,
      });
      actions.setAlerts({
        type: "success",
        error: `Successfully clocked ${
          isClockingIn ? "in" : `out. ${res.data.message}`
        }`,
      });
      actions.setClockInPopupAction(false);
      !isClockingIn && actions.signOut();
    } catch (err) {
      // debugger;
      console.log(err);
      err.response &&
        err.response.data &&
        err.response.data.logObj &&
        actions.setLogObjAction(err.response.data.logObj);
      console.log(JSON.stringify(helpers.getState().logObj, null, 2));
      actions.processChoochResFailAction({
        message:
          handleAcumaticaError(err) ||
          "There was a problem recording your time entry",
        alertColor: "danger",
      });

      setTimeout(() => {
        actions.processCleanupAction(
          err && err.response && err.response.data ? err.response.data : {}
        );
      }, 5000);
    }
  }),
  biometricAlternativeThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoading(true);
    try {
      const { biometricAlternativeCodeInput, user, isClockingIn } =
        helpers.getState();

      let response = null;

      if (
        user.BiometricAlternativeCode.trim() ===
        biometricAlternativeCodeInput.trim()
      ) {
        response = await biometricAlternativeAPICall({
          AccountID: user.AccountID,
          isClockingIn,
          EmployeeRefNo: user.EmployeeRefNo,
        });
      } else {
        actions.clearLocalStorageAndRefressh();
      }
      actions.showBiometricAlternativeModalAction(false);
      actions.setUser({
        ...helpers.getState().user,
        KronosClockIn: true,
      });
      actions.setAlerts({
        type: "success",
        error: `Successfully clocked ${
          isClockingIn
            ? "in"
            : `out.${response ? ` ${response.data.message}` : ""}`
        }`,
      });

      !isClockingIn && actions.signOut();
    } catch (err) {
      console.log(err);
      actions.processChoochResFailAction({
        message:
          handleAcumaticaError(err) ||
          "There was a problem recording your time entry",
        alertColor: "danger",
      });
      setTimeout(() => {
        actions.processCleanupAction(
          err && err.response && err.response.data ? err.response.data : {}
        );
      }, 5000);
    }
    actions.setLoading(false);
  }),
};

export default thunks;
