import { DEFAULT_FONT, drawBox } from "./utilities/document"

export const showContinuationSheet = (doc, startX, startY) => {
    let cursorX = startX;

    doc
        .font("Helvetica-Bold")
        .text("Continuation Sheet", cursorX, startY)
        .font(DEFAULT_FONT);

    cursorX += doc.widthOfString("Continuation Sheet") + 50;

    drawBox(doc, cursorX, startY, 7, 7);
    cursorX += 10;
    doc.text("No", cursorX, startY);

    cursorX += doc.widthOfString("No") + 25;

    drawBox(doc, cursorX, startY, 7, 7);
    cursorX += 10;
    doc.text("Yes", cursorX, startY);

    cursorX += doc.widthOfString("Yes") + 20;

    doc.text("Number:  ____ of ____", cursorX, startY);
}