import { underlineText } from "../utilities/document";
import { DEFAULT_FONT_SIZE, drawBox } from "./utilities/document";

export const showRepair = (doc, startX, startY) => {
    drawBox(doc, startX, startY, 395, 50);

    underlineText(doc, "Method of Repair:", startX + 5, startY + 3);

    doc.y += 9;

    doc.fontSize(7.25)
    const checkboxRows = [
        [
            {
                label: "Engineering Order",
                rightMargin: 15,
            }, 
            {
                label: "SWPM Repair",
                rightMargin: 10,
            }, 
            { 
                label: "SRM Repair",
            },
        ],
        [
            { 
                label: "Remove & Replace",
                rightMargin: 12,
            }, 
            { 
                label: "Other:",
            },
        ],
    ];
    checkboxRows.forEach(row => {
        const y = doc.y + 11;
        doc.x = startX + 7;
        row.forEach(({ label, rightMargin }) => {
            drawBox(doc, doc.x, y, 10, 10);
            doc.text(label, doc.x + 15, y + 3);
            doc.x += rightMargin ? rightMargin : 0;
        });
    });
    doc.fontSize(DEFAULT_FONT_SIZE);
}