import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";

const NavbarBottom = () => {
  const {
    user,
    page,
    projectTasks,
    barCode,
    usingCentrik,
    mechanicQualified,
    taskLimit,
    queueLocked,
    KronosClockIn,
    KioskRole,
    facialRecogition,
    Search,
    Scan,
    SecondaryFacialRecogRequired,
    hideSearchTasks,
    BiometricAlternative,
    loading,
    alerts,
  } = useStoreState((state) => ({
    page: state.page,
    user: state.user,
    barCode: state.barCode,
    projectTasks: state.projectTasks || [],
    usingCentrik: state.usingCentrik,
    mechanicQualified: state.qualifications.mechanicQualified,
    taskLimit: state.taskLimit,
    queueLocked: state.queueLocked,
    KronosClockIn: state.user ? state.user.KronosClockIn : false,
    KioskRole: state.user ? state.user.KioskRole : "Not Applicable",
    facialRecogition: state.user ? state.user.facialRecogition : false,
    Search: state.user ? state.user.customLabels.taskList.Search : "",
    Scan: state.user ? state.user.customLabels.taskList.Scan : "",
    SecondaryFacialRecogRequired: state.user
      ? state.user.SecondaryFacialRecogRequired
      : false,
    hideSearchTasks: state.user ? state.user.hideSearchTasks : false,
    BiometricAlternative: state.user ? state.user.BiometricAlternative : false,
    loading: state.loading,
    alerts: state.alerts,
  }));

  const {
    setAlerts,
    setPage,
    signOut,
    addTaskToProjectTasksByCode,
    cleanupSearch,
    setLoading,
    removeTasksFromProjectTasks,
    setBarCode,
    setCorrectiveAction,
    setForcedLogoutModal,
    setClockInPopupAction,
    setIsClockingInAction,
    showBiometricAlternativeModalAction,
  } = useStoreActions((actions) => ({
    setAlerts: actions.setAlerts,
    setPage: actions.setPage,
    signOut: actions.signOut,
    addTaskToProjectTasksByCode: actions.addTaskToProjectTasksByCode,
    cleanupSearch: actions.cleanupSearch,
    removeTasksFromProjectTasks: actions.removeTasksFromProjectTasks,
    setLoading: actions.setLoading,
    setBarCode: actions.setBarCode,
    setCorrectiveAction: actions.setCorrectiveAction,
    setForcedLogoutModal: actions.setForcedLogoutModal,
    setClockInPopupAction: actions.setClockInPopupAction,
    setIsClockingInAction: actions.setIsClockingInAction,
    showBiometricAlternativeModalAction:
      actions.showBiometricAlternativeModalAction,
  }));

  const searchTasks = () => {
    user && user.validities
      ? setPage("search")
      : setAlerts({
          error:
            "Please wait until you name is green.  Your qualifications are still loading . . .",
          type: "warning",
        });
  };

  const goHome = () => {
    cleanupSearch();
    setPage("home");
  };

  const scanTask = () => {
    if (user && user.validities) {
      const scannedValue = document.querySelector(
        'input[name="barCode"]'
      ).value;
      if (scannedValue) {
        if (queueLocked) {
          setForcedLogoutModal(true);
        } else {
          if (
            projectTasks.filter(
              (task) => task.ProjectTaskID.value === scannedValue
            ).length === 0
          ) {
            addTaskToProjectTasksByCode(scannedValue);
          } else {
            setAlerts({
              error: `Task ${scannedValue} is already in this list`,
              type: "warning",
            });
          }
        }
      } else {
        setAlerts({ error: "No barcode value was detected", type: "warning" });
      }
    } else {
      setAlerts({
        error:
          "Please wait until you name is green.  Your qualifications are still loading . . .",
        type: "warning",
      });
    }
  };

  const notQualified = () => {
    setAlerts({
      error:
        "Your mechanical qualifications do not meet the minimum requirements to continue",
      type: "warning",
    });
  };

  const setBarcode = (e) => {
    setBarCode(e.target.value);
    if (user.preventTypingInTaskCard)
      setTimeout(() => {
        setBarCode("");
      }, user.preventTypingTimeoutMS);
  };

  const stopAllWork = () => {
    setLoading(true);

    const project = {};
    project.remove = true;
    project.process = "incompleteAll";

    setCorrectiveAction("");

    removeTasksFromProjectTasks(project);
  };

  const exitApplication = () => {
    signOut();
  };

  const buttonCount = () => {
    let count = 1;

    if (
      (facialRecogition &&
        SecondaryFacialRecogRequired &&
        KronosClockIn &&
        KioskRole !== "Not Applicable") ||
      !facialRecogition ||
      !SecondaryFacialRecogRequired ||
      user.SkipFacialRecogition
    ) {
      if (page === "home") {
        !hideSearchTasks && count++;

        !projectTasks.length &&
          !user.SkipFacialRecogition &&
          facialRecogition &&
          KronosClockIn &&
          SecondaryFacialRecogRequired &&
          ((user.timeActivity && user.TANoteID) || !user.timeActivity) &&
          count++;

        projectTasks.length && !user.hideStopAllWork && count++;
      }

      count++;
    }

    return count;
  };

  if (!loading && user) {
    return (
      <div className="navbar-bottom fixed-bottom navbar-light">
        <div
          id="navBarBottom"
          className={`grid-${buttonCount()} large`}
          style={{ width: "100%" }}
        >
          {((facialRecogition &&
            SecondaryFacialRecogRequired &&
            KronosClockIn &&
            KioskRole !== "Not Applicable") ||
            !facialRecogition ||
            !SecondaryFacialRecogRequired ||
            user.SkipFacialRecogition) && (
            <>
              {page === "home" ? (
                <Fragment>
                  {!hideSearchTasks && (
                    <div>
                      <Link
                        to={
                          mechanicQualified || !usingCentrik
                            ? "/searchProjects"
                            : ""
                        }
                        onClick={
                          mechanicQualified || !usingCentrik
                            ? searchTasks
                            : notQualified
                        }
                        hidden={taskLimit}
                      >
                        <i className="fa fa-search text-primary"></i>
                      </Link>

                      <div
                        className="icon-description text-center"
                        hidden={taskLimit}
                      >
                        {Search}
                      </div>
                    </div>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  <div>
                    <Link to="/" onClick={goHome}>
                      <i className="fas fa-tasks text-primary"></i>
                    </Link>
                    <div className="icon-description text-center">
                      My Task List
                    </div>
                  </div>
                </Fragment>
              )}
            </>
          )}
          {page === "home" && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Fragment>
                {taskLimit ? (
                  <span
                    className="text-danger medium"
                    title={`Clock out of task ${projectTasks[0].ProjectTaskID.value}\nin order to clock into a new task`}
                  >
                    Open Task Limit Reached
                  </span>
                ) : (
                  <input
                    className={`text-center ${
                      (facialRecogition &&
                        SecondaryFacialRecogRequired &&
                        KronosClockIn &&
                        KioskRole !== "Not Applicable") ||
                      !facialRecogition ||
                      !SecondaryFacialRecogRequired ||
                      user.SkipFacialRecogition
                        ? ""
                        : "d-none"
                    }`}
                    type="text"
                    name="barCode"
                    value={barCode}
                    placeholder={Scan}
                    autoFocus
                    disabled={taskLimit || alerts.length}
                    onChange={
                      mechanicQualified || !usingCentrik
                        ? setBarcode
                        : notQualified
                    }
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        scanTask();
                      }
                    }}
                    autoComplete="off"
                    style={{ width: hideSearchTasks ? "50%" : "100%" }}
                  />
                )}
              </Fragment>
            </div>
          )}
          {page === "home" &&
            ((facialRecogition &&
              KioskRole !== "Not Applicable" &&
              SecondaryFacialRecogRequired &&
              KronosClockIn) ||
              !facialRecogition ||
              !SecondaryFacialRecogRequired ||
              user.SkipFacialRecogition) && (
              <Fragment>
                {!projectTasks.length &&
                  !user.SkipFacialRecogition &&
                  facialRecogition &&
                  KronosClockIn &&
                  SecondaryFacialRecogRequired &&
                  ((user.timeActivity && user.TANoteID) ||
                    !user.timeActivity) && (
                    <div>
                      <i
                        className="far fa-clock text-danger"
                        onClick={() => {
                          if (BiometricAlternative) {
                            showBiometricAlternativeModalAction(true);
                          } else {
                            setClockInPopupAction(true);
                          }
                          setIsClockingInAction(false);
                        }}
                      ></i>
                      <div className="icon-description text-center text-danger">
                        KRONOS - OUT
                      </div>
                    </div>
                  )}

                {projectTasks.length > 0 && !user.hideStopAllWork && (
                  <div>
                    <i
                      className="far fa-hand-paper text-danger"
                      onClick={
                        mechanicQualified || !usingCentrik
                          ? stopAllWork
                          : notQualified
                      }
                    ></i>
                    <div className="icon-description text-center">
                      Stop All Work
                    </div>
                  </div>
                )}
              </Fragment>
            )}
          <div>
            <i
              className="fas fa-sign-in-alt text-danger"
              onClick={exitApplication}
            ></i>
            <div className="icon-description text-center">Sign Out</div>
          </div>
        </div>
      </div>
    );
  } else {
    return "";
  }
};

export default NavbarBottom;
