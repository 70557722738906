import { DEFAULT_FONT } from "../nonRoutine/utilities/document";
import { X_START } from "./fillinblank";

const showHeader = (doc, { companyLogoBlob, AcumaticaUSPNumber }) => {
  // show company logo
  doc.image(companyLogoBlob, 10, 10, {
    fit: [200, 200],
  });

  // show title
  doc
    .font("Helvetica-Bold")
    .fontSize(22)
    .text("UNSERVICEABLE", doc.x + 150, 25)
    .font(DEFAULT_FONT)
    .fontSize(9);

  // show small text below company logo
  doc.x = X_START;
  doc.y += 32;
  doc.fontSize(6);
  const notes = ["EASA Part UK. 145.01251", "BCAD AMO 02/10"];
  notes.forEach((note) => {
    doc.text(note, doc.x, doc.y + 1);
    doc.rect(doc.x + 5, doc.y - 1, 7, 7);
    doc.x += 17;
    doc.y -= 1;
  });

  doc
    .font("Helvetica-Bold")
    .fontSize(11)
    .text(AcumaticaUSPNumber, doc.x + 35, doc.y - 3)
    .font(DEFAULT_FONT)
    .fontSize(9);

  doc.fontSize(9);
};

export default showHeader;
