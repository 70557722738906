import React, { Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Options from './Options';
import { useStoreState, useStoreActions } from 'easy-peasy';

const NonRoutineOptions = () => {
  const {
    loading,
    nonRoutineDropdowns,
    showNonRoutineOptions,
    nonRoutineOptionType,
  } = useStoreState((state) => ({
    loading: state.loading,
    nonRoutineDropdowns: state.nonRoutineDropdowns,
    showNonRoutineOptions: state.showNonRoutineOptions,
    nonRoutineOptionType: state.nonRoutineOptionType,
  }));

  const { clearOptionsFilter, resetNonRoutineOptions } = useStoreActions(
    (actions) => ({
      clearOptionsFilter: actions.clearOptionsFilter,
      resetNonRoutineOptions: actions.resetNonRoutineOptions,
    })
  );

  const toggle = () => {
    //setShowNonRoutineOptions(false);
    resetNonRoutineOptions();
    clearOptionsFilter();
  };

  const properCase = (vlu) => {
    return vlu.charAt(0).toUpperCase() + vlu.slice(1);
  };

  return (
    <Fragment>
      {nonRoutineDropdowns !== null && !loading && (
        <Modal
          isOpen={showNonRoutineOptions}
          centered={true}
          size={'lg'}
          backdrop={true}
        >
          <ModalHeader>Select {properCase(nonRoutineOptionType)}</ModalHeader>
          <ModalBody>
            <Options />
          </ModalBody>
          <ModalFooter>
            <Button
              color="light"
              style={{ display: 'block', width: '100%' }}
              //onClick={toggle}
              onClick={toggle}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Fragment>
  );
};

export default NonRoutineOptions;
