import { DEFAULT_FONT_SIZE, drawBox } from "./utilities/document";

export const showTaskType = (
    doc, 
    {
        None,
        EWIS,
        RII,
        CriticalTask,
        CDCCL,
        AirworthinessLimitationsItems,
    },
    startX, 
    startY
) => {
    drawBox(doc, startX, startY, (doc.page.width - 50) - startX, 115);

    doc.text("Task Type:", startX + 9, startY + 7);

    doc.y += 4;

    doc.fontSize(7.25);
    const checkboxLabels = [
        {
            isChecked: None,
            label: "None",
        },
        {
            isChecked: EWIS,
            label: "EWIS Item",
        },
        {
            isChecked: RII,
            label: "RII",
        },
        {
            isChecked: CriticalTask,
            label: "Critical Task/Independent",
        },
        {
            isChecked: CDCCL,
            label: "CDCCL",
        },
        {
            isChecked: AirworthinessLimitationsItems,
            label: "Airworthiness Limitation Item",
        },
    ];
    checkboxLabels.forEach(({ isChecked = false, label }) => {
        const startY = doc.y + 9;
        drawBox(doc, startX + 5, startY, 10, 10);
        if(isChecked)
            doc.text("X", startX + 7.5, startY + 2.5);
        doc.text(label, startX + 20, startY + 3);
    });
    doc.fontSize(DEFAULT_FONT_SIZE);
}