import React, { Fragment } from 'react';
import TasksFilter from './TasksFilter';
import TaskItem from './TaskItem';
import { useStoreState } from 'easy-peasy';

const Tasks = () => {
  const { tasks, filteredTasks, loading } = useStoreState((state) => ({
    tasks: state.tasksByFilteredFoundProject,
    filteredTasks: state.filteredTasks,
    loading: state.loading,
  }));

  return (
    <Fragment>
      {tasks ? <TasksFilter /> : ''}
      <div>
        {tasks !== null && !loading ? (
          <Fragment>
            {tasks.length > 0 ? (
              filteredTasks !== null ? (
                filteredTasks.map((t) => (
                  <div
                    //exit={false}
                    key={t.TaskCD}
                    //timeout={0}
                    className="item"
                  >
                    <TaskItem task={t} />
                  </div>
                ))
              ) : (
                tasks.map((t) => (
                  <div
                    //exit={false}
                    key={t.TaskCD}
                    //timeout={0}
                    className="item"
                  >
                    <TaskItem task={t} />
                  </div>
                ))
              )
            ) : (
              <div className="card">
                <h2>
                  No <span className="text-primary">Project</span> Tasks found
                </h2>
              </div>
            )}{' '}
          </Fragment>
        ) : (
          ''
        )}
      </div>
    </Fragment>
  );
};

export default Tasks;
