import { centerText, wrapText } from "../utilities/document";

const textHeight = 8;

export const X_START = 50;

const calculateFieldHeight = (doc, { value, lineWidth }) =>
  wrapText(doc.font("Helvetica-Bold"), value, lineWidth - 10).length *
  textHeight;

export const showFillInBlank = (doc, { label, value, lineWidth }) => {
  const yStart = Number(doc.y);

  doc.text(label);

  // show line
  const lineY = doc.y + 7;
  const lineXStart = Number(doc.x + 5);
  doc.moveTo(lineXStart, lineY);
  doc.lineTo(lineXStart + lineWidth, lineY).stroke();

  // fill in blank
  const textValue = `${value || ""}`;
  const textLines = wrapText(
    doc.font("Helvetica-Bold"),
    textValue,
    lineWidth - 10
  );
  textLines.length > 1 &&
    (doc.y -= calculateFieldHeight(doc, {
      value: textValue,
      lineWidth,
    }));
  doc.y += textLines.reduce((heightResult, text) => {
    const textWidth = doc.font("Helvetica-Bold").widthOfString(textValue);
    doc.font("Helvetica");
    if (textWidth > lineWidth) {
      doc
        .font("Helvetica-Bold")
        .text(text, lineXStart, doc.y - 2)
        .font("Helvetica");
    } else {
      centerText(doc, text, lineWidth, lineXStart, doc.y - 2, {
        bold: true,
      });
    }
    doc.y += textHeight + 4;
    return heightResult + textHeight;
  }, 0);

  // move cursor for next fill in blank in the same row
  doc.x = lineXStart + lineWidth + 4;
  doc.y = yStart;
};

export const showFillInBlankTable = (doc, rows) => {
  rows.forEach((fields) => {
    doc.x = X_START;
    doc.y +=
      25 + Math.max(...fields.map((field) => calculateFieldHeight(doc, field)));

    fields.forEach((field) => showFillInBlank(doc, field));
  });
};
