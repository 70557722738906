export const DEFAULT_FONT_SIZE = 10;
export const DEFAULT_FONT = "Helvetica";
export const DEFAULT_LINE_WIDTH = 1;
export const DEFAULT_LINE_COLOR = "black";

export const drawBox = (doc, x, y, width, height, boxOptions={}) => {
    const {
        lineWidth,
        strokeColor,
    } = {
        lineWidth: DEFAULT_LINE_WIDTH,
        strokeColor: DEFAULT_LINE_COLOR,
        ...boxOptions,
    };

    doc
        .lineWidth(lineWidth)
        .strokeColor(strokeColor)
        .rect(x, y, width, height)
        .stroke()
        .lineWidth(DEFAULT_LINE_WIDTH)
        .strokeColor(DEFAULT_LINE_COLOR);
}