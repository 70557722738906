import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import React from "react";

function Popup({ popupMessageHeader, popupMessage, alertColor, ...props }) {
  return (
    <Modal centered={true} {...props}>
      <ModalHeader className={`bg-${alertColor}`}>
        {popupMessageHeader}
      </ModalHeader>
      <ModalBody className="text-center">
        {popupMessage
          ? popupMessage
          : "We were not able to succesfully recognize your face."}
      </ModalBody>
      <ModalFooter className={`bg-${alertColor}`} />
    </Modal>
  );
}

export default Popup;
