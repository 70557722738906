const state = {
  client: '',
  ipAddress: '',
  appBaseUrl: '',
  cameraData: {
    dataUri: null,
    imgClass: 'hidden',
  },
  txName: '',
  isRecognized: false,
  isNotRecognized: false,
  showClockInPopup: false,
  logObj: {},
  alertColor: 'primary',
  showPopup: false,
  popupMessage: null,
  popupMessageHeader: null,
  isClockingIn: false,
  showClockInOutButton: false,
  biometricAlternativeCodeInput: '',
  showBiometricAlternativeModal: false,
};

export default state;
