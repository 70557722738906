import React, { useRef, Fragment } from "react";
import StockItemResults from "./searchPart/stockItemResults";
import StockItemQty from "./searchPart/stockItemQty";
import AddNewStockItem from "./searchPart/addNewStockItem";
import AddUnsvcStockItem from "./searchPart/addUnsvcStockItem";
import PartRequestList from "./PartsRequestList/partRequestList";
import { useStoreState, useStoreActions } from "easy-peasy";
import createOrderManifest from "../../reports/orderManifest";

const PartsRequest = () => {
  const {
    stockItemSearchValue,
    showStockItemSearchResults,
    showStockItemQty,
    showAddNewStockItem,
    showAddUnsvcStockItem,
    currentTask,
    user,
    SearchPlaceholder,
  } = useStoreState((state) => ({
    stockItemSearchValue: state.stockItemSearchValue,
    showStockItemSearchResults: state.showStockItemSearchResults,
    showStockItemQty: state.showStockItemQty,
    showAddNewStockItem: state.showAddNewStockItem,
    showAddUnsvcStockItem: state.showAddUnsvcStockItem,
    currentTask: state.currentTask,
    user: state.user,
    SearchPlaceholder: state.user.customLabels.partRequest.SearchPlaceholder,
  }));

  const {
    setLoading,
    clearStockItemSearchValue,
    setStockItemSearchValue,
    cleanupStockItemSearch,
    searchItems,
    setPartNotFoundInventoryID,
    getReportImage,
    setReportingItemReq,
    getPartRequestManifest,
  } = useStoreActions((actions) => ({
    setLoading: actions.setLoading,
    clearStockItemSearchValue: actions.clearStockItemSearchValue,
    setStockItemSearchValue: actions.setStockItemSearchValue,
    // filterStockItems: actions.filterStockItems,
    cleanupStockItemSearch: actions.cleanupStockItemSearch,
    searchItems: actions.searchItems,
    setPartNotFoundInventoryID: actions.setPartNotFoundInventoryID,
    getReportImage: actions.getReportImage,
    setReportingItemReq: actions.setReportingItemReq,
    getPartRequestManifest: actions.getPartRequestManifest,
  }));

  // Mock JSON for PDF Testing
  // let mockJSON = {}

  const text = useRef("");

  const onChange = (e) => {
    if (text.current.value !== "") {
      setStockItemSearchValue(e.target.value);
    } else {
      clearStockItemSearchValue();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (stockItemSearchValue.length !== 0) {
      setLoading(true);
      setPartNotFoundInventoryID();
      cleanupStockItemSearch();
      // filterStockItems(stockItemSearchValue.toString());
      searchItems({
        searchValue: stockItemSearchValue.toString(),
        cust: currentTask.CustomerWarehouse.value,
        comp: currentTask.CompanyWarehouse.value,
      });
    } else return;
  };

  const printOrderManifest = () => {
    console.log("print");
    printItemRequestManifest();
  };

  const buildPartsListReq = async (task) => {
    const reportingItemReq = {
      CustomerName: task.Customer.value,
      EmployeeName: user.LegalName,
      CreatedAt: task.CreatedAt.value,
      NeedBy: task.TrxDateTime.value,
      WorkOrderID: task.WorkOrderID.value,
      ContractCD: task.ProjectIDInt,
      TaskCD: task.ProjectTaskID.value,
      EquipID: task.EquipID.value,
      ProjectDescription: currentTask.ProjectDescription.value,
      TaskDescription: currentTask.ProjectTaskDescription.value,
      CustEquipCD: task.NNumber,
      Zone: task.Zone,
      UOM: task.UOM,
      Qty: task.Qty,
      Priority: task.UsrPriorityLevel,
    };
    setReportingItemReq(reportingItemReq);
    return reportingItemReq;
  };

  const printItemRequestManifest = async () => {
    const taskReq = await buildPartsListReq(currentTask);
    const blob = await getReportImage();
    const manifest = await getPartRequestManifest();
    createOrderManifest(blob, taskReq, manifest);
    setLoading(false);
  };

  return (
    <Fragment>
      {currentTask.printItemReqManifest && (
        <button className="btn btn-success" onClick={printOrderManifest}>
          Print Part Request Manifest
        </button>
      )}

      {showStockItemSearchResults ? (
        <StockItemResults />
      ) : showStockItemQty ? (
        <StockItemQty />
      ) : showAddNewStockItem ? (
        <AddNewStockItem />
      ) : showAddUnsvcStockItem ? (
        <AddUnsvcStockItem />
      ) : (
        <Fragment>
          <form onSubmit={onSubmit}>
            <div className="input-group mb-3">
              <input
                ref={text}
                onChange={onChange}
                type="text"
                className="form-control"
                placeholder={`Search by ${SearchPlaceholder}`}
                aria-label={`Search by ${SearchPlaceholder}`}
                aria-describedby="basic-addon2"
                value={stockItemSearchValue}
                autoFocus
                name="partSearchValue"
              />
              <div
                className="input-group-append"
                style={{ marginTop: "1.2rem" }}
                onClick={onSubmit}
              >
                <button
                  className="btn btn-success"
                  type="button"
                  disabled={stockItemSearchValue.length === 0}
                >
                  <i className="fa fa-search text-white mr-2"></i>
                  Search
                </button>
              </div>
            </div>
          </form>
          <PartRequestList />
        </Fragment>
      )}
    </Fragment>
  );
};

export default PartsRequest;
