import { action } from "easy-peasy";

import checkQualifications from "../../utils/checkQualifications";

const projectActions = {
  setQueueLocked: action((state) => {
    state.queueLocked = true;
  }),
  clearQueueLocked: action((state) => {
    state.queueLocked = false;
  }),
  setForcedLogoutModal: action((state, show) => {
    state.showForcedLogoutModal = show;
  }),
  setQueueScanCountDownIsActive: action((state, isActive) => {
    state.queueScanCountDownIsActive = isActive;
  }),
  setQueueScanLockSeconds: action((state, seconds) => {
    state.queueScanLockSeconds = seconds;
  }),
  setProjectTasks: action((state, tasks) => {
    state.projectTasks = tasks;
    const limit =
      state.user.taskLimit && tasks.length >= state.user.taskLimit
        ? true
        : false;
    state.taskLimit = limit;
  }),

  // setTaskLimit: action((state, { length, taskLimit }) => {
  //   length >= taskLimit ? (state.taskLimit = true) : (state.taskLimit = false);
  // }),
  setFilteredProjectTasks: action((state, text) => {
    let rValue = state.projectTasks.filter((t) => {
      const regex = new RegExp(`${text}`, "gi");
      return (
        t.ProjectTaskDescription.value.match(regex) ||
        t.ProjectDescription.value.match(regex) ||
        t.ProjectTaskID.value.match(regex)
      );
    });

    if (rValue.length === 0) {
      rValue = [];
    }
    state.filteredProjectTasks = rValue;
  }),
  clearProjectTasksFilter: action((state) => {
    state.filteredProjectTasks = null;
  }),
  setProjectSearchValue: action((state, text) => {
    state.projectSearchValue = text;
  }),
  clearProjectSearchValue: action((state) => {
    state.projectSearchValue = "";
  }),
  setFoundProjects: action((state, foundProjects) => {
    state.foundProjects = foundProjects;
  }),
  setFilteredFoundProject: action((state, projectId) => {
    state.filteredFoundProjects = state.foundProjects.filter(
      (p) => p.ProjectID === projectId
    );
  }),
  clearTaskFilter: action((state) => {
    state.filteredTasks = null;
  }),
  setFilteredTasks: action((state, text) => {
    let rValue = state.tasksByFilteredFoundProject.filter((t) => {
      const regex = new RegExp(`${text}`, "gi");
      return (
        t.ProjectTaskDescription.match(regex) ||
        t.TaskCD.match(regex) ||
        t.ProjectCD.match(regex)
      );
    });

    if (rValue.length === 0) {
      rValue = [];
    }
    state.filteredTasks = rValue;
  }),
  setTasksByFilteredFoundProject: action((state, tasks) => {
    const qualifications = state.qualifications;
    const qualificationChecks = state.user.qualificationChecks;
    const { inspectorQualified } = qualifications;
    const statusToCheckTypes = ["Inspection", "Supervisor"];
    const bypassQuals = state.user.BypassQuals;

    tasks.forEach((t, index) => {
      if (!bypassQuals) {
        tasks[index].qualified = true;
        if (qualificationChecks.includes("customer")) {
          tasks[index] = checkQualifications(
            t,
            qualifications[t.Customer],
            "customer",
            []
          );
        }
        if (
          tasks[index].qualified &&
          qualificationChecks.includes("personal")
        ) {
          tasks[index] = checkQualifications(
            t,
            qualifications["personal"],
            "personal",
            []
          );
        }
        if (
          tasks[index].qualified &&
          qualificationChecks.includes("type") &&
          statusToCheckTypes.includes(tasks[index].TaskStatus)
        ) {
          tasks[index].qualified = false;
          tasks[index] = checkQualifications(
            t,
            qualifications["type"],
            "type",
            inspectorQualified
          );
        }
      } else {
        t.qualified = true;
      }
    });

    state.tasksByFilteredFoundProject = tasks;
  }),
  cleanupSearch: action((state) => {
    state.projectSearchValue = "";
    state.foundProjects = null;
    state.filteredTasks = null;
    state.filteredFoundProjects = null;
    state.tasksByFilteredFoundProject = null;
  }),
  setCurrentTask: action((state, task) => {
    const currentTask = state.projectTasks.filter(
      (p) => p.ProjectTaskID.value === task
    );

    state.currentTask = currentTask[0];
    const Inspector = state.user.Inspector;
    const TaskStatus = currentTask[0].TaskStatus.value;
    const ShowNRButton = currentTask[0].CreateNRFromNR.value;
    Inspector === "YES" && TaskStatus === "Inspection"
      ? (state.showInspectorButtons = true)
      : (state.showInspectorButtons = false);
    ShowNRButton === "YES"
      ? (state.showNonRoutineButton = true)
      : (state.showNonRoutineButton = false);
  }),
  toggleCurrentBillableTaskAction: action((state) => {
    const isNonBillable =
      state.currentTask.NonBillable && state.currentTask.NonBillable.value;

    state.currentTask = {
      ...state.currentTask,
      NonBillable: { value: !isNonBillable },
    };
  }),
  cleanupCurrentTask: action((state) => {
    state.currentTask = null;
  }),
  setActiveCarouselIndex: action((state, index) => {
    state.activeCarouselIndex = index;
  }),
  setCarouselAnimating: action((state, animate) => {
    state.carouselAnimating = animate;
  }),
  setNonRoutineTask: action((state, task) => {
    // let obj = state.nonRoutineTask;
    // obj = { ...obj, keyValue };
    // state.nonRoutineTask = obj;
    // state.nonRoutineTask = { ...state.nonRoutineTask, ...keyValue };

    state.nonRoutineTask[Object.keys(task)[0]] = task[Object.keys(task)[0]];
    //state.nonRoutineTask = task;
  }),
  setNonRoutineOptions: action((state, options) => {
    const naturalCompare = (a, b) => {
      var ax = [],
        bx = [];

      a.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
        ax.push([$1 || Infinity, $2 || ""]);
      });
      b.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
        bx.push([$1 || Infinity, $2 || ""]);
      });

      while (ax.length && bx.length) {
        var an = ax.shift();
        var bn = bx.shift();
        var nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
        if (nn) return nn;
      }

      return ax.length - bx.length;
    };
    options.chapters &&
      options.chapters.length &&
      (options.chapters = options.chapters.sort((a, b) =>
        naturalCompare(a.ChapterID, b.ChapterID)
      ));
    options.subChapters &&
      options.subChapters.length &&
      (options.subChapters = options.subChapters.sort((a, b) =>
        naturalCompare(
          a.ChapterID.toString() + a.SubChapterID.toString(),
          b.ChapterID.toString() + b.SubChapterID.toString()
        )
      ));
    options.zones &&
      options.zones.length &&
      (options.zones = options.zones.sort((a, b) =>
        naturalCompare(a.ZoneID, b.ZoneID)
      ));
    options.stsZones &&
      options.stsZones.length &&
      (options.stsZones = options.stsZones.sort((a, b) =>
        naturalCompare(a.ZoneID, b.ZoneID)
      ));
    options.priorityLevels &&
      options.priorityLevels.length &&
      (options.priorityLevels = options.priorityLevels.sort((a, b) =>
        naturalCompare(a.PriorityLevel, b.PriorityLevel)
      ));

    options.laborItems &&
      options.laborItems.length &&
      (options.laborItems = options.laborItems.sort((a, b) =>
        naturalCompare(a.InventoryID, b.InventoryID)
      ));

    options.itemConditions &&
      options.itemConditions.length &&
      (options.itemConditions = options.itemConditions.sort((a, b) =>
        naturalCompare(a.ConditionID, b.ConditionID)
      ));

    state.nonRoutineOptions = options;
    state.priorityLevelOptions = options.priorityLevels;
  }),
  setShowNonRoutineOptions: action((state, show) => {
    state.showNonRoutineOptions = show;
  }),
  setNonRoutineOptionType: action((state, type) => {
    state.nonRoutineOptionType = type;
  }),
  setFilteredOptions: action((state, filter) => {
    let rValue = state.nonRoutineOptions[filter.type].filter((o) => {
      const regex = new RegExp(`${filter.text}`, "gi");

      if (filter.type === "chapters") {
        return o.ChapterID.match(regex) || o.Description.match(regex);
      } else if (filter.type === "subChapters") {
        let chapter = state.ataChapterID;
        return (
          o.ChapterID === chapter &&
          (o.SubChapterID.match(regex) || o.Description.match(regex))
        );
      } else if (filter.type === "zones" || filter.type === "stsZones") {
        return o.ZoneID.match(regex) || o.Description.match(regex);
      } else {
        return false;
      }
    });

    if (rValue.length === 0) {
      rValue = [];
    }
    state.filteredNonRoutineOptions = rValue;
  }),
  updateRejectedReasonForTask: action((state, payload) => {
    const { reason, projectTasks, currentTaskId } = payload;

    const updatedProjectTasks = projectTasks.map((pt) => {
      if (
        pt.ProjectTaskID.value &&
        pt.ProjectTaskID.value.toString() === currentTaskId.toString()
      ) {
        pt.RejectedReason = {
          value: reason,
        };
      }
      return pt;
    });

    state.projectTasks = updatedProjectTasks;
  }),
  clearOptionsFilter: action((state) => {
    state.optionsFilterText = "";
    state.filteredNonRoutineOptions = null;
  }),
  setOptionsFilterText: action((state, text) => {
    state.optionsFilterText = text;
  }),
  setAtaChapterID: action((state, chapter) => {
    state.ataChapterID = chapter;
  }),
  setSTSZoneAction: action((state, zone) => {
    state.STSZone = zone;
  }),
  setAtaSubChapterID: action((state, subChapter) => {
    state.ataSubChapterID = subChapter;
  }),
  setZoneID: action((state, zone) => {
    state.zoneID = zone;
  }),
  setLaborItemID: action((state, laborItem) => {
    state.laborItemID = laborItem;
  }),
  setFilteredNonRoutingSubOptions: action((state, filter) => {
    state.filteredNonRoutingSubOptions = (
      state.nonRoutineOptions.subChapters || []
    ).filter((s) => {
      return s.ChapterID === filter;
    });
  }),
  setNonRoutineTaskItem: action((state, keyValue) => {
    state.nonRoutineTask[keyValue.key] = keyValue.value;
  }),
  clearNonRoutineTaskItemExceptNone: action((state) => {
    state.nonRoutineTask.isEWIS = "";
    state.nonRoutineTask.isRII = "";
    state.nonRoutineTask.isCriticalTask = "";
    state.nonRoutineTask.isCDCCL = "";
    state.nonRoutineTask.isAWLI = "";
  }),
  clearNonRoutine: action((state) => {
    if (state.currentTask.nonRoutineMatchMXTypes) {
      state.currentTask.MXIsNone.value &&
        (state.nonRoutineTask.isNone = state.currentTask.MXIsNone.value);
      state.currentTask.MXIsEWIS.value &&
        (state.nonRoutineTask.isEWIS = state.currentTask.MXIsEWIS.value);
      state.currentTask.MXIsRII.value &&
        (state.nonRoutineTask.isRII = state.currentTask.MXIsRII.value);
      state.currentTask.MXIsCriticalTask.value &&
        (state.nonRoutineTask.isCriticalTask =
          state.currentTask.MXIsCriticalTask.value);
      state.currentTask.MXIsCDCCL.value &&
        (state.nonRoutineTask.isCDCCL = state.currentTask.MXIsCDCCL.value);
      state.currentTask.MXIsAWLI.value &&
        (state.nonRoutineTask.isAWLI = state.currentTask.MXIsAWLI.value);
      state.nonRoutineTask.title = "";
      state.nonRoutineTask.defect = "";
      state.nonRoutineTask.ataChhapterID = "";
      state.nonRoutineTask.ataSubChapterID = "";
      state.nonRoutineTask.zoneID = "";
      state.nonRoutineTask.hours = "";
    } else {
      state.nonRoutineTask = {
        title: "",
        defect: "",
        ataChhapterID: "",
        ataSubChapterID: "",
        zoneID: "",
        isNone: true,
        isEWIS: "",
        isRII: "",
        isCriticalTask: "",
        isCDCCL: "",
        isAWLI: "",
        hours: "",
      };
    }
    state.showNonRoutineOptions = false;
    state.nonRoutineOptionType = "";
    state.optionsFilterText = "";
    state.filteredNonRoutineOptions = null;
    state.filteredNonRoutingSubOptions = null;
    state.ataChapterID = "";
    state.ataSubChapterID = "";
    state.zoneID = "";
    state.laborItemID = "";
  }),
  // clearFilteredOptions: action((state) => {
  //   state.filteredNonRoutineOptions = null;
  //   state.filteredNonRoutineSubOptions = null;
  // }),
  setCorrectiveAction: action((state, text) => {
    state.correctiveAction = text;
  }),
  setBarCode: action((state, text) => {
    state.barCode = text;
  }),
  setShowInspectionRejectModal: action((state, show) => {
    state.showInspectionRejectModal = show;
  }),
  setRejectedReason: action((state, reason) => {
    state.rejectedReason = reason;
  }),
  resetNonRoutineOptions: action((state) => {
    state.filteredNonRoutineOptions = null;
    state.nonRoutineOptionType = "";
    state.showNonRoutineOptions = false;
    state.optionsFilterText = "";
  }),
  setNonRoutineMXTypes: action((state) => {
    state.currentTask.MXIsNone.value &&
      (state.nonRoutineTask.isNone = state.currentTask.MXIsNone.value);
    state.currentTask.MXIsEWIS.value &&
      (state.nonRoutineTask.isEWIS = state.currentTask.MXIsEWIS.value);
    state.currentTask.MXIsRII.value &&
      (state.nonRoutineTask.isRII = state.currentTask.MXIsRII.value);
    state.currentTask.MXIsCriticalTask.value &&
      (state.nonRoutineTask.isCriticalTask =
        state.currentTask.MXIsCriticalTask.value);
    state.currentTask.MXIsCDCCL.value &&
      (state.nonRoutineTask.isCDCCL = state.currentTask.MXIsCDCCL.value);
    state.currentTask.MXIsAWLI.value &&
      (state.nonRoutineTask.isAWLI = state.currentTask.MXIsAWLI.value);
  }),
  setServiceTypeAction: action((state, type) => {
    state.serviceType = type;
    state.servicePackageList = [];
    state.chosenRootServiceProfileOption = {
      Description: "",
      RootID: "",
      Sortrder: 0,
      ServiceProfileCategoryID: "",
    };
    state.childServiceProfileOptions = [];
    state.treeChildServiceProfileDropdowns = [];
    state.chosenTreeChildServiceProfileOptions = [];
    state.serviceProfileList = [];
    state.chosenService = null;
  }),
  setChosenServiceAction: action((state, service) => {
    state.chosenService = service;
  }),
  setServicePackageListAction: action((state, list) => {
    state.servicePackageList = list;
  }),
  setRootServiceProfileOptionsAction: action((state, list) => {
    state.rootServiceProfileOptions = list;
  }),
  setChosenRootServiceProfileOptionAction: action((state, option) => {
    state.chosenRootServiceProfileOption = option;
    state.treeChildServiceProfileDropdowns = [];
    state.chosenTreeChildServiceProfileOptions = [];
  }),
  setChildServiceProfileOptionsAction: action((state, list) => {
    state.childServiceProfileOptions = list;
  }),
  setTreeChildServiceProfileDropdownsAction: action(
    (state, { index, options }) => {
      state.treeChildServiceProfileDropdowns =
        options && options.length
          ? [...state.treeChildServiceProfileDropdowns.slice(0, index), options]
          : state.treeChildServiceProfileDropdowns.slice(0, index);
    }
  ),
  setChosenTreeChildServiceProfileOptionsAction: action(
    (state, { index, option }) => {
      state.chosenTreeChildServiceProfileOptions = [
        ...state.chosenTreeChildServiceProfileOptions.slice(0, index),
        option,
      ];
    }
  ),
  setServiceOptionListAction: action((state, list) => {
    state.serviceProfileList = list.map((svcProfile) => ({
      SvcProfileDescription: svcProfile,
      chosen: false,
    }));
  }),
  chooseServiceProfileAction: action((state, index) => {
    state.serviceProfileList = [
      ...state.serviceProfileList.slice(0, index),
      {
        ...state.serviceProfileList[index],
        chosen: !state.serviceProfileList[index].chosen,
      },
      ...state.serviceProfileList.slice(index + 1),
    ];
  }),
  setTaskServerSideSearchValueAction: action((state, value) => {
    state.taskServerSideSearchValue = value;
  }),
  setChosenProjectIdAction: action((state, projectId) => {
    state.chosenProjectId = projectId;
  }),
};

export default projectActions;
