import { centerText } from "../utilities/document"
import { drawBox } from "./utilities/document";

export const showRotables = (doc, startX, startY) => {
    centerText(doc, "ROTABLES/EXPENDABLES/CONSUMABLES USED RECORD", doc.page.width - 50 - startX, startX, startY, {
        bold: true,
    });

    const [tableStartY, cellHeight] = [doc.y + 15, 15];
    const tableColumns = [
        {
            cells: ["Part No. Of", "", "", "", ""]
        },
        {
            cells: ["Part No. On", "", "", "", ""],
        },
        {
            offset: 100,
            cells: ["Description", "", "", "", ""],
        },
        {
            cells: ["Serial No. Off", "", "", "", ""],
        },
        {
            cells: ["Serial No. On", "", "", "", ""],
        },
        {
            offset: 25,
            cells: ["Position", "", "", "", ""],
        },
        {
            cells: ["Batch No.", "", "", "", ""],
        },
    ];

    let tableCursorX = startX;
    doc.fontSize(9);
    tableColumns.forEach(({ cells, offset }) => {
        let tableCursorY = tableStartY;
        const cellWidth = doc.widthOfString(cells[0]) + (offset || 15);
        cells.forEach(textInCell => {
            drawBox(doc, tableCursorX, tableCursorY, cellWidth, cellHeight);
            centerText(doc, textInCell, cellWidth, tableCursorX, tableCursorY + 4);
            tableCursorY += cellHeight;
        });
        tableCursorX += cellWidth;
    });
}