import PDFDocument from "@react-pdf/pdfkit";
import blobStream from "blob-stream";

import {
  makeNewPage,
  makeSection,
  DESCRIPTION_FONT_SIZE,
  makeSubsection,
  makeSubsectionHeading,
  makeLine,
} from "../reports/utilities/document";

const createOrderManifest = (blob, currentTask, partsRequestList) => {
  let lastItem = {};
  try {
    const {
      WorkOrderID,
      TaskCD,
      ProjectDescription,
      TaskDescription,
      EquipID,
      CustomerName,
    } = currentTask;

    const doc = new PDFDocument({
      autoFirstPage: false,
      layout: "landscape",
    });

    const stream = doc.pipe(blobStream());

    // Divide parts request list into sections of 20 items
    const partsRequestListSections = [];
    let currentSection = [];
    partsRequestList.forEach((item, index) => {
      currentSection.push(item);
      if (index % 20 === 19) {
        partsRequestListSections.push(currentSection);
        currentSection = [];
      }
    });
    if (currentSection.length > 0) {
      partsRequestListSections.push(currentSection);
    }
    // Count how many pages will be needed
    const totalPage = partsRequestListSections.length;

    // Create a page for each section after the first
    partsRequestListSections.forEach((section, index) => {
      const page = makeNewPage(
        doc,
        index + 1,
        currentTask,
        blob,
        "Part Request Manifest",
        totalPage
      );
      page.fontSize(DESCRIPTION_FONT_SIZE);

      //   page.moveDown();

      let yIndex = 165;

      // If its not the first page

      //index !== 0 && (yIndex = yIndex - 50);  //account for header if not first page

      /* #region  make repeating page contents */
      section.forEach((item, index) => {
        if (
          item.InventoryID !== lastItem.InventoryID &&
          item.ItemReqID !== lastItem.ItemReqID
        ) {
          makeSubsection(page, item.InventoryID, 15, yIndex + index * 20, 100);
        }

        item.InventoryID !== lastItem.InventoryID &&
          makeSubsection(
            page,
            parseFloat(item.RequestedQty).toFixed(2),
            100,
            yIndex + index * 20,
            50
          );

        makeSubsection(page, item.OrderNbr, 145, yIndex + index * 20, 100);
        makeSubsection(page, item.SOLineNbr, 180, yIndex + index * 20, 50);
        makeSubsection(
          page,
          parseFloat(item.SOLineQty).toFixed(2),
          205,
          yIndex + index * 20,
          50
        );
        makeSubsection(
          page,
          parseFloat(item.IssuedQty).toFixed(2),
          235,
          yIndex + index * 20,
          50
        );
        const openQty = parseFloat(item.OpenQty).toFixed(2);
        openQty !== "0.00" &&
          makeSubsection(
            page,
            parseFloat(item.OpenQty).toFixed(2),
            265,
            yIndex + index * 20,
            50
          );

        if (
          item.InventoryID !== lastItem.InventoryID ||
          item.ItemReqID !== lastItem.ItemReqID ||
          item.OrderNbr !== lastItem.OrderNbr
        ) {
          makeSubsection(
            page,
            item.RequestedByName,
            303,
            yIndex + index * 20,
            50
          );
          makeSubsection(page, item.ItemReqID, 363, yIndex + index * 20, 50);
          makeSubsection(page, item.Note, 430, yIndex + index * 20, 250);
        }

        lastItem = item;
      });
      /* #endregion */

      // Add to the first page
      if (index >= 0) {
        //changed from === 0 so header would be on each page
        makeSection(page, `Customer: ${CustomerName}`, 15, 85);

        makeLine(page, 3, [15, 96], [770, 96]);

        page.fontSize(DESCRIPTION_FONT_SIZE);

        //   page.moveDown();

        makeSubsectionHeading(page, "Work Order", 15, 110, 80);
        makeSubsectionHeading(page, "Description", 95, 110, 500);

        makeSubsectionHeading(page, "Task", 250, 110, 50);
        makeSubsectionHeading(page, "Task Description", 400, 110, 50);

        makeSubsectionHeading(
          page,
          "Aircraft Registration Number",
          595,
          110,
          300
        );

        makeLine(page, 1, [15, 121], [770, 121]);

        //   page.moveDown();

        makeSubsection(page, WorkOrderID, 15, 125, 80);
        makeSubsection(page, ProjectDescription, 95, 125, 500);
        makeSubsection(page, TaskCD, 250, 125, 100);
        makeSubsection(page, TaskDescription, 400, 125, 100);
        makeSubsection(page, EquipID, 595, 125, 300);

        makeSubsectionHeading(page, "Inventory CD", 15, 150, 100);
        makeSubsectionHeading(page, "Requested", 100, 150, 50);
        makeSubsectionHeading(page, "Order", 145, 150, 100);
        makeSubsectionHeading(page, "Line", 180, 150, 50);
        makeSubsectionHeading(page, "Ordered  ", 205, 150, 50);
        makeSubsectionHeading(page, "Issued", 235, 150, 50);
        makeSubsectionHeading(page, "Open", 265, 150, 50);
        makeSubsectionHeading(page, "Requested By", 303, 150, 50);
        makeSubsectionHeading(page, "Item Req Number", 363, 150, 50);
        makeSubsectionHeading(page, "Part Description", 430, 150, 250);

        makeLine(page, 1, [15, 161], [770, 161]);
      }

      // Add to the last page
      if (index === partsRequestListSections.length - 1) {
        page.fontSize(DESCRIPTION_FONT_SIZE - 2);
        makeSubsection(page, "WOREQ1", 15, 570, 100);
        makeSubsection(page, "CRS# FAA  CRS# 1E1R898B", 15, 577, 100);
      }
    });

    doc.end();

    stream.on("finish", function () {
      // get a blob you can do whatever you like with
      // const blob = stream.toBlob('application/pdf');
      // or get a blob URL for display in the browser
      const url = stream.toBlobURL("application/pdf");
      window.open(url, "_blank");
    });
  } catch (err) {
    console.log(err);
  }
};
export default createOrderManifest;
