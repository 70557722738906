import React from "react";
import { useHistory } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import TaskItem from "../taskCarousel/TaskItem";
import PartsRequest from "../taskCarousel/PartsRequest";
import NonRoutineItem from "../taskCarousel/NonRoutineItem";
import InspectionReject from "../taskCarousel/InspectionReject";
import UnserviceablePart from "../taskCarousel/UnserviceablePart";
import Spinner from "../layout/Spinner";
import moment from "moment";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import { Carousel, CarouselItem } from "reactstrap";

const Task = () => {
  const {
    loading,
    carouselItems,
    activeCarouselIndex,
    interval,
    animating,
    task,
    correctiveAction,
    projectTasks,
    currentTask,
    showInspectorButtons,
    showNonRoutineButton,
    showUnservicablePopup,
    TaskTitle,
    CorrectiveAction,
    NonRoutineTitle,
    user,
  } = useStoreState((state) => ({
    user: state.user,
    loading: state.loading,
    carouselItems: state.carouselItems,
    activeCarouselIndex: state.activeCarouselIndex,
    interval: state.carouselInterval,
    animating: state.carouselAnimating,
    task: state.currentTask,
    correctiveAction: state.correctiveAction,
    projectTasks: state.projectTasks,
    currentTask: state.currentTask,
    showInspectorButtons: state.showInspectorButtons,
    showNonRoutineButton: state.showNonRoutineButton,
    showUnservicablePopup: state.showUnservicablePopup,
    TaskTitle: state.user.customLabels.task.Title,
    CorrectiveAction: state.user.customLabels.task.CorrectiveAction,
    NonRoutineTitle: state.user.customLabels.nonRoutine.Title,
  }));

  const {
    setAlerts,
    setAnimating,
    setActiveCarouselIndex,
    setLoading,
    setFilteredNonRoutingSubOptions,
    removeTasksFromProjectTasks,
    setPage,
    setCorrectiveAction,
    setShowInspectionRejectModal,
    getPartReq,
    setShowUnservicablePopup,
    setAtaChapterID,
    setAtaSubChapterID,
    setZoneID,
    getRootServiceProfileOptionsThunk,
    setServiceTypeAction,
    setSTSZoneAction,
    toggleBillableTaskThunk,
  } = useStoreActions((actions) => ({
    setAlerts: actions.setAlerts,
    setAnimating: actions.setCarouselAnimating,
    setActiveCarouselIndex: actions.setActiveCarouselIndex,
    setLoading: actions.setLoading,
    setFilteredNonRoutingSubOptions: actions.setFilteredNonRoutingSubOptions,
    removeTasksFromProjectTasks: actions.removeTasksFromProjectTasks,
    setPage: actions.setPage,
    setCorrectiveAction: actions.setCorrectiveAction,
    setShowInspectionRejectModal: actions.setShowInspectionRejectModal,
    getPartReq: actions.getPartReq,
    setShowUnservicablePopup: actions.setShowUnservicablePopup,
    setAtaChapterID: actions.setAtaChapterID,
    setAtaSubChapterID: actions.setAtaSubChapterID,
    setZoneID: actions.setZoneID,
    getRootServiceProfileOptionsThunk:
      actions.getRootServiceProfileOptionsThunk,
    setServiceTypeAction: actions.setServiceTypeAction,
    setSTSZoneAction: actions.setSTSZoneAction,
    toggleBillableTaskThunk: actions.toggleBillableTaskThunk,
  }));

  const next = () => {
    if (animating) return;
    // eslint-disable-next-line no-restricted-globals
    if (event.keyCode === 39) return;
    const nextIndex =
      activeCarouselIndex === carouselItems.length - 1
        ? 0
        : activeCarouselIndex + 1;
    setActiveCarouselIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    // eslint-disable-next-line no-restricted-globals
    if (event.keyCode === 37) return;
    const nextIndex =
      activeCarouselIndex === 0
        ? carouselItems.length - 1
        : activeCarouselIndex - 1;
    setActiveCarouselIndex(nextIndex);
  };

  const taskItem = () => {
    setActiveCarouselIndex(0);
    //setAlerts({ error: 'NON ROUTINE function selected', type: 'warning' });
  };

  const partsRequest = () => {
    setActiveCarouselIndex(1);
    //getStockItems();
    setLoading(true);
    getPartReq();
    //setAlerts({ error: 'PARTS REQUEST function selected', type: 'warning' });
  };

  const nonRoutine = () => {
    setActiveCarouselIndex(2);
    if (currentTask.ATAChapter) {
      setAtaChapterID(currentTask.ATAChapter.value);
      setFilteredNonRoutingSubOptions(currentTask.ATAChapter.value);
    }
    currentTask.ATASubChapter &&
      setAtaSubChapterID(currentTask.ATASubChapter.value);
    currentTask.UsrZoneID && setZoneID(currentTask.UsrZoneID.value);
    if (user.serviceProfilesNonRoutine) {
      getRootServiceProfileOptionsThunk();
      setServiceTypeAction(user.addTaskTypes[0]);
    } else if (
      user.stsZone &&
      currentTask.STSZone &&
      currentTask.STSZone.value
    ) {
      setSTSZoneAction(currentTask.STSZone.value);
    }
  };

  /* #region  Mark Complete */
  const markComplete = () => {
    /* #region  CA Warning */
    if (
      correctiveAction !== "" &&
      !currentTask.correctiveActionOnMarkComplete
    ) {
      setAlerts({
        error: `${CorrectiveAction} can not be recorded on Mark Complete action!`,
        type: "warning",
      });
      setCorrectiveAction("");
      return;
    }
    /* #endregion */
    if (correctiveAction !== "") {
      const projectToProcess = projectTasks.filter((p) => {
        return p.ProjectTaskID.value === currentTask.ProjectTaskID.value;
      });

      const project = projectToProcess[0];

      project.remove = true;
      project.CorrectiveAction = { value: correctiveAction };
      setCorrectiveAction("");

      project.process = "complete";

      removeTasksFromProjectTasks(project);

      history.push("/");
      setPage("home");
    } else {
      setAlerts({
        error: `${CorrectiveAction} cannot be blank`,
        type: "warning",
      });
    }
  };
  /* #endregion */

  /* #region  Inspection Complete */

  const inspectionComplete = () => {
    /* #region  CA Warning */
    if (
      correctiveAction !== "" &&
      !currentTask.correctiveActionOnInspectionComplete
    ) {
      setAlerts({
        error: `${CorrectiveAction} can not be recorded on Inspection Complete action!`,
        type: "warning",
      });
      setCorrectiveAction("");
      return;
    }
    /* #endregion */

    setLoading(true);

    const projectToProcess = projectTasks.filter((p) => {
      return p.ProjectTaskID.value === currentTask.ProjectTaskID.value;
    });

    const project = projectToProcess[0];

    project.remove = true;
    currentTask.correctiveActionOnInspectionComplete && correctiveAction
      ? (project.CorrectiveAction = { value: correctiveAction })
      : (project.CorrectiveAction = { value: "" });
    setCorrectiveAction("");

    project.process = "inspectionComplete";

    removeTasksFromProjectTasks(project);

    history.push("/");
    setPage("home");
  };
  /* #endregion */

  /* #region  Stop Work */
  const stopWork = () => {
    /* #region  CA Warning */
    if (correctiveAction !== "" && !currentTask.correctiveActionOnStopWork) {
      setAlerts({
        error: `${CorrectiveAction} can not be recorded on Stop Work action!`,
        type: "warning",
      });
      setCorrectiveAction("");
      return;
    }
    /* #endregion */

    const projectToProcess = projectTasks.filter((p) => {
      return p.ProjectTaskID.value === currentTask.ProjectTaskID.value;
    });

    const project = projectToProcess[0];
    projectToProcess[0].CorrectiveAction = { value: "" };

    project.remove = true;
    currentTask.correctiveActionOnStopWork &&
      correctiveAction &&
      (project.CorrectiveAction = { value: correctiveAction });
    setCorrectiveAction("");

    project.process = "incomplete";

    removeTasksFromProjectTasks(project);

    history.push("/");
    setPage("home");
  };

  /* #endregion */

  const openInspectionRejectModal = () => {
    setShowInspectionRejectModal(true);
  };

  let history = useHistory();

  /* #region  Slides Definition */
  const slides = carouselItems.map((item, index) => {
    if (task) {
      return (
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={task.id + "-" + index}
        >
          {activeCarouselIndex === 0 ? (
            <TaskItem key="taskItem" />
          ) : activeCarouselIndex === 1 ? (
            <PartsRequest key="partsRequest" />
          ) : activeCarouselIndex === 2 ? (
            <NonRoutineItem key="nonRoutine" />
          ) : activeCarouselIndex === 3 ? (
            <UnserviceablePart key="unsvcPart" />
          ) : (
            <div>No Match Found for Carousel</div>
          )}
        </CarouselItem>
      );
    } else {
      return "";
    }
  });
  /* #endregion */

  if (!task) {
    history.push("/");
    window.location.reload(false);
  }

  if (loading) {
    return <Spinner />;
  }

  /* #region  Get Time */
  const getTime = (timeInput) => {
    const offset = new Date().getTimezoneOffset();

    const offsetCorrectedTime = moment(
      new Date(timeInput).toUTCString()
    ).subtract(offset, "minutes");
    //return offsetCorrectedTime.format('dddd YYYY-MM-DD HH:mm a');
    return offsetCorrectedTime.format("llll");
  };
  /* #endregion */

  // const getButtonCount = () => {
  //   let buttonCount = 6;

  //   !showInspectorButtons && (buttonCount -= 2);
  //   !showNonRoutineButton && (buttonCount -= 1);
  //   task.unserviceableParts !== undefined &&
  //     !task.unserviceableParts &&
  //     (buttonCount -= 1);
  //   task.TaskStatus.value === 'Inspection' && (buttonCount -= 1);
  //   task.TaskStatus.value === 'Inspection' &&
  //     task.hideInspectionReject &&
  //     (buttonCount -= 1);
  //   (activeCarouselIndex === 1 || activeCarouselIndex === 2) &&
  //     (buttonCount -= 1);

  //   return buttonCount.toString();
  // };
  const getButtonCount = () => {
    let buttonCount = 6;

    !showInspectorButtons && (buttonCount -= 2);
    (!showNonRoutineButton || user.hideNonRoutine) && (buttonCount -= 1);
    task.unserviceableParts !== undefined &&
      !task.unserviceableParts &&
      (buttonCount -= 1);
    task.TaskStatus.value === "Inspection" && (buttonCount -= 1);
    task.TaskStatus.value === "Inspection" &&
      task.hideInspectionReject &&
      (buttonCount -= 1);
    (activeCarouselIndex === 1 || activeCarouselIndex === 2) &&
      (buttonCount -= 1);

    task.TaskStatus.value !== "Inspection" &&
      !activeCarouselIndex &&
      user.BypassQuals === true &&
      user.hideMarkComplete === true &&
      (buttonCount = 3);

    ((user.hidePartsRequestOnInspection &&
      task.TaskStatus.value === "Inspection") ||
      user.hidePartRequest) &&
      (buttonCount -= 1);

    return buttonCount.toString();
  };

  const toggleUnservicableModal = () => {
    setShowUnservicablePopup(!showUnservicablePopup);
  };

  return (
    <div className="jumbotron text-center inner-scroll">
      <Row>
        <Col xs={12} md={3} />

        <Col xs={12} md={6}>
          <h4 className="text-success">
            {TaskTitle}: {task.ProjectTaskID.value} -{" "}
            {task.ProjectDescription.value}
          </h4>
          <h6>
            Status:{" "}
            <span
              className={
                task.TaskStatus.value === "Active"
                  ? "text-success"
                  : task.TaskStatus.value === "Inspection"
                  ? "text-warning"
                  : task.TaskStatus.value === "Reject"
                  ? "text-danger"
                  : "text-dark"
              }
            >
              {" "}
              {task.TaskStatus.value}
            </span>
          </h6>
          <InspectionReject />
          <p>Open Since {getTime(task.TimeUTC.value)}</p>
        </Col>

        <Col xs={12} md={3}>
          {user.nonBillableTask && (
            <Button
              className="bg-transparent"
              disabled={task.NonBillable && task.NonBillable.value}
              onClick={() => toggleBillableTaskThunk()}
            >
              <Row
                style={{
                  justifyContent: "center",
                }}
              >
                <div
                  className={`text-white ${
                    task.NonBillable && task.NonBillable.value
                      ? "bg-danger"
                      : "bg-success"
                  }`}
                  style={{
                    borderRadius: "50%",
                    padding: "4px",
                    paddingInline: "12px",
                  }}
                >
                  $
                </div>
              </Row>

              <div style={{ color: "#333" }}>{`Mark ${
                task.NonBillable && task.NonBillable.value ? "" : "Un-"
              }Billable`}</div>
            </Button>
          )}
        </Col>
      </Row>

      <Carousel
        activeIndex={0}
        next={next}
        previous={previous}
        interval={interval}
      >
        {slides}
      </Carousel>
      <div className={`grid-${getButtonCount()} lead`}>
        {activeCarouselIndex === 0 &&
        showNonRoutineButton &&
        !user.hideNonRoutine ? (
          <div onClick={nonRoutine}>
            <i className="fas fa-exclamation-triangle text-warning"></i>
            <div className="icon-description text-center">
              {NonRoutineTitle}
            </div>
          </div>
        ) : activeCarouselIndex !== 0 ? (
          <div onClick={taskItem}>
            <i className="fa fa-tasks text-gray"></i>
            <div className="icon-description text-center">{TaskTitle}</div>
          </div>
        ) : (
          ""
        )}

        {activeCarouselIndex !== 1 ? (
          <div
            onClick={partsRequest}
            className={
              (user.hidePartsRequestOnInspection &&
                task.TaskStatus.value === "Inspection") ||
              user.hidePartRequest
                ? "d-none"
                : ""
            }
          >
            <i className="fas fa-cogs text-primary"></i>
            <div className="icon-description text-center">Parts Request </div>
          </div>
        ) : showNonRoutineButton && !user.hideNonRoutine ? (
          <div onClick={nonRoutine}>
            <i className="fas fa-exclamation-triangle text-warning"></i>
            <div className="icon-description text-center">
              {NonRoutineTitle}
            </div>
          </div>
        ) : (
          ""
        )}

        {/* {showInspectorButtons && ( */}
        {/* {task.TaskStatus.value !== 'Inspection' && !activeCarouselIndex && (
          // If the bypassQuals is True and hideMarkComplete is True, then dont show it
          // if the bypassQuals is False and hideMarkComplete is False, then dont show it
          // if the bypassQuals is True and hideMarkComplete is False, then show it
          <div
            onClick={markComplete}
            name="markComplete"
            value={task.ProjectTaskID.value}
          >
            <i className="fa fa-check text-success"></i>
            <div className="icon-description text-center">Mark Complete</div>
          </div>
        )} */}
        {task.TaskStatus.value !== "Inspection" &&
          !activeCarouselIndex &&
          (user.BypassQuals && user.hideMarkComplete ? null : (
            <div
              onClick={markComplete}
              name="markComplete"
              value={task.ProjectTaskID.value}
            >
              <i className="fa fa-check text-success"></i>
              <div className="icon-description text-center">Mark Complete</div>
            </div>
          ))}
        {/* )} */}
        <div //removed per #20725 mdh03292021
          onClick={stopWork}
          name="stopWork"
          value={task.ProjectTaskID.value}
        >
          <i className="far fa-hand-paper text-danger"></i>
          <div className="icon-description text-center">Stop Work</div>
        </div>
        {showInspectorButtons && (
          <div //added per #20725 mdh03292021
            onClick={inspectionComplete}
            name="inspectionComplete"
            value={task.ProjectTaskID.value}
          >
            <i className="far fa-thumbs-up text-warning"></i>
            <div className="icon-description text-center">
              Inspection Complete
            </div>
          </div>
        )}
        {showInspectorButtons && !task.hideInspectionReject && (
          <div
            onClick={openInspectionRejectModal}
            name="inspectReject"
            value={task.ProjectTaskID.value}
          >
            <i className="far fa-times-circle text-danger"></i>
            <div className="icon-description text-center">
              Inspection Reject
            </div>
          </div>
        )}
        <Modal
          isOpen={showUnservicablePopup}
          centered
          toggle={toggleUnservicableModal}
        >
          <ModalBody>
            <UnserviceablePart />
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default Task;
