import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useStoreState, useStoreActions } from 'easy-peasy';

const UserWarning = ({ title, message, type }) => {
  const { showUserWarning } = useStoreState((state) => ({
    showUserWarning: state.showUserWarning,
  }));

  type = `bg-${type}`;

  const { setShowUserWarning } = useStoreActions((actions) => ({
    setShowUserWarning: actions.setShowUserWarning,
  }));

  const toggle = () => {
    setShowUserWarning(false);
  };

  return (
    <div>
      <Modal isOpen={showUserWarning} toggle={toggle}>
        <ModalHeader toggle={toggle} className={type}>
          {title}
        </ModalHeader>
        <ModalBody className="text-center">{message}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle} className="btn-block">
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

UserWarning.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.string,
};

UserWarning.defaultProps = {
  title: 'Kiosk Warning',
};

export default UserWarning;
