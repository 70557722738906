import React, { Fragment, useEffect, useState } from "react";
import NonRoutineOptions from "../nonRoutineModal/NonRoutineOptions";
import { useStoreState, useStoreActions } from "easy-peasy";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import "../../style.css";

const NonRoutineItem = () => {
  const [serviceProfileSearchInput, setServiceProfileSearchInput] =
    useState("");

  const {
    showNonRoutineOptions,
    ataChapterID,
    ataSubChapterID,
    zoneID,
    laborItemID,
    nonRoutineTask,
    currentTask,
    user,
    Chapter,
    Subchapter,
    Zone,
    None,
    EWIS,
    RII,
    CriticalTask,
    CDCCL,
    AWLI,
    Button,
    requiredNonRoutineItems,
    serviceType,
    serviceList,
    STSZone,
    rootServiceProfileOptions,
    chosenRootServiceProfileOption,
    treeChildServiceProfileDropdowns,
    chosenTreeChildServiceProfileOptions,
    serviceProfileList,
  } = useStoreState((state) => ({
    showNonRoutineOptions: state.showNonRoutineOptions,
    ataChapterID: state.ataChapterID,
    ataSubChapterID: state.ataSubChapterID,
    zoneID: state.zoneID,
    laborItemID: state.laborItemID,
    nonRoutineTask: state.nonRoutineTask,
    currentTask: state.currentTask,
    user: state.user,
    Chapter: state.user.customLabels.nonRoutine.Chapter,
    Subchapter: state.user.customLabels.nonRoutine.Subchapter,
    Zone: state.user.customLabels.nonRoutine.Zone,
    None: state.user.customLabels.nonRoutine.None,
    EWIS: state.user.customLabels.nonRoutine.EWIS,
    RII: state.user.customLabels.nonRoutine.RII,
    CriticalTask: state.user.customLabels.nonRoutine.CriticalTask,
    CDCCL: state.user.customLabels.nonRoutine.CDCCL,
    AWLI: state.user.customLabels.nonRoutine.AWLI,
    Button: state.user.customLabels.nonRoutine.Button,
    requiredNonRoutineItems: state.user.requiredNonRoutineItems,
    serviceType: state.serviceType,
    serviceList: state.serviceList || [],
    STSZone: state.STSZone,
    rootServiceProfileOptions: state.rootServiceProfileOptions,
    chosenRootServiceProfileOption: state.chosenRootServiceProfileOption,
    treeChildServiceProfileDropdowns: state.treeChildServiceProfileDropdowns,
    chosenTreeChildServiceProfileOptions:
      state.chosenTreeChildServiceProfileOptions,
    serviceProfileList: state.serviceProfileList,
  }));

  const {
    setLoading,
    addNonRoutineTask,
    setAlerts,
    setShowNonRoutineOptions,
    setNonRoutineOptionType,
    setNonRoutineTaskItem,
    clearNonRoutineTaskItemExceptNone,
    setAtaSubChapterID,
    setZoneID,
    setNonRoutineMXTypes,
    setServiceTypeAction,
    getServicePackageListThunk,
    addServiceTaskThunk,
    setChosenServiceValueAction,
    setSTSZoneAction,
    chooseRootServiceProfileOptionThunk,
    chooseChildServiceProfileOptionThunk,
    chooseServiceProfileAction,
    getRootServiceProfileOptionsThunk,
  } = useStoreActions((actions) => ({
    setLoading: actions.setLoading,
    addNonRoutineTask: actions.addNonRoutineTask,
    setAlerts: actions.setAlerts,
    setShowNonRoutineOptions: actions.setShowNonRoutineOptions,
    setNonRoutineOptionType: actions.setNonRoutineOptionType,
    setNonRoutineTaskItem: actions.setNonRoutineTaskItem,
    clearNonRoutineTaskItemExceptNone:
      actions.clearNonRoutineTaskItemExceptNone,
    setAtaSubChapterID: actions.setAtaSubChapterID,
    setZoneID: actions.setZoneID,
    setNonRoutineMXTypes: actions.setNonRoutineMXTypes,
    setServiceTypeAction: actions.setServiceTypeAction,
    getServicePackageListThunk: actions.getServicePackageListThunk,
    addServiceTaskThunk: actions.addServiceTaskThunk,
    setChosenServiceValueAction: actions.setChosenServiceValueAction,
    setSTSZoneAction: actions.setSTSZoneAction,
    chooseRootServiceProfileOptionThunk:
      actions.chooseRootServiceProfileOptionThunk,
    chooseChildServiceProfileOptionThunk:
      actions.chooseChildServiceProfileOptionThunk,
    chooseServiceProfileAction: actions.chooseServiceProfileAction,
    getRootServiceProfileOptionsThunk:
      actions.getRootServiceProfileOptionsThunk,
  }));

  let showLaborItems = false;
  currentTask.nonRoutineLaborItems &&
    (showLaborItems = currentTask.nonRoutineLaborItems);

  const {
    title,
    defect,
    isNone,
    isEWIS,
    isRII,
    isCriticalTask,
    isCDCCL,
    isAWLI,
    hours,
  } = nonRoutineTask;

  const specialNonRoutineTypes =
    user && user.specialNonRoutineTypes ? user.specialNonRoutineTypes : [];

  specialNonRoutineTypes &&
    specialNonRoutineTypes.forEach(
      (st) => !nonRoutineTask[st.abr] && (nonRoutineTask[st.abr] = "")
    );

  const onSubmit = async (e) => {
    e.preventDefault();

    const possibleRequiredValues = {
      ataChapterID,
      ataSubChapterID,
      zoneID,
      title,
      defect,
      hours,
      laborItemID,
      STSZone,
    };

    if (requiredNonRoutineItems && requiredNonRoutineItems.length) {
      let isValid = true;

      requiredNonRoutineItems.forEach((item) => {
        if (!possibleRequiredValues[item]) {
          isValid = false;
          setAlerts({
            error: `${item} is required!`,
            type: "danger",
          });
        }
      });

      if (!isValid) return;
    }

    let task = {
      Title: title,
      Defect: defect,
      ATAChapterID: ataChapterID,
      ATASubChapterID: ataSubChapterID,
      ZoneID: zoneID,
      None: isNone,
      EWIS: isEWIS,
      RII: isRII,
      CriticalTask: isCriticalTask,
      CDCCL: isCDCCL,
      AirworthinessLimitationsItems: isAWLI,
      Qty: hours,
      InventoryID: laborItemID,
      ProjectTaskID: currentTask.ProjectTaskID.value,
      WorkOrderID: currentTask.WorkOrderID.value,
      Customer: currentTask.Customer.value,
      JobCode: currentTask.JobCode.value,
      RaisedBy: user.AccountID,
      DateRaised: currentTask.Time.value,
      EquipModel: currentTask.EquipModel.value,
      EquipID: currentTask.EquipID.value,
      ServiceProfileCD: currentTask.ServiceProfileCD
        ? currentTask.ServiceProfileCD.value
        : "",
    };

    specialNonRoutineTypes &&
      specialNonRoutineTypes.forEach(
        (st) => (task[st.abr] = nonRoutineTask[st.abr])
      );

    if (user.stsZone) task.STSZone = STSZone;
    if (currentTask.UsrZoneID && currentTask.UsrZoneID.value)
      task.UsrZoneID = currentTask.UsrZoneID.value;

    setLoading(true);
    addNonRoutineTask(task, currentTask.ReleaseStatement);
  };

  const selectOption = (e) => {
    const type = `${e.target.name}s`;
    setNonRoutineOptionType(type);
    type === "chapters" && setAtaSubChapterID("") && setZoneID("");
    type === "subChapters" && setZoneID("");
    type === "stsZones" && setSTSZoneAction("");
    setShowNonRoutineOptions(true);
  };

  const onChange = (e) => {
    if (e.target.type === "checkbox") {
      if (e.target.name === "isNone" && e.target.checked) {
        clearNonRoutineTaskItemExceptNone();
      }
      setNonRoutineTaskItem({ key: e.target.name, value: e.target.checked });
    } else {
      setNonRoutineTaskItem({ key: e.target.name, value: e.target.value });
    }
  };

  useEffect(() => {
    if (currentTask.nonRoutineMatchMXTypes) {
      setNonRoutineMXTypes(currentTask);
    }

    // eslint-disable-next-line
  }, [currentTask]);

  const filteredServiceProfileOptions = serviceProfileList.filter(
    (svcProfile) =>
      svcProfile.SvcProfileDescription &&
      svcProfile.SvcProfileDescription.toLowerCase()
        .trim()
        .includes(serviceProfileSearchInput.toLowerCase().trim())
  );

  return (
    <Fragment>
      {showNonRoutineOptions ? (
        <NonRoutineOptions />
      ) : (
        <form onSubmit={onSubmit}>
          {!user.serviceProfilesNonRoutine ? (
            <Fragment>
              <div>
                <label htmlFor="title" className="w-100 text-left my-1">
                  Title
                </label>
                <input
                  name="title"
                  className="form-control  my-1"
                  placeholder="Title"
                  value={title}
                  onChange={onChange}
                  required={true}
                  autoComplete="off"
                />
              </div>
              <label htmlFor="detail" className="w-100 text-left my-1">
                Detail
              </label>
              <textarea
                className="form-control  my-1"
                name="defect"
                rows="3"
                placeholder="Defect"
                value={defect}
                onChange={onChange}
                autoComplete="off"
              ></textarea>
              <div className={`grid-${user.stsZone ? 4 : 3}`}>
                <div>
                  <label htmlFor="chapter" className="w-100 text-left my-1">
                    {Chapter}
                  </label>
                  <input
                    name="chapter"
                    className="form-control  my-1"
                    placeholder={Chapter}
                    value={ataChapterID}
                    onFocus={selectOption}
                    readOnly={true}
                  ></input>
                </div>

                <div>
                  <label htmlFor="subChapter" className="w-100 text-left my-1">
                    {Subchapter}
                  </label>
                  <input
                    name="subChapter"
                    className="form-control  my-1"
                    placeholder={Subchapter}
                    value={ataSubChapterID}
                    onFocus={selectOption}
                    disabled={!ataChapterID}
                    readOnly={true}
                  ></input>
                </div>

                <div>
                  <label htmlFor="zone" className="w-100 text-left my-1">
                    {Zone}
                  </label>
                  <input
                    name="zone"
                    className="form-control  my-1"
                    placeholder={Zone}
                    value={zoneID}
                    onFocus={selectOption}
                    disabled={!ataChapterID}
                    readOnly={true}
                  />
                </div>

                {user.stsZone && (
                  <div>
                    <label htmlFor="stsZone" className="w-100 text-left my-1">
                      STS Zone
                    </label>
                    <input
                      name="stsZone"
                      className="form-control  my-1"
                      placeholder="STS Zone"
                      value={STSZone}
                      onFocus={selectOption}
                      disabled={!ataChapterID}
                      readOnly={true}
                    />
                  </div>
                )}
              </div>
              <div className="grid-3">
                <div>
                  <label htmlFor="isNone" className="w-100 text-left">
                    <input
                      type="checkbox"
                      name="isNone"
                      checked={isNone}
                      onChange={onChange}
                      className="my-1"
                    />{" "}
                    {None}
                  </label>
                </div>
                <div>
                  <label htmlFor="isEWIS" className="w-100 text-left">
                    <input
                      type="checkbox"
                      name="isEWIS"
                      checked={isEWIS}
                      disabled={isNone}
                      onChange={onChange}
                      className="my-1"
                    />{" "}
                    {EWIS}
                  </label>
                </div>
                <div>
                  <label htmlFor="isRII" className="w-100 text-left">
                    <input
                      type="checkbox"
                      name="isRII"
                      checked={isRII}
                      disabled={isNone}
                      onChange={onChange}
                      className="my-1"
                    />{" "}
                    {RII}
                  </label>
                </div>
                <div>
                  <label htmlFor="isCriticalTask" className="w-100 text-left">
                    <input
                      type="checkbox"
                      name="isCriticalTask"
                      checked={isCriticalTask}
                      disabled={isNone}
                      onChange={onChange}
                      className="my-1"
                    />{" "}
                    {CriticalTask}
                  </label>
                </div>
                <div>
                  <label htmlFor="isCDCCL" className="w-100 text-left">
                    <input
                      type="checkbox"
                      name="isCDCCL"
                      checked={isCDCCL}
                      disabled={isNone}
                      onChange={onChange}
                      className="my-1"
                    />{" "}
                    {CDCCL}
                  </label>
                </div>
                <div>
                  <label htmlFor="isAWLI" className="w-100 text-left">
                    <input
                      type="checkbox"
                      name="isAWLI"
                      checked={isAWLI}
                      disabled={isNone}
                      onChange={onChange}
                      className="my-1"
                    />{" "}
                    {AWLI}
                  </label>
                </div>
                {specialNonRoutineTypes.map((t) => {
                  return (
                    <div key={t.abr}>
                      <label htmlFor={t.abr} className="w-100 text-left">
                        <input
                          type="checkbox"
                          name={t.abr}
                          checked={nonRoutineTask[t.abr]}
                          disabled={isNone}
                          onChange={onChange}
                          className="my-1"
                        />{" "}
                        {t.name}
                      </label>
                    </div>
                  );
                })}
                <div></div>
                <div hidden={!showLaborItems}>
                  <label htmlFor="hours" className="w-100 text-left my-1">
                    Hours
                  </label>
                  <input
                    type="text"
                    name="hours"
                    className="my-0"
                    placeholder="Hours"
                    value={hours}
                    onChange={onChange}
                  />
                </div>
                <div hidden={!showLaborItems}>
                  <label htmlFor="laborItem" className="w-100 text-left my-1">
                    Labor Item
                  </label>
                  <input
                    type="text"
                    name="laborItem"
                    className="my-0"
                    placeholder="Labor Item"
                    value={laborItemID}
                    onFocus={selectOption}
                    readOnly={true}
                    // onChange={onChange}
                  />
                </div>
              </div>
              <button className="btn btn-primary my-3 w-100" onClick={onSubmit}>
                {Button}
              </button>{" "}
            </Fragment>
          ) : (
            <Form>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Label>Type</Label>
                    <Input
                      type="select"
                      onChange={(evt) => {
                        const type = evt.target.value;
                        setServiceTypeAction(type);
                        type === "Service Packages" &&
                          getServicePackageListThunk(type);
                      }}
                      value={serviceType}
                    >
                      {(user.addTaskTypes || []).map((option) => (
                        <option>{option}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>

                <Col xs={12} md={6}>
                  <FormGroup>
                    <Label>Service Profile / Packages</Label>
                    <i
                      class="fas fa-sync-alt"
                      style={{ marginLeft: "8px" }}
                      onClick={() => {
                        getRootServiceProfileOptionsThunk();
                        setServiceTypeAction(serviceType);
                      }}
                    />
                    {serviceType === "Service Profiles" ? (
                      <Input
                        type="select"
                        value={JSON.stringify(chosenRootServiceProfileOption)}
                        onChange={(evt) =>
                          chooseRootServiceProfileOptionThunk(
                            JSON.parse(evt.target.value)
                          )
                        }
                      >
                        {[
                          {
                            Description: "",
                            RootID: "",
                            Sortrder: 0,
                            ServiceProfileCategoryID: "",
                          },
                          ...rootServiceProfileOptions,
                        ].map((service) => (
                          <option value={JSON.stringify(service)}>
                            {service.Description}
                          </option>
                        ))}
                      </Input>
                    ) : (
                      <Input
                        type="select"
                        // value={chosenService.ServicePackageCD}
                        onChange={(evt) =>
                          setChosenServiceValueAction(evt.target.value)
                        }
                      >
                        {[{}, ...serviceList].map((service) => (
                          <option value={JSON.stringify(service)}>
                            {service.ServicePackageCD}
                          </option>
                        ))}
                      </Input>
                    )}
                  </FormGroup>

                  {serviceType === "Service Profiles" && (
                    <>
                      {treeChildServiceProfileDropdowns.map(
                        (options, index) => (
                          <FormGroup>
                            <Input
                              type="select"
                              onChange={(evt) =>
                                chooseChildServiceProfileOptionThunk({
                                  index,
                                  chosenOption: JSON.parse(evt.target.value),
                                })
                              }
                              value={JSON.stringify(
                                chosenTreeChildServiceProfileOptions[index] || {
                                  CategoryId: "",
                                  Description: "",
                                  ParentId: "",
                                  svcCat_sortOrder: 0,
                                }
                              )}
                            >
                              {[
                                {
                                  CategoryId: "",
                                  Description: "",
                                  ParentId: "",
                                  svcCat_sortOrder: 0,
                                },
                                ...options,
                              ].map((service) => (
                                <option value={JSON.stringify(service)}>
                                  {service.Description}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        )
                      )}
                    </>
                  )}
                </Col>
              </Row>

              {filteredServiceProfileOptions.length > 0 && (
                <>
                  <Input
                    placeholder="Search service profile"
                    value={serviceProfileSearchInput}
                    onChange={(e) =>
                      setServiceProfileSearchInput(e.target.value)
                    }
                  />

                  <div className="service-profile-container">
                    <div>
                      {filteredServiceProfileOptions.map(
                        (svcProfile, index) => (
                          <div className="service-profile-option">
                            <input
                              onChange={() => chooseServiceProfileAction(index)}
                              type="checkbox"
                              value={svcProfile.chosen}
                              className="service-profile-checkbox"
                            />
                            {svcProfile.SvcProfileDescription}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </>
              )}

              <button
                disabled={serviceProfileList.every(
                  (svcProfile) => !svcProfile.chosen
                )}
                className="btn btn-primary my-3 w-100"
                onClick={() => addServiceTaskThunk()}
              >
                {Button}
              </button>
            </Form>
          )}
        </form>
      )}
    </Fragment>
  );
};

export default NonRoutineItem;
