import React from "react";
import Modal from "../container/Modal";
import ProjectSearch from "../searchProject/ProjectSeach";
import Projects from "../searchProject/Projects";

const SearchProjects = () => {
  return (
    <div className="inner-scroll">
      <ProjectSearch />
      <Projects />
      <Modal.SearchTasksServerSide />
    </div>
  );
};

export default SearchProjects;
