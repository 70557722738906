import wrap from "word-wrap";

export const showHeader = (doc, { Title, NonRoutineTaskCD }) => {
  // Wrap the title into multiple lines
  const title = wrap(Title.toUpperCase(), { width: 40 });
  const wordsArray = title.split("\n");
  let yIndex = 14;

  // Draw the title
  doc.font("Helvetica-Bold").fontSize(14);
  wordsArray.every((n) => {
    yIndex = yIndex + 0.1;
    return doc.text(n, 15, doc.y + yIndex - 1);
  });

  // Draw the NonRoutineTaskCD
  doc
    .font("Helvetica-Bold")
    .fontSize(16)
    .text(
      NonRoutineTaskCD,
      doc.page.width - doc.widthOfString(NonRoutineTaskCD) - 50,
      12
    );
  doc
    .fillColor("#D5D5D5")
    .font("Helvetica")
    .fontSize(7)
    .text(
      "Form Number:    ENG150NRC-ProMRO",
      doc.page.width - doc.widthOfString(NonRoutineTaskCD) - 120,
      40
    )
    .text(
      "ISSUE 01",
      doc.page.width - doc.widthOfString(NonRoutineTaskCD) - 27,
      50
    )
    .text(
      "Date:    Mar 2021",
      doc.page.width - doc.widthOfString(NonRoutineTaskCD) - 50,
      60
    )
    .fillColor("black");
};
