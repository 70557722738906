import React, { useRef, Fragment } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";

const ProjectSearch = () => {
  const { projectSearchValue, usingCentrik, mechanicQualified } = useStoreState(
    (state) => ({
      projectSearchValue: state.projectSearchValue,
      usingCentrik: state.usingCentrik,
      mechanicQualified: state.qualifications.mechanicQualified,
    })
  );

  const {
    clearProjectSearchValue,
    setProjectSearchValue,
    searchProjects,
    setLoading,
    cleanupSearch,
  } = useStoreActions((state) => ({
    clearProjectSearchValue: state.clearProjectSearchValue,
    setProjectSearchValue: state.setProjectSearchValue,
    searchProjects: state.searchProjects,
    setLoading: state.setLoading,
    cleanupSearch: state.cleanupSearch,
  }));

  const text = useRef("");

  const onChange = (e) => {
    if (text.current.value !== "") {
      setProjectSearchValue(e.target.value);
    } else {
      clearProjectSearchValue();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    cleanupSearch();
    setLoading(true);
    searchProjects(projectSearchValue);
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="input-group mb-3">
        {(usingCentrik && mechanicQualified) || !usingCentrik ? (
          <Fragment>
            <input
              onChange={onChange}
              type="text"
              className="form-control"
              placeholder="Search by Tail Number"
              aria-label="Search by Tail Number"
              aria-describedby="basic-addon2"
              value={projectSearchValue}
              ref={text}
            />
            <div
              className="input-group-append"
              style={{ marginTop: "1.2rem" }}
              onClick={onSubmit}
            >
              <button className="btn btn-success" type="button">
                <i className="fa fa-search text-white mr-2"></i>
                Search
              </button>
            </div>
          </Fragment>
        ) : (
          <div></div>
        )}
      </div>
    </form>
  );
};

export default ProjectSearch;
