import blobStream from 'blob-stream';
import PDFDocument from '@react-pdf/pdfkit';
import { showHeader } from './header';
import { showOverview } from './overview';
import { showInfoWithCustomer } from './customer';
import { showEquipInfo } from './equip';
import { showTaskType } from './taskType';
import { showDefect } from './defect';
import { showRepair } from './repair';
import { showRectification } from './rectification';
import { showMechanic } from './mechanic';
import { showInspector } from './inspector';
import { showCalibratedTools } from './tools';
import { showContinuationSheet } from './continuationSheet';
import { showRotables } from './rotables';

const createNonRoutineTaskCard = (
    blob,
    barcodeBlob,
    nonRoutineTask,
) => {
    try {
        const doc = new PDFDocument();
        const stream = doc.pipe(blobStream());

        showHeader(doc, nonRoutineTask);

        let [startX, startY] = [50, 75];
        showOverview(doc, 
          { 
            blob,
            barcodeBlob,
            ...nonRoutineTask,
          },
          startX,
          startY
        );

        [startX, startY] = [50, 145];
        showInfoWithCustomer(doc, nonRoutineTask, startX, startY);

        [startX, startY] = [50, 185];
        showEquipInfo(doc, nonRoutineTask, startX, startY);

        [startX, startY] = [445, 185];
        showTaskType(doc, nonRoutineTask, startX, startY);

        [startX, startY] = [50, 215];
        showDefect(doc, nonRoutineTask, startX, startY);

        [startX, startY] = [50, 300];
        showRepair(doc, startX, startY);

        [startX, startY] = [445, 300];
        showMechanic(doc, startX, startY);

        [startX, startY] = [445 + ((doc.page.width - 50) - 445) / 2, 300];
        showInspector(doc, startX, startY, nonRoutineTask);

        [startX, startY] = [50, 350];
        showRectification(doc, startX, startY);

        [startX, startY] = [50, 543];
        showCalibratedTools(doc, startX, startY);

        [startX, startY] = [150, 625];
        showContinuationSheet(doc, startX, startY);

        [startX, startY] = [50, 650];
        showRotables(doc, startX, startY);

        doc.end();
    
        stream.on('finish', function () {
          // get a blob you can do whatever you like with
          // const blob = stream.toBlob('application/pdf');
          // or get a blob URL for display in the browser
          const url = stream.toBlobURL('application/pdf');
          window.open(url, '_blank');
        });
      } catch (err) {
        console.log(err);
      }
}

export default createNonRoutineTaskCard;