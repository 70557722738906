import React, { Fragment } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";

const TaskItem = () => {
  const { correctiveAction, currentTask, Squawk, CorrectiveActionLabel } =
    useStoreState((state) => ({
      correctiveAction: state.correctiveAction,
      currentTask: state.currentTask,
      Squawk: state.user.customLabels.task.Squawk,
      CorrectiveActionLabel: state.user.customLabels.task.CorrectiveAction,
    }));

  const { setCorrectiveAction } = useStoreActions((actions) => ({
    setCorrectiveAction: actions.setCorrectiveAction,
  }));

  const onChange = (e) => {
    const text = e.target.value;
    setCorrectiveAction(text);
  };

  const displayRejectedReason = (task) => {
    if (task && task.RejectedReason && task.RejectedReason.value) {
      return (
        <Fragment>
          <h4 className="text-left text-warning">Inspection Rejected:</h4>
          <p className="m-2 text-left">{task.RejectedReason.value}</p>
        </Fragment>
      );
    }
    return null;
  };

  return (
    <Fragment>
      <h4 className="text-left text-primary">{Squawk}</h4>
      <p className="m-2 text-left">{currentTask.Instructions.value}</p>
      {displayRejectedReason(currentTask)}
      <h4 className="text-left text-primary">{CorrectiveActionLabel}:</h4>
      <div className="m-2 text-left text-dark">
        {currentTask.CorrectiveAction.value
          ? `Existing ${CorrectiveActionLabel}: ${currentTask.CorrectiveAction.value}`
          : `No Exising ${CorrectiveActionLabel}`}
      </div>
      <form>
        <div className="form-group">
          <textarea
            className="form-control"
            name="correctiveAction"
            id="correctiveAction"
            rows="3"
            placeholder={`${CorrectiveActionLabel} here`}
            onChange={onChange}
            value={correctiveAction}
          ></textarea>
        </div>
      </form>
    </Fragment>
  );
};

export default TaskItem;
