import React, { Fragment, useEffect } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import UserWarning from "../userWarningModal/UserWarning";
import ProjectTasks from "../projectTasks/ProjectTasks";
import Spinner from "../layout/Spinner";
import { Button } from "reactstrap";

const Home = () => {
  const { loading, user, showUserWarning, favicon, logo, isTest } =
    useStoreState((state) => ({
      loading: state.loading,
      user: state.user,
      showUserWarning: state.showUserWarning,
      tenant: state.tenant,
      favicon: state.favicon,
      logo: state.logo,
      isTest: state.isTest,
    }));

  const { getEmployee, setLoading, clearLocalStorageAndRefressh } =
    useStoreActions((actions) => ({
      setLocalStorageToken: actions.setLocalStorageToken,
      getEmployee: actions.getEmployee,
      setLoading: actions.setLoading,
      clearLocalStorageAndRefressh: actions.clearLocalStorageAndRefressh,
      setLogo: actions.setLogo,
    }));

  useEffect(() => {
    const faviconId = document.getElementById("favicon");
    let faviconURL =
      "https://kioskbranding.blob.core.windows.net/images/favicon.ico";
    favicon &&
      (faviconURL = `https://kioskbranding.blob.core.windows.net/images/${favicon}`);
    faviconId.href = faviconURL;
  }, [favicon]);

  const onSubmit = (e) => {
    e.preventDefault();
    const scannedValue = document.querySelector(
      'input[name="scannedValue"]'
    ).value;
    setLoading(true);
    getEmployee(scannedValue);
  };

  return (
    <Fragment>
      {!loading ? (
        <Fragment>
          {!user ? (
            <Fragment>
              <div className="jumbotron text-center" id="jumbo">
                <img
                  src={`https://kioskbranding.blob.core.windows.net/images/${logo}`}
                  alt=""
                  style={{ width: "350px" }}
                />
              </div>
              <h1 className="text-center">ProMRO Sign In</h1>
              <div
                className="text-center text-gray"
                style={{ fontSize: "6em" }}
              >
                <i className="fa fa-credit-card" />
                <h3>Scan badge or enter ID to Sign-in</h3>
              </div>
              <form onSubmit={onSubmit}>
                <div className="grid-3">
                  <div className="" />
                  <input
                    className="text-center"
                    type="password"
                    name="scannedValue"
                    autoFocus
                    autoComplete="off"
                  />
                  <div className="" />
                </div>
              </form>
              <div className="grid-3">
                <div></div>
                {isTest && (
                  <Button
                    className="w-100 mt-4"
                    color="warning"
                    type="button"
                    onClick={clearLocalStorageAndRefressh}
                  >
                    Select another environment
                  </Button>
                )}
                <div></div>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              {showUserWarning ? (
                <UserWarning
                  title="Kronos Alert"
                  message="Please clock into Kronos"
                  type="warning"
                />
              ) : (
                ""
              )}

              <ProjectTasks />
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
};

export default Home;
