import React, { Fragment } from "react";
import ProjectTasksFilter from "./ProjectTasksFilter";
import ProjectTaskItem from "./ProjectTaskItem";
import ForcedLogout from "../layout/ForcedLogout";
import Spinner from "../layout/Spinner";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useStoreActions, useStoreState } from "easy-peasy";
import { v4 } from "uuid";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import ClockIn from "../clockin/ClockIn";
import BiometricAlternativeModal from "../userWarningModal/BiometricAlternativeModal";

const ProjectTasks = () => {
  const {
    projectTasks,
    filteredProjectTasks,
    loading,
    showForcedLogoutModal,
    None,
    mechanicQualified,
    KronosClockIn,
    KioskRole,
    showClockInPopup,
    facialRecogition,
    SecondaryFacialRecogRequired,
    BiometricAlternative,
    SkipFacialRecogition,
  } = useStoreState((state) => ({
    projectTasks: state.projectTasks,
    filteredProjectTasks: state.filteredProjectTasks,
    loading: state.loading,
    showForcedLogoutModal: state.showForcedLogoutModal,
    None: state.user.customLabels.taskList.None,
    mechanicQualified: state.qualifications.mechanicQualified,
    KronosClockIn: state.user.KronosClockIn,
    KioskRole: state.user.KioskRole,
    showClockInPopup: state.showClockInPopup,
    facialRecogition: state.user.facialRecogition,
    SecondaryFacialRecogRequired: state.user.SecondaryFacialRecogRequired,
    BiometricAlternative: state.user.BiometricAlternative,
    SkipFacialRecogition: state.user.SkipFacialRecogition,
  }));

  const {
    setClockInPopupAction,
    setIsClockingInAction,
    showBiometricAlternativeModalAction,
  } = useStoreActions((actions) => actions);

  return (
    <Fragment>
      {showForcedLogoutModal ? (
        <ForcedLogout />
      ) : (
        <Fragment>
          <ProjectTasksFilter />
          <div className="inner-scroll">
            {projectTasks !== null &&
            Array.isArray(projectTasks) &&
            !loading ? (
              <TransitionGroup
                className={
                  (facialRecogition &&
                    KronosClockIn &&
                    SecondaryFacialRecogRequired) ||
                  !facialRecogition ||
                  !SecondaryFacialRecogRequired ||
                  SkipFacialRecogition
                    ? ""
                    : "d-none"
                }
              >
                {projectTasks.length > 0 && mechanicQualified ? (
                  filteredProjectTasks !== null ? (
                    filteredProjectTasks.map((t) => (
                      <CSSTransition
                        exit={false}
                        key={t.id}
                        timeout={500}
                        classNames="item"
                      >
                        <ProjectTaskItem task={t} />
                      </CSSTransition>
                    ))
                  ) : (
                    projectTasks.map((t) => (
                      <CSSTransition
                        exit={false}
                        key={t.id}
                        timeout={500}
                        classNames="item"
                      >
                        <ProjectTaskItem task={t} />
                      </CSSTransition>
                    ))
                  )
                ) : (
                  <CSSTransition
                    enter={false}
                    exit={false}
                    key={v4()}
                    timeout={500}
                    classNames="item"
                  >
                    <div className="card">
                      <h2>{None}</h2>
                    </div>
                  </CSSTransition>
                )}{" "}
              </TransitionGroup>
            ) : (
              <Spinner />
            )}

            {((facialRecogition &&
              !KronosClockIn &&
              SecondaryFacialRecogRequired &&
              KioskRole !== "Not Applicable") ||
              (facialRecogition && !KronosClockIn && BiometricAlternative)) &&
              !SkipFacialRecogition && (
                <Button
                  block
                  color="success"
                  onClick={() => {
                    if (BiometricAlternative) {
                      showBiometricAlternativeModalAction(true);
                    } else {
                      setClockInPopupAction(true);
                    }
                    setIsClockingInAction(true);
                  }}
                >
                  <i className="far fa-clock"></i>
                  <div>KRONOS - IN (Please ensure camera is enabled)</div>
                </Button>
              )}
          </div>
        </Fragment>
      )}

      <Modal
        isOpen={showClockInPopup}
        toggle={() => setClockInPopupAction(false)}
        size="md"
        centered
      >
        <ModalHeader toggle={() => setClockInPopupAction(false)} />
        <ModalBody className="p-0">
          <ClockIn />
        </ModalBody>
      </Modal>

      <BiometricAlternativeModal />
    </Fragment>
  );
};

export default ProjectTasks;
