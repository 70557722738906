import { action } from "easy-peasy";
import { v4 } from "uuid";
//import { qualSplit } from "../../utils/qualificationSplitter";

const employeeActions = {
  setLogo: action((state, logo) => {
    state.logo = logo;
    localStorage.setItem("logo", logo);
  }),
  setFavicon: action((state, favicon) => {
    state.favicon = favicon;
    localStorage.setItem("favicon", favicon);
  }),
  setLocalStorageToken: action((state, token) => {
    localStorage.setItem("token", token);
  }),
  clearLocalStorageAndRefressh: action((state) => {
    state.logo = "";
    state.favicon = "";
    localStorage.clear();
    window.location.reload();
  }),
  setUser: action((state, user) => {
    state.user = user;
  }),
  setShowUserWarning: action((state, show) => {
    state.showUserWarning = show;
  }),
  setPage: action((state, page) => {
    state.page = page;
  }),
  setUserValidites: action((state, validities) => {
    state.user.validities = validities;
  }),
  setUsingCentrick: action((state, usingCentrik) => {
    state.usingCentrik = usingCentrik;
  }),
  setQualificationsPersonal: action((state, qualifications) => {
    state.qualifications.personal = qualifications;
  }),
  setQualificationsType: action((state, qualifications) => {
    state.qualifications.type = qualifications;
  }),
  setQualificationsCustomer: action((state, qualifications) => {
    state.qualifications.customer = qualifications;
  }),
  setQualificationsMechanic: action((state, mechanicQualified) => {
    state.qualifications.mechanicQualified = mechanicQualified;
  }),
  setQualificationsInspector: action((state, inspectorQualified) => {
    state.qualifications.inspectorQualified = inspectorQualified;
  }),
  setQualificationsBoroscope: action((state, boroscopeQualified) => {
    state.qualifications.boroscopeQualified = boroscopeQualified;
  }),
  setQualificationsFailed: action((state, failedQualification) => {
    state.qualifications.failedQualification = failedQualification;
  }),

  setLoading: action((state, loading) => {
    state.loading = loading;
  }),
  setAlert: action((state, alert) => {
    // state.alerts = [...state.alerts, alert];
    let alerts = state.alerts;

    alerts.length && (alerts = alerts.filter((a) => a.error !== alert.error));

    state.alerts = [...alerts, alert];
  }),
  setAlertsAndLock: action((state, alerts) => {
    state.alerts = [
      ...state.alerts,
      {
        id: v4(),
        error: alerts,
        type: "danger",
      },
    ];
    state.showCentrikButton = true;
  }),
  removeAlert: action((state, alert) => {
    state.alerts = state.alerts.filter((a) => a.id !== alert.id);
    state.showCentrikButton = false;
  }),
  updateLogin: action((state, loginInput) => {
    state.loginInput = {
      ...state.loginInput,
      ...loginInput,
    };
  }),
  setTenant: action((state, tenant) => {
    state.tenant = tenant;
    localStorage.setItem("tenant", tenant);
  }),
  setSpinner: action((state, spinner) => {
    state.spinner = spinner;
    localStorage.setItem("spinner", spinner);
  }),
};

export default employeeActions;
