import React from "react";
import { Row, Form, Col, Button, Dropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useStoreActions, useStoreState } from "easy-peasy";
//import createUnserviceableCard from "../../reports/unserviceable";
import moment from "moment";

// use 235953 for badge id

function formatTextPlaceholder(label) {
  return `Enter ${label.toLowerCase()}`;
}

const useFields = () => {
  const { unservicable, customLabels } = useStoreState((state) => ({
    ...state,
    customLabels: state.user.customLabels.unserviceablePart,
  }));
  const { updateUnservicable } = useStoreActions((actions) => actions);

  return [
    {
      label: customLabels.PartNbr,
      input: {
        readOnly: true,
        name: "partNbr",
        id: "partNbr",
        value: unservicable.PartNbr.InventoryCD,
      },
    },
    {
      label: customLabels.SerialNbr,
      input: {
        name: "serialNbr",
        id: "serialNbr",
        placeholder: formatTextPlaceholder(customLabels.SerialNbr),
        onChange: (evt) => updateUnservicable({ SerialNbr: evt.target.value }),
        value: unservicable.SerialNbr,
      },
    },
    {
      label: customLabels.ModStateShelfLife,
      input: {
        name: "stateShelfLife",
        id: "stateShelfLife",
        onChange: (evt) =>
          updateUnservicable({ StateShelfLife: evt.target.value }),
        value: unservicable.StateShelfLife,
      },
    },
    {
      label: customLabels.Position,
      input: {
        name: "position",
        id: "position",
        placeholder: formatTextPlaceholder(customLabels.Position),
        onChange: (evt) => updateUnservicable({ Position: evt.target.value }),
        value: unservicable.Position,
      },
    },
    {
      label: "Quantity",
      input: {
        name: "quantity",
        id: "quantity",
        onChange: (evt) =>
          updateUnservicable({ Quantity: Number(evt.target.value) }),
        value: unservicable.Quantity,
        type: "number",
      },
    },
    {
      label: customLabels.Date,
      input: {
        name: "date",
        id: "date",
        placeholder: "MM/DD/YYYY",
        onChange: (evt) => updateUnservicable({ Date: evt.target.value }),
        value: unservicable.Date,
        type: "date",
        onKeyDown: (event) => event.preventDefault(),
      },
    },
    {
      label: customLabels.Station,
      input: {
        name: "station",
        id: "station",
        placeholder: formatTextPlaceholder(customLabels.Station),
        onChange: (evt) => updateUnservicable({ Station: evt.target.value }),
        value: unservicable.Station,
      },
    },
    {
      label: customLabels.Owner,
      input: {
        name: "owner",
        id: "owner",
        placeholder: formatTextPlaceholder(customLabels.Owner),
        onChange: (evt) => updateUnservicable({ Owner: evt.target.value }),
        value: unservicable.Owner,
      },
    },
    {
      label: customLabels.TechLogRef,
      input: {
        name: "techLogRef",
        id: "techLogRef",
        placeholder: formatTextPlaceholder(customLabels.TechLogRef),
        onChange: (evt) => updateUnservicable({ TechLogRef: evt.target.value }),
        value: unservicable.TechLogRef,
      },
    },
    {
      label: customLabels.Operator,
      input: {
        name: "operator",
        id: "operator",
        placeholder: formatTextPlaceholder(customLabels.Operator),
        onChange: (evt) => updateUnservicable({ Operator: evt.target.value }),
        value: unservicable.Operator,
      },
    },
    {
      label: customLabels.Description,
      input: {
        name: "description",
        id: "description",
        placeholder: customLabels.DescriptionPlaceholder,
        onChange: (evt) =>
          updateUnservicable({ Description: evt.target.value }),
        value: unservicable.Description,
      },
      col: { md: 12 },
    },
    {
      label: customLabels.RemovalReason,
      input: {
        name: "removalReason",
        id: "removalReason",
        placeholder: formatTextPlaceholder(customLabels.RemovalReason),
        onChange: (evt) =>
          updateUnservicable({ ReasonforRemoval: evt.target.value }),
        value: unservicable.ReasonforRemoval,
        as: "textarea",
      },
      col: { md: 12 },
    },
  ];
};

const useButtons = () => {
  const {
    removeUnservicablePart,
    setLoading,
    setAlerts,
    updateUnservicable,
    setShowUnservicablePopup,
    setShowStockItemSearchResults,
    getReportImage,
    createUnserviceablePartCard,
  } = useStoreActions((actions) => actions);

  const { user, unservicable, currentTask } = useStoreState((state) => state);

  const hiddenFields =
    user && user.hiddenFields && user.hiddenFields.unserviceablePart
      ? user.hiddenFields.unserviceablePart
      : null;

  const onSubmit = async () => {
    setLoading(true);
    let companyLogoBlob = null;
    let reportObject = {};

    if (currentTask.printNonRoutineTaskCard) {
      companyLogoBlob = await getReportImage();
      reportObject = {
        ...unservicable,
        ...currentTask,
        PartNbr: unservicable.PartNbr.InventoryCD,
        Inspector: user.Inspector === "YES" ? user.LegalName : "",
        EquipID: currentTask.EquipID.value,
        Position: unservicable.Position,
        Date: moment(unservicable.Date).format("L"),
      };
    }

    try {
      if (
        currentTask.unserviceablePartsLocationOverride &&
        (!currentTask.UnserviceableWarehouse.value ||
          currentTask.UnserviceableWarehouse.value === "NOWAREHOUSE" ||
          !currentTask.UnserviceableLocation.value ||
          currentTask.UnserviceableLocation.value === "NOLOCATION")
      ) {
        setAlerts({
          error: `No Unserviceable Part Warehouse or Location found.`,
          type: "danger",
        });
      } else {
        const createdUnservicablePart = await removeUnservicablePart({
          ...unservicable,
          PartNbr: unservicable.PartNbr.InventoryID,
          ReasonforRemoval: `${unservicable.ReasonforRemoval} - Position: ${unservicable.Position}`,
          Inspector: user.AccountID,
          Warehouse: `${
            currentTask.unserviceablePartsLocationOverride
              ? currentTask.UnserviceableWarehouse.value
              : currentTask.WarehouseID.value
          }`,
          Location: `${
            currentTask.unserviceablePartsLocationOverride
              ? currentTask.UnserviceableLocation.value
              : currentTask.LocationID.value
          }`,
          ProjectID: currentTask.WorkOrderID.value,
          Task: currentTask.ProjectTaskID.value,
          QuoteNbr: currentTask.ProjectTaskID.value,
        });

        if (createdUnservicablePart.data) {
          console.log("SUCCESS", createdUnservicablePart.data);
          setLoading(false);

          if (createdUnservicablePart.data.QuoteNbr.value) {
            setAlerts({
              error: `Unserviceable Part Request: ${createdUnservicablePart.data.QuoteNbr.value} created successfully`,
              type: "success",
            });

            reportObject.AcumaticaUSPNumber =
              createdUnservicablePart.data.QuoteNbr.value;

            updateUnservicable({
              SerialNbr: "",
              StateShelfLife: "",
              Description: "",
              Quantity: 0,
              Date: "",
              Station: "",
              Owner: "",
              TechLogRef: "",
              Operator: "",
              ReasonforRemoval: "",
              Position: "",
            });

            if (currentTask.printNonRoutineTaskCard) {
              await createUnserviceablePartCard({
                companyLogoBlob,
                reportObject,
              });
            }
          }
          // setShowUnservicablePopup(false);
          // setShowStockItemSearchResults(false);
        }
      }
      updateUnservicable({
        SerialNbr: "",
        StateShelfLife: "",
        Description: "",
        Quantity: 0,
        Date: "",
        Station: "",
        Owner: "",
        TechLogRef: "",
        Operator: "",
        ReasonforRemoval: "",
      });
      setShowUnservicablePopup(false);
      setShowStockItemSearchResults(false);
    } catch (error) {
      console.log("ERROR", error.response);
      setLoading(false);
      setAlerts({
        error: `Submission failed.`,
        type: "danger",
      });
    }
  };

  return [
    {
      variant: "light",
      text: "Cancel",
      onClick: () => setShowUnservicablePopup(false),
      id: "cancel-btn",
    },
    {
      variant: "success",
      text: "Submit",
      onClick: () => onSubmit(),
      id: "submit-btn",
      disabled:
        unservicable.Quantity <= 0 ||
        unservicable.SerialNbr.trim() === "" ||
        unservicable.StateShelfLife.trim() === "" ||
        unservicable.Description.trim() === "" ||
        unservicable.Date.trim() === "" ||
        unservicable.Station.trim() === "" ||
        ((!hiddenFields || (hiddenFields && !hiddenFields.includes("Owner"))) &&
          unservicable.Owner.trim() === "") ||
        unservicable.TechLogRef.trim() === "" ||
        ((!hiddenFields ||
          (hiddenFields && !hiddenFields.includes("Operator"))) &&
          unservicable.Operator.trim() === "") ||
        unservicable.ReasonforRemoval.trim() === "",
    },
  ];
};

const UnservicablePart = () => {
  const { user, customLabels, nonRoutineOptions, unservicable } = useStoreState(
    (state) => ({
      ...state,
      customLabels: state.user.customLabels.unserviceablePart,
    })
  );
  const { updateUnservicable } = useStoreActions((actions) => actions);

  const hiddenFields =
    user && user.hiddenFields && user.hiddenFields.unserviceablePart
      ? user.hiddenFields.unserviceablePart
      : null;

  const itemConditions =
    nonRoutineOptions && nonRoutineOptions.itemConditions
      ? nonRoutineOptions.itemConditions
      : null;

  const fields = useFields();
  const buttons = useButtons();

  return (
    <Form style={{ padding: "30px 0" }}>
      <h1 className="text-center">{customLabels.Title}</h1>
      <br />
      <Row>
        {fields.map(({ label, input, col = { md: 6 }, ...props }) => {
          if (
            !hiddenFields ||
            (hiddenFields && !hiddenFields.includes(label))
          ) {
            if (label === customLabels.ModStateShelfLife && itemConditions) {
              return (
                <Col
                  className="mb-3"
                  sm={12}
                  key={input.id}
                  {...col}
                  {...props}
                >
                  <Form.Group className="text-left">
                    <Form.Label>{label}</Form.Label>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="warning"
                        id="dropdown-basic"
                        className="btn-block"
                      >
                        {unservicable.StateShelfLife
                          ? unservicable.StateShelfLife
                          : customLabels.ModStateShelfLife}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {itemConditions.map((c, i) => {
                          return (
                            <Dropdown.Item
                              value={c.ConditionID}
                              key={c.ConditionID}
                              onClick={() =>
                                updateUnservicable({
                                  StateShelfLife: c.ConditionID,
                                })
                              }
                            >
                              {c.ConditionDescription}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                </Col>
              );
            } else {
              return (
                <Col
                  className="mb-3"
                  sm={12}
                  key={input.id}
                  {...col}
                  {...props}
                >
                  <Form.Group className="text-left">
                    <Form.Label>{label}</Form.Label>
                    <Form.Control required {...input} />
                  </Form.Group>
                </Col>
              );
            }
          } else {
            return "";
          }
        })}
      </Row>

      <div style={{ margin: "20px 0" }} />

      <Row>
        {buttons.map(({ text, id, ...rest }) => (
          <Col sm={12} md={6} key={id} style={{ padding: "0 15px" }}>
            <Button {...rest} block>
              {text}
            </Button>
          </Col>
        ))}
      </Row>
    </Form>
  );
};

export default UnservicablePart;
