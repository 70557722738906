import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useStoreState, useStoreActions } from 'easy-peasy';

const ForcedLogout = () => {
  const { showForcedLogoutModal, closeTasksOnForcedLogout } = useStoreState(
    (state) => ({
      showForcedLogoutModal: state.showForcedLogoutModal,
      closeTasksOnForcedLogout: state.user.closeTasksOnForcedLogout,
    })
  );

  const {
    setForcedLogoutModal,
    setLoading,
    setCorrectiveAction,
    removeTasksFromProjectTasks,
    signOut,
  } = useStoreActions((actions) => ({
    setForcedLogoutModal: actions.setForcedLogoutModal,
    setLoading: actions.setLoading,
    setCorrectiveAction: actions.setCorrectiveAction,
    removeTasksFromProjectTasks: actions.removeTasksFromProjectTasks,
    signOut: actions.signOut,
  }));

  const toggle = () => {
    setForcedLogoutModal(false);
    setLoading(true);
    const project = {};

    if (closeTasksOnForcedLogout) {
      project.remove = true;
      project.process = 'incompleteAll';
      project.signout = true;

      setCorrectiveAction('');

      removeTasksFromProjectTasks(project);
    } else {
      signOut();
    }
  };

  // const submit = () => {
  //   putInspectionRejection();
  // };

  return (
    <Modal
      isOpen={showForcedLogoutModal}
      centered={true}
      size={'md'}
      backdrop={true}
    >
      <ModalHeader className="h4">Forced Logout</ModalHeader>
      <ModalBody className="text-center">
        <div className="m-4 text-danger h4">
          {closeTasksOnForcedLogout
            ? 'All task cards will be clocked out and you will be logged out.'
            : 'You will be logged out.'}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="grid-3 w-100">
          <div></div>
          <Button
            color="light"
            style={{ display: 'block', width: '100%' }}
            onClick={toggle}
          >
            OK
          </Button>
          <div></div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ForcedLogout;
