import React, { useRef } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Modal, ModalBody, Input, Form, Button } from "reactstrap";

function SearchTasksServerSideModal(props) {
  const { chosenProjectId, taskServerSideSearchValue } = useStoreState(
    (state) => state
  );

  const {
    setChosenProjectIdAction,
    setTaskServerSideSearchValueAction,
    getTaskForFilteredProject,
    setLoading,
  } = useStoreActions((actions) => actions);

  const inputRef = useRef(null);

  return (
    <Modal
      centered={true}
      isOpen={chosenProjectId}
      toggle={() => setChosenProjectIdAction(null)}
      onOpened={() => inputRef.current.focus()}
      {...props}
    >
      <ModalBody>
        <Form
          onSubmit={(evt) => {
            evt.preventDefault();
            setLoading(true);
            getTaskForFilteredProject(chosenProjectId);
          }}
        >
          <h3>Search Project Tasks</h3>
          <Input
            value={taskServerSideSearchValue}
            onChange={(evt) =>
              setTaskServerSideSearchValueAction(evt.target.value)
            }
            placeholder="Enter search phrase"
            innerRef={inputRef}
          />

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button type="submit">Search</Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default SearchTasksServerSideModal;
