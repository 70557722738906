function customLabels(json) {
  function customProps(type) {
    return json ? json[type] : {};
  }

  const labels = {
    taskList: {
      Filter: "Filter Project Tasks",
      None: "No Project Tasks found",
      Search: "Search Tasks",
      Scan: "scan card",
      Title: "My Task List",
      ...customProps("taskList"),
    },
    task: {
      Title: "Task",
      Squawk: "Squawk",
      CorrectiveAction: "Corrective Action",
      ...customProps("task"),
    },
    nonRoutine: {
      Chapter: "Chapter",
      Subchapter: "Sub-Chapter",
      Zone: "Zone",
      None: "None",
      EWIS: "EWIS",
      RII: "RII",
      CriticalTask: "Critical Task",
      CDCCL: "CDCCL",
      AWLI: "Airworthiness Limitation",
      Title: "Non-Routine",
      Button: "Save Non-Routine Task",
      ...customProps("nonRoutine"),
    },
    unserviceablePart: {
      Station: "Station",
      Title: "Unserviceable Part",
      PartNbr: "Part Nbr",
      SerialNbr: "Serial Nbr",
      ModStateShelfLife: "Mod State/Shelf Life",
      Position: "Position",
      Quantity: "Quantity",
      Date: "Date",
      TechLogRef: "Tech Log Ref",
      Operator: "Operator",
      Description: "Description",
      DescriptionPlaceholder: "Enter description",
      RemovalReason: "Reason for Removal",
      RemovalReasonPlaceholder: "Enter reason for removal",
      Owner: "Owner",
      ...customProps("unserviceablePart"),
    },
    addUnserviceablePart: {
      Title: "Add Unserviceable Stock Item",
      ...customProps("addUnserviceablePart"),
    },
    partRequest: {
      SearchTitle: "Part",
      SearchPlaceholder: "part number",
      None: "No Project Part Request found",
      ...customProps("partRequest"),
    },
    partQuantity: {
      Title: "Part Quantity",
      ...customProps("partQuantity"),
    },
  };

  return labels;
}

export { customLabels };
