exports.qualSplit = (quals) => {
  let splitUpQuals = null;
  const splitQual = (q) => {
    const qualObj = {};

    const beginParan = q.lastIndexOf('(');
    const endingParan = q.lastIndexOf(')');
    //console.log('beginning: ', beginParan, ' ending: ', endingParan);
    qualObj.engine = q.substring(beginParan, endingParan + 1);
    //removed for the new approach
    // .replace('(', '')
    // .replace(')', '');

    q = q.replace(qualObj.engine, '').trim();

    const qualSplit = q.split(' ');
    qualObj.make = qualSplit[0];
    //change made to simplify
    qualObj.model = q
      .replace(qualObj.make, '')
      .replace(qualObj.engine, '')
      .trim();

    //complex breakout of variant
    //qualObj.model = qualSplit[1].split('/');
    // if (qualObj.length > 2) {
    //   console.log('here');
    //   qualObj.variant = q.replace(
    //     `${qualSplit[0]}{' '}${qualSplit[1]}{' '}`,
    //     ''
    //   );
    // }

    return qualObj;
  };

  /* #region  test data */
  /*
  const quals = [
    {
      validityId: '37b2817d-02c0-41fe-a8dd-27f5cd403034',
      validatedOn: '2019-12-03T00:00:00.0000000',
      expiresOn: '2021-12-03T00:00:00.0000000',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '03/12/2019',
      expiresOnText: '03/12/2021',
      exempt: false,
      qualificationId: 'b61afae3-6a92-4aa7-a900-862bd44b2426',
      qualificationType: 'General Qualification',
      qualificationNumber: '1',
      qualificationName: 'EWIS',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: true,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/f962ca55-eb80-41b9-a88f-9f880fd93f11/EWIS_Cert_Exp_3.12-21.pdf',
        },
      ],
    },
    {
      validityId: 'd8f7c65a-bc69-4ac8-95b0-d780d8b4a9f9',
      validatedOn: '2019-12-03T00:00:00.0000000',
      expiresOn: '2021-12-03T00:00:00.0000000',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '03/12/2019',
      expiresOnText: '03/12/2021',
      exempt: false,
      qualificationId: '2bd4b5e9-704d-4cbb-994d-7a31aef13778',
      qualificationType: 'General Qualification',
      qualificationNumber: '2',
      qualificationName: 'Fuel Tank Safety',
      qualificationExternalId: null,
      qualificationNameShort: 'SFAR-88',
      qualificationMandatory: true,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/fba2ff32-361f-4170-ac22-9c1f5b5f1c12/FTS_Cert_Exp_03.12.21.pdf',
        },
      ],
    },
    {
      validityId: 'e8da95f7-f160-4c41-8c3e-0be8e240c807',
      validatedOn: '2019-12-03T00:00:00.0000000',
      expiresOn: '2021-12-03T00:00:00.0000000',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '03/12/2019',
      expiresOnText: '03/12/2021',
      exempt: false,
      qualificationId: 'c5b91273-6e90-4d19-94b2-8fc8e47048d1',
      qualificationType: 'General Qualification',
      qualificationNumber: '3',
      qualificationName: 'Human Factors',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: true,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/c07200cb-6871-45ce-b69d-5bcc8dfe9709/HF_Cert_Exp_03.12.21.pdf',
        },
      ],
    },
    {
      validityId: '05051203-4e61-48c0-ba0e-72037f01ec6f',
      validatedOn: '2019-12-16T00:00:00.0000000',
      expiresOn: '2021-12-15T00:00:00.0000000',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '16/12/2019',
      expiresOnText: '15/12/2021',
      exempt: false,
      qualificationId: '2ee79123-c50c-4d9f-aaf8-eed9ba45bd2d',
      qualificationType: 'General Qualification',
      qualificationNumber: '1',
      qualificationName: 'Initial Procedures/Continuation Training',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: true,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/c78bb52a-fa52-4ac9-a574-6bebe27cd063/QA129A_Continuation__Training.pdf',
        },
      ],
    },
    {
      validityId: '8703f39a-b4fd-4cb6-9185-70a8c023b75d',
      validatedOn: '1998-03-06T00:00:00.0000000',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '06/03/1998',
      expiresOnText: null,
      exempt: false,
      qualificationId: 'e206e7d8-a95c-4129-a565-398012180f0b',
      qualificationType: 'General Qualification',
      qualificationNumber: '1',
      qualificationName: 'Airbus A318/319/320/321 (CFM56)',
      qualificationExternalId: 'TYPE',
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/5a1fdcc5-bf69-444b-a62c-0a15687e66d3/cert21.pdf',
        },
        {
          fileNameStorage:
            '/Files/Attachment/b5b13989-e0de-4b46-9f5a-c6ed591573db/cert20.pdf',
        },
        {
          fileNameStorage:
            '/Files/Attachment/9ba41bbf-6c26-4b40-974a-6146c1a50a0d/Brian_Adkins_320_Neo_Course_Practical.pdf',
        },
        {
          fileNameStorage:
            '/Files/Attachment/ac844759-c910-4c08-8e8c-9293fdda9ee1/Brian_Adkins_-_320_NEO_Cert_Theory.pdf',
        },
      ],
    },
    {
      validityId: 'fc2f911e-5177-4a70-b4fb-5d2233d08e65',
      validatedOn: '1998-03-06T00:00:00.0000000',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '06/03/1998',
      expiresOnText: null,
      exempt: false,
      qualificationId: 'c125404b-095e-4cbf-9fc7-45e475c9a4cc',
      qualificationType: 'General Qualification',
      qualificationNumber: '1',
      qualificationName: 'Airbus A319/320/321 (IAE V2500)',
      qualificationExternalId: 'TYPE',
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/164c6736-c1de-4d2c-b876-5fbe79b1af8d/Brian_Adkins_320_Neo_Course_Practical.pdf',
        },
        {
          fileNameStorage:
            '/Files/Attachment/f4fe1f5f-750c-4ff6-8000-8ae61d440136/Brian_Adkins_-_320_NEO_Cert_Theory.pdf',
        },
        {
          fileNameStorage:
            '/Files/Attachment/e6bdd406-5002-42c1-a9b0-1c793d07a0c2/cert20.pdf',
        },
        {
          fileNameStorage:
            '/Files/Attachment/19767256-e9df-43b0-b53a-e59401f8d59b/cert19.pdf',
        },
      ],
    },
    {
      validityId: 'db5fe82c-8c1f-4f2b-bcf5-09cf52b13f59',
      validatedOn: '2019-02-01T00:00:00.0000000',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '01/02/2019',
      expiresOnText: null,
      exempt: false,
      qualificationId: 'a0634e15-53e0-4fbe-9015-82ae7abe8f76',
      qualificationType: 'General Qualification',
      qualificationNumber: '1',
      qualificationName: 'Airbus A319/320/321 NEO (CFM LEAP-1A)',
      qualificationExternalId: 'TYPE',
      qualificationNameShort: 'A320NEO',
      qualificationMandatory: false,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/e094c2d6-6c0f-42d7-9656-3e59f0b4c779/_408_LEAP_True_Copy_Brian.pdf',
        },
        {
          fileNameStorage:
            '/Files/Attachment/196f0e56-701c-41c6-918c-57de090894ba/Brian_Adkins_-_320_NEO_Cert_Theory.pdf',
        },
        {
          fileNameStorage:
            '/Files/Attachment/fb2cdbec-3dd0-4211-a943-6733fa9b563a/Brian_Adkins_320_Neo_Course_Practical.pdf',
        },
      ],
    },
    {
      validityId: '5769b000-2886-47a0-86de-4b982bc541d9',
      validatedOn: '2001-08-24T00:00:00.0000000',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '24/08/2001',
      expiresOnText: null,
      exempt: false,
      qualificationId: 'd465e3b7-f434-420c-b9da-04090dd70008',
      qualificationType: 'General Qualification',
      qualificationNumber: '1',
      qualificationName: 'Boeing 737-300/400/500 (CFM56)',
      qualificationExternalId: 'TYPE',
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/45737c1b-9a3d-489b-971f-6127907a764d/cert30.pdf',
        },
        {
          fileNameStorage:
            '/Files/Attachment/893a101e-3c69-4d9a-9366-b3acf8493b78/cert25.pdf',
        },
        {
          fileNameStorage:
            '/Files/Attachment/42c9212e-6ce8-4cfc-a7a8-8bfa7e58e9b3/cert31.pdf',
        },
        {
          fileNameStorage:
            '/Files/Attachment/e9988c1d-4622-4c91-ad89-449df9b848fd/cert29.pdf',
        },
        {
          fileNameStorage:
            '/Files/Attachment/25fc93a1-915a-4f5c-b8b6-0371e3c72811/Brian_C_of_R__408.pdf',
        },
      ],
    },
    {
      validityId: 'fa3793e9-eeab-4f01-ac9e-1e76294215af',
      validatedOn: '2000-08-11T00:00:00.0000000',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '11/08/2000',
      expiresOnText: null,
      exempt: false,
      qualificationId: '919d3b96-fba1-4c29-9484-fbc42a0d53de',
      qualificationType: 'General Qualification',
      qualificationNumber: '1',
      qualificationName: 'Boeing 737-600/700/800/900 (CFM56)',
      qualificationExternalId: 'TYPE',
      qualificationNameShort: 'B737NG',
      qualificationMandatory: false,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/a1e62653-1e98-4246-be6a-02a275468f05/cert26.pdf',
        },
        {
          fileNameStorage:
            '/Files/Attachment/ec1d03f1-a03d-4556-a10a-0ede5b339699/cert27.pdf',
        },
      ],
    },
    {
      validityId: '5c77a59c-8ce7-4482-bf72-4012e60b5134',
      validatedOn: '1992-09-04T00:00:00.0000000',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '04/09/1992',
      expiresOnText: null,
      exempt: false,
      qualificationId: '3bed382c-8682-465a-86d0-ffea604d73fd',
      qualificationType: 'General Qualification',
      qualificationNumber: '1',
      qualificationName: 'Boeing 757-200/300 (RB211)',
      qualificationExternalId: 'TYPE',
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/2e8bbb09-8e0f-440c-a9fe-beb1484b66b5/757_Cert089.pdf',
        },
      ],
    },
    {
      validityId: '707d4c0d-96d5-4d8a-b5a7-f297faa53629',
      validatedOn: '',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: null,
      expiresOnText: null,
      exempt: false,
      qualificationId: '765c300d-c3f3-4b6f-ae7a-863459ba8906',
      qualificationType: 'General Qualification',
      qualificationNumber: '3',
      qualificationName: 'Newquay',
      qualificationExternalId: null,
      qualificationNameShort: 'NQY',
      qualificationMandatory: false,
      files: [],
    },
    {
      validityId: 'f0fe86b6-847f-4145-bad4-a038e61ba9c3',
      validatedOn: '2016-10-05T00:00:00.0000000',
      expiresOn: '2021-10-05T00:00:00.0000000',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '05/10/2016',
      expiresOnText: '05/10/2021',
      exempt: false,
      qualificationId: 'b8ead503-2ed9-4808-80ac-6593a678e836',
      qualificationType: 'General Qualification',
      qualificationNumber: '1',
      qualificationName: 'GSAT',
      qualificationExternalId: null,
      qualificationNameShort: 'GSAT',
      qualificationMandatory: false,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/25eadfba-67d9-405b-abd6-a78cbb603741/GSAT_OCT_2016_issue095.pdf',
        },
      ],
    },
    {
      validityId: '846c41ed-cbee-42b8-94a6-a45ca3779867',
      validatedOn: '2016-12-06T00:00:00.0000000',
      expiresOn: '2021-12-06T00:00:00.0000000',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '06/12/2016',
      expiresOnText: '06/12/2021',
      exempt: false,
      qualificationId: '38eb0139-4aef-4bba-87fe-f0bd5974cb58',
      qualificationType: 'General Qualification',
      qualificationNumber: '2',
      qualificationName: 'CRC',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/0e471ece-1063-480a-bec0-fb9fbc539939/Adkins_-_Scottish_Disclosure_DEC2016.pdf',
        },
      ],
    },
    {
      validityId: '0361351f-b786-4ce4-bd84-d22ab1e26e0e',
      validatedOn: '',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: null,
      expiresOnText: null,
      exempt: false,
      qualificationId: '602001c3-ac95-4bc0-b642-8f7afddffb24',
      qualificationType: 'General Qualification',
      qualificationNumber: '1',
      qualificationName: 'MEWPs',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [],
    },
    {
      validityId: 'e92d3140-396c-415c-8b45-73ffba309593',
      validatedOn: '',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: 'Due',
      expiresOnText: null,
      exempt: false,
      qualificationId: '7c3bf168-9fbf-4d5a-b51d-c26fba19d2f1',
      qualificationType: 'General Qualification',
      qualificationNumber: '2',
      qualificationName: 'O2 / N2 Bottles',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [],
    },
    {
      validityId: 'af945155-2645-40b5-bc8b-0a662043aff7',
      validatedOn: '',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: 'Due',
      expiresOnText: null,
      exempt: false,
      qualificationId: 'f3fa85b0-93e3-466d-a4e2-9c91c10031cf',
      qualificationType: 'General Qualification',
      qualificationNumber: '4',
      qualificationName: 'Aircraft Staging',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [],
    },
    {
      validityId: 'be0184e4-793c-49d1-a4c5-6a2111861c85',
      validatedOn: '',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: 'Due',
      expiresOnText: null,
      exempt: false,
      qualificationId: '985fd322-3007-4fbb-81c8-1423b8cff64d',
      qualificationType: 'General Qualification',
      qualificationNumber: '6',
      qualificationName: 'Hydraulic Rigs',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [],
    },
    {
      validityId: 'ff711239-63b8-448b-9fbe-f27f1fcde611',
      validatedOn: '',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: 'Due',
      expiresOnText: null,
      exempt: false,
      qualificationId: '886adcec-86be-48da-90f8-9a4bc77fea59',
      qualificationType: 'General Qualification',
      qualificationNumber: '9',
      qualificationName: 'Aircraft Steps',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [],
    },
    {
      validityId: '07cf4c8b-da9a-4684-b3df-65f89954ef9e',
      validatedOn: '',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: 'Due',
      expiresOnText: null,
      exempt: false,
      qualificationId: 'b3daeb4a-c993-4b16-832f-99e69c2d0892',
      qualificationType: 'General Qualification',
      qualificationNumber: '10',
      qualificationName: 'Aircraft Jacks',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [],
    },
    {
      validityId: 'f82dd2dd-25ba-48fd-bb81-ab41ce5c13e6',
      validatedOn: '2018-05-03T00:00:00.0000000',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '03/05/2018',
      expiresOnText: null,
      exempt: false,
      qualificationId: '2d548fd2-f5c1-48ac-905a-6d68acb6dbc6',
      qualificationType: 'General Qualification',
      qualificationNumber: '13',
      qualificationName: 'Boroscope',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/b2d3a44a-6a6e-47bd-9a0c-5ecf261e27b4/Brian_Adkins_GE_BSI_Training_Cert_-_Apple.pdf',
        },
        {
          fileNameStorage:
            '/Files/Attachment/7f9b0299-bf3d-4d6d-871b-579a46c45d01/Brain_Adkins_-_Borescope_Training.pdf',
        },
      ],
    },
    {
      validityId: 'b456d438-9cde-43e9-9618-4ecd31c66d6b',
      validatedOn: '',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: 'Due',
      expiresOnText: null,
      exempt: false,
      qualificationId: 'f81d06dd-3d1a-4569-8707-f55aa2136a10',
      qualificationType: 'General Qualification',
      qualificationNumber: '4',
      qualificationName: 'Hangar Door Operation',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [],
    },
    {
      validityId: '51d0a2b7-0ad2-44e9-bbc9-8a09db8a0255',
      validatedOn: '',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: 'Due',
      expiresOnText: null,
      exempt: false,
      qualificationId: 'fa0fbca9-c048-4fb6-805d-d228dde91807',
      qualificationType: 'General Qualification',
      qualificationNumber: '8',
      qualificationName: 'ETOPS',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [],
    },
    {
      validityId: 'cc062272-fffe-49dd-9286-dc81f26eb2b3',
      validatedOn: '',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: null,
      expiresOnText: null,
      exempt: false,
      qualificationId: '6a4bf7eb-c6fc-458c-8127-36af68faf356',
      qualificationType: 'General Qualification',
      qualificationNumber: '2',
      qualificationName: 'Fire Awareness',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [],
    },
    {
      validityId: 'f1de805d-b33e-4fc4-9ffe-e79e035f5dcb',
      validatedOn: '',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: 'Due',
      expiresOnText: null,
      exempt: false,
      qualificationId: '41582b37-9c37-43a6-b2db-c4d9ef0ca895',
      qualificationType: 'General Qualification',
      qualificationNumber: '3',
      qualificationName: 'Working at Height',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [],
    },
    {
      validityId: '3c56c403-504b-4909-83f0-c8df0345ff86',
      validatedOn: '',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: 'Due',
      expiresOnText: null,
      exempt: false,
      qualificationId: 'a0153dd6-bfa5-403e-b6d2-177da9087f94',
      qualificationType: 'General Qualification',
      qualificationNumber: '4',
      qualificationName: 'Manual Handling',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [],
    },
    {
      validityId: '6def38b3-b42e-48ba-aef0-2138d562f066',
      validatedOn: '',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: 'Due',
      expiresOnText: null,
      exempt: false,
      qualificationId: '0aa839a9-0f3d-47bb-ad5d-eb365f8f250a',
      qualificationType: 'General Qualification',
      qualificationNumber: '5',
      qualificationName: 'Airside Awareness',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [],
    },
    {
      validityId: '7666e778-9216-41ea-bbd7-356e63aa0f60',
      validatedOn: '',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: 'Due',
      expiresOnText: null,
      exempt: false,
      qualificationId: '7085df65-1752-4290-9a00-d825bf05a4ce',
      qualificationType: 'General Qualification',
      qualificationNumber: '6',
      qualificationName: 'Fuel Spill Kit',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [],
    },
    {
      validityId: '3ac1cf6a-1f51-4b64-aad9-41d640629104',
      validatedOn: '',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: 'Due',
      expiresOnText: null,
      exempt: false,
      qualificationId: 'ba3b2496-9794-45a8-bfd9-3cfff29d831a',
      qualificationType: 'General Qualification',
      qualificationNumber: '7',
      qualificationName: 'Display Screen Equipment',
      qualificationExternalId: null,
      qualificationNameShort: 'DSE',
      qualificationMandatory: false,
      files: [],
    },
    {
      validityId: 'e434ed4e-8219-4cb8-9816-0c55fbbd24f0',
      validatedOn: '',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: null,
      expiresOnText: null,
      exempt: false,
      qualificationId: 'f0844f65-eaeb-4ab3-bb22-9148a04e80fc',
      qualificationType: 'General Qualification',
      qualificationNumber: '8',
      qualificationName: 'Medical',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [],
    },
    {
      validityId: '01f4278a-4e2c-4c85-8a8e-46368fe6f6fc',
      validatedOn: '2020-05-18T00:00:00.0000000',
      expiresOn: '2020-09-05T00:00:00.0000000',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '18/05/2020',
      expiresOnText: '05/09/2020',
      exempt: false,
      qualificationId: '2eba1490-bfeb-4c37-9bdc-c65d3a46425d',
      qualificationType: 'General Qualification',
      qualificationNumber: '1',
      qualificationName: 'Authorisation QA44',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [],
    },
    {
      validityId: '0db8839b-dd3d-4a0e-bff3-e82d26761517',
      validatedOn: '2017-07-05T00:00:00.0000000',
      expiresOn: '2021-07-22T00:00:00.0000000',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '05/07/2017',
      expiresOnText: '22/07/2021',
      exempt: false,
      qualificationId: '783bb3b5-bdb2-4bf4-94a9-41d836129f2a',
      qualificationType: 'General Qualification',
      qualificationNumber: '2',
      qualificationName: 'B1 Part 66 Licence',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/5c82254e-1928-41e2-8999-bda9148714b9/Brian_Adkins_-_Licence_update.pdf',
        },
      ],
    },
    {
      validityId: '81e9a1c5-3355-41f9-aecd-ec2e19295945',
      validatedOn: '2017-07-05T00:00:00.0000000',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '05/07/2017',
      expiresOnText: null,
      exempt: false,
      qualificationId: 'ca4dce16-155f-4651-96cc-1247ae2cee40',
      qualificationType: 'General Qualification',
      qualificationNumber: '5',
      qualificationName: 'C Certification',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/b7f7e013-38b4-4b63-bd25-d552bf1a3ac7/Brian_Adkins_-_Licence_update.pdf',
        },
      ],
    },
    {
      validityId: '850d0ec9-1535-463d-aeba-116a618fe8e7',
      validatedOn: '2019-02-28T00:00:00.0000000',
      expiresOn: '2021-02-28T00:00:00.0000000',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '28/02/2019',
      expiresOnText: '28/02/2021',
      exempt: false,
      qualificationId: 'bca9d1b9-6117-44a7-a3ee-2e23006e7492',
      qualificationType: 'General Qualification',
      qualificationNumber: '2',
      qualificationName: 'Certifying and Support staff Competency Assessment',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/a4c15151-495c-4c40-903d-9fc98a2ec4f6/Comp_Assesment_Brian_Adkins.pdf',
        },
      ],
    },
    {
      validityId: 'e3c4622c-6593-45e5-b52f-dad8425f6d14',
      validatedOn: '',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: 'Due',
      expiresOnText: null,
      exempt: false,
      qualificationId: 'd3fab811-52ce-4d11-aece-9956e411b282',
      qualificationType: 'General Qualification',
      qualificationNumber: '3',
      qualificationName: 'Centrik Induction',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [],
    },
    {
      validityId: '34e7d43d-1b70-40d5-b74b-fe7077372afc',
      validatedOn: '2019-12-12T00:00:00.0000000',
      expiresOn: '2021-12-12T00:00:00.0000000',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '12/12/2019',
      expiresOnText: '12/12/2021',
      exempt: false,
      qualificationId: 'aef86b7e-def7-41b5-b25c-e3485c87011e',
      qualificationType: 'General Qualification',
      qualificationNumber: '4',
      qualificationName: 'RR Product Integrity Training',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/733cbf66-af40-4af6-bb56-b48b955e5e38/RR_Product_Integrity_Training_cert_expires_12.12.21.pdf',
        },
      ],
    },
    {
      validityId: '24f5c7fc-6029-45b6-b98a-5c963e2e1ae1',
      validatedOn: '2018-09-06T00:00:00.0000000',
      expiresOn: '2020-09-06T00:00:00.0000000',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '06/09/2018',
      expiresOnText: '06/09/2020',
      exempt: false,
      qualificationId: '2cbc933b-1c42-4813-bb3d-2c66fe30c1ce',
      qualificationType: 'General Qualification',
      qualificationNumber: '5',
      qualificationName: 'Eye Test (with colour perception)',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/febb1bea-64b0-4dca-ad6f-aa5dd7058679/QA135_-_Brian_Adkins___Optician_Report.pdf',
        },
      ],
    },
    {
      validityId: 'a253f121-4ea9-4fda-8497-b3ad5e2b0ed0',
      validatedOn: '2014-05-05T00:00:00.0000000',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '05/05/2014',
      expiresOnText: null,
      exempt: false,
      qualificationId: 'ce9c3a2a-230f-42ff-94a6-197d96b6ffb0',
      qualificationType: 'General Qualification',
      qualificationNumber: '8',
      qualificationName: 'SMS Training',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/d9bc98e8-df65-490b-928d-2f66cc6acd67/Certificate_WBT_Training_ADKINS_Brian.pdf',
        },
      ],
    },
    {
      validityId: 'f6aacc44-1f4a-40fc-b85a-b944e6892886',
      validatedOn: '1998-02-06T00:00:00.0000000',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '06/02/1998',
      expiresOnText: null,
      exempt: false,
      qualificationId: '87d83ab1-2555-46ec-ae96-f1cd5fcefdf6',
      qualificationType: 'General Qualification',
      qualificationNumber: '1',
      qualificationName: 'International Aero Engines V2500-A1',
      qualificationExternalId: null,
      qualificationNameShort: 'V2500-A1',
      qualificationMandatory: false,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/5d39f497-2571-4bf3-b660-cd4089761279/cert19.pdf',
        },
      ],
    },
    {
      validityId: '28d30f0a-452a-4428-97dd-8cd9e0b40ffa',
      validatedOn: '1998-02-06T00:00:00.0000000',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '06/02/1998',
      expiresOnText: null,
      exempt: false,
      qualificationId: '4015df94-8690-4d9a-a4b9-29cc624d3034',
      qualificationType: 'General Qualification',
      qualificationNumber: '2',
      qualificationName: 'International Aero Engines V2500-A5',
      qualificationExternalId: null,
      qualificationNameShort: null,
      qualificationMandatory: false,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/a7c3d075-1e0c-47ed-a1c2-f67c5733e10a/cert19.pdf',
        },
      ],
    },
    {
      validityId: '353954b5-c251-41a0-b44a-7bcee5ba4bb5',
      validatedOn: '1992-09-07T00:00:00.0000000',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '07/09/1992',
      expiresOnText: null,
      exempt: false,
      qualificationId: '48ffcda5-de92-44f9-ad90-b1bbacf7b268',
      qualificationType: 'General Qualification',
      qualificationNumber: '3',
      qualificationName: 'Rolls Royce RB211-535',
      qualificationExternalId: null,
      qualificationNameShort: 'RB211-535',
      qualificationMandatory: false,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/26c6d1bb-d2ea-4b6b-8a38-5a00b6662274/757-Cert089.pdf',
        },
      ],
    },
    {
      validityId: '032c25d0-9aad-4629-9ff5-781cbed51cfe',
      validatedOn: '2001-08-24T00:00:00.0000000',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '24/08/2001',
      expiresOnText: null,
      exempt: false,
      qualificationId: '24f3d76a-7a4c-41d9-8bc4-83ba880987da',
      qualificationType: 'General Qualification',
      qualificationNumber: '10',
      qualificationName: 'CFM56-3 Series Engines',
      qualificationExternalId: null,
      qualificationNameShort: 'CFM56-3',
      qualificationMandatory: false,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/c1150119-5972-4252-b4df-895a85302af8/cert31.pdf',
        },
      ],
    },
    {
      validityId: '364c8684-78f8-45a2-badc-82bb45aa90c7',
      validatedOn: '1998-05-14T00:00:00.0000000',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '14/05/1998',
      expiresOnText: null,
      exempt: false,
      qualificationId: '15e38900-5f0b-4083-b91a-cc521f82ffca',
      qualificationType: 'General Qualification',
      qualificationNumber: '11',
      qualificationName: 'CFM56-5 Series Engines',
      qualificationExternalId: null,
      qualificationNameShort: 'CFM56-5',
      qualificationMandatory: false,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/50be3d5c-7ea9-4368-b013-ed4fdf62604e/cert21.pdf',
        },
      ],
    },
    {
      validityId: '6a7c2f42-2677-4c18-9e19-b7b6d9c65240',
      validatedOn: '2000-08-11T00:00:00.0000000',
      expiresOn: '',
      scheduledTrainingDate: '',
      scheduledTrainingText: null,
      validatedOnText: '11/08/2000',
      expiresOnText: null,
      exempt: false,
      qualificationId: '5779ce70-62c4-4724-83cd-15f3520fe486',
      qualificationType: 'General Qualification',
      qualificationNumber: '12',
      qualificationName: 'CFM56-7 Series Engines',
      qualificationExternalId: null,
      qualificationNameShort: 'CFM56-7',
      qualificationMandatory: false,
      files: [
        {
          fileNameStorage:
            '/Files/Attachment/d0f4b021-89b5-4f75-a77c-141d295e0829/cert27.pdf',
        },
      ],
    },
  ];

  /* #endregion */

  if (Array.isArray(quals)) {
    const filteredObj = quals.filter((qual) => {
      if (qual.qualificationExternalId === 'TYPE') {
        return qual;
      }
      return false;
    });

    splitUpQuals = [];
    filteredObj.forEach((f) => {
      splitUpQuals.push(splitQual(f.qualificationName));
    });
  } else {
    splitUpQuals = splitQual(quals);
  }

  return splitUpQuals;
};
