import { underlineText } from "../utilities/document";
import { drawBox } from "./utilities/document";
import wrap from "word-wrap";
//import { sentenceCase } from "sentence-case";

export const showDefect = (doc, { Defect }, startX, startY) => {
  // let sentenceDefect = Defect.split(".");
  // sentenceDefect.forEach((s) => {
  // s = sentenceCase(s.trim());
  // });

  // Defect = sentenceCase.join(" ");

  const newDefect = wrap(Defect, { width: 80 });
  const wordsArray = newDefect.split("\n");
  let yIndex = 14;
  drawBox(doc, startX, startY, 395, 85);

  underlineText(
    doc,
    "Defect / Detail of Work Required",
    startX + 5,
    startY + 3
  );

  doc.font("Helvetica-Bold").fontSize(7.5);

  wordsArray.every((n) => {
    yIndex = yIndex + 0.1;
    return doc.text(n, startX + 5, doc.y + yIndex - 1);
  });
  //   doc
  //     .font("Helvetica-Bold")
  //     .text(newDefect, startX + 5, doc.y + 14)
  //     .font("Helvetica");

  doc.font("Helvetica");
};
