import moment from "moment";

async function createPostChoochPayload(payload) {
  const body = {};

  if (payload.client) {
    body.client = payload.client;
  } else {
    throw new Error("Missing client in chooch call");
  }

  if (payload.dataUri) {
    body.dataUri = payload.dataUri;
  } else {
    throw new Error("Missing photo data chooch call");
  }

  if (payload.ipAddress) {
    body.ipAddress = payload.ipAddress;
  } else {
    throw new Error("Missing ip address in chooch call");
  }

  return body;
}

async function createPostClockinPayload(payload) {
  const body = {
    time: moment().format(),
  };

  if (payload.dataUri) {
    body.dataUri = payload.dataUri;
  } else {
    throw new Error("Missing photo data clockin call");
  }

  if (payload.fileUrl) {
    body.fileUrl = payload.fileUrl;
  } else {
    throw new Error("Missing the file URL in clockin call");
  }
  if (payload.logObj) {
    body.logObj = payload.logObj;
  } else {
    throw new Error("Missing the log object in clockin call");
  }

  if (payload.txName) {
    body.txName = payload.txName;
  } else {
    throw new Error("Missing the tx name in clockin call");
  }

  if (payload.BAccountID) {
    body.BAccountID = payload.BAccountID;
  } else {
    throw new Error("Missing the BAccountID in clockin call");
  }

  if (payload.EmployeeRefNo) {
    body.EmployeeRefNo = payload.EmployeeRefNo;
  } else {
    throw new Error("Missing the EmployeeRefNo in clockin call");
  }

  if (payload.isClockingIn !== undefined) {
    body.isClockingIn = payload.isClockingIn;
  } else {
    throw new Error("Missing the isClockingIn in clockin call");
  }

  return body;
}

export { createPostChoochPayload, createPostClockinPayload };
