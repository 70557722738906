import React, { useRef } from 'react';
import InActiveTimer from 'react-idle-timer';
import { useStoreActions, useStoreState } from 'easy-peasy';

const InActiveTimerContainer = () => {
  const InactiveTimerRef = useRef(null);
  const sessionTimeoutRef = useRef(null);

  const { signOut } = useStoreActions((actions) => actions);

  const onIdle = () => {
    console.log('User is idle');
    sessionTimeoutRef.current = setTimeout(logOut, 5000);
  };

  const logOut = () => {
    console.log('You Have been Logged Out ');
    signOut();
  };

  const { user } = useStoreState(state => state);

  return user && localStorage.getItem("autoLogout") ? (
    <div>
      <InActiveTimer
        ref={InactiveTimerRef}
        timeout={60000 * localStorage.getItem("autoLogoutMinutes")}
        onIdle={onIdle}
      ></InActiveTimer>
    </div>
  ) : (<></>);
};

export default InActiveTimerContainer;
