import React, { useRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';

const OptionsFilter = () => {
  const { nonRoutineOptionType } = useStoreState((state) => ({
    nonRoutineOptionType: state.nonRoutineOptionType,
  }));

  const { clearOptionsFilter, setFilteredOptions } = useStoreActions(
    (actions) => ({
      clearOptionsFilter: actions.clearOptionsFilter,
      setFilteredOptions: actions.setFilteredOptions,
    })
  );

  const text = useRef('');

  const onSubmit = (e) => {
    e.preventDefault();
    if (text.current.value !== '') {
      //setOptionsFilterText(text.current.value);
      setFilteredOptions({
        type: nonRoutineOptionType,
        text: text.current.value,
      });
    } else {
      clearOptionsFilter();
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Filter Options"
          aria-label="Filter Options"
          aria-describedby="basic-addon2"
          ref={text}
          autoFocus={true}
        />
        <div
          className="input-group-append"
          style={{ marginTop: '1.2rem' }}
          onClick={onSubmit}
        >
          <button className="btn btn-success" type="button">
            <i className="fa fa-search text-white mr-2"></i>
            Search
          </button>
        </div>
      </div>
    </form>
  );
};

export default OptionsFilter;
