import { useStoreActions, useStoreState } from "easy-peasy";
import { Modal, ModalBody, Input, Form, Button } from "reactstrap";
import React from "react";

function BiometricAlternativeModal(props) {
  const {
    showBiometricAlternativeModal,
    biometricAlternativeCodeInput,
    alertColor,
  } = useStoreState((state) => state);

  const {
    showBiometricAlternativeModalAction,
    setBiometricAlternativeCodeInputAction,
    biometricAlternativeThunk,
  } = useStoreActions((actions) => actions);

  return (
    <Modal
      centered={true}
      isOpen={showBiometricAlternativeModal}
      toggle={() => showBiometricAlternativeModalAction(false)}
      {...props}
    >
      <ModalBody
        className={`text-center bg-${
          alertColor === "danger" ? alertColor : "white"
        }`}
      >
        <Form onSubmit={() => biometricAlternativeThunk()}>
          <h3>Alternate ID</h3>
          <Input
            value={biometricAlternativeCodeInput}
            onChange={(evt) =>
              setBiometricAlternativeCodeInputAction(evt.target.value)
            }
            placeholder="Enter Alternate ID"
          />
          <Button type="submit">Submit</Button>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default BiometricAlternativeModal;
