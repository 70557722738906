import { action } from "easy-peasy";

//import { formatISOToDateMonthYear } from '../../utils/formatDates';

const stockItemsActions = {
  clearStockItemSearchValue: action((state) => {
    state.stockItemSearchValue = "";
  }),
  setStockItemSearchValue: action((state, text) => {
    state.stockItemSearchValue = text;
  }),
  cleanupStockItemSearch: action((state) => {
    state.stockItemSearchValue = "";
    state.filteredStockItems = null;
  }),
  setShowStockItemSearchResults: action((state, show) => {
    state.showStockItemSearchResults = show;
  }),
  cleanupStockItemSearchResultsModal: action((state) => {
    state.filteredStockItems = null;
  }),
  setStockItemToAdd: action((state, item) => {
    //item.Qty = '';
    //item.UsrNeedBy = '';
    //item.UsrPriorityLevel = '';
    state.stockItemToAdd = item;
  }),
  setQtyStockItemToAdd: action((state, payload) => {
    let ar = [];
    state.partRequestList !== null && (ar = state.partRequestList);

    state.partRequestList = [...ar, payload];

    state.stockItemToAdd = null;
  }),
  setShowStockItemQty: action((state, show) => {
    state.showStockItemQty = show;
  }),
  setStockItems: action((state, items) => {
    state.stockItems = items;
  }),
  setStockItemsComplete: action((state, finished) => {
    state.stockItemsComplete = finished;
  }),
  setFilteredStockItems: action((state, items) => {
    state.filteredStockItems = items;
  }),
  setPriorityLevelOptions: action((state, options) => {
    state.priorityLevelOptions = options;
  }),
  setShowAddNewStockItem: action((state, show) => {
    state.showAddNewStockItem = show;
  }),
  setShowAddUnsvcStockItem: action((state, show) => {
    state.showAddUnsvcStockItem = show;
  }),
  addNewStockItemToRequestList: action((state, item) => {
    let ar = [];
    state.partRequestList !== null && (ar = state.partRequestList);

    state.partRequestList = [...ar, item];
  }),
  setPartItemRequest: action((state, item) => {
    state.stockItemToAdd[item.key] = item.value;
    let { Qty, UsrNeedBy, UsrPriorityLevel } = state.stockItemToAdd;
    state.enableStockItemAdd =
      Qty > 0 && UsrNeedBy !== "" && UsrPriorityLevel !== "";
  }),
  setPartRequestList: action((state, item) => {
    state.partRequestList = item;
  }),
  setPartRequestListComplete: action((state, finished) => {
    state.partRequestListComplete = finished;
  }),
  setPartNotFoundItem: action((state, item) => {
    state.partNotFoundItem[item.key] = item.value;

    let { InventoryID, Description, Qty, UsrNeedBy, UsrPriorityLevel } =
      state.partNotFoundItem;
    state.enablePartNotFoundItemAdd =
      InventoryID !== "" &&
      Description !== "" &&
      Qty > 0 &&
      UsrNeedBy !== "" &&
      UsrPriorityLevel !== "";
  }),
  updateUnservicable: action((state, payload) => {
    state.unservicable = {
      ...state.unservicable,
      ...payload,
    };
  }),
  clearUnserviceablePart: action((state) => {
    state.unservicable = {
      PartNbr: {
        InventoryCD: "",
        InventoryID: 0,
      },
      SerialNbr: "",
      StateShelfLife: "",
      Description: "",
      Quantity: 0,
      Date: "",
      Station: "",
      Owner: "",
      TechLogRef: "",
      Operator: "",
      ReasonforRemoval: "",
    };
  }),
  setPartNotFoundInventoryID: action((state) => {
    state.partNotFoundItem.InventoryID = state.stockItemSearchValue.toString();
  }),
  clearPartNotFoundItem: action((state) => {
    state.partNotFoundItem = {
      InventoryID: "",
      Description: "",
      Qty: 0,
      UsrNeedBy: "",
      UsrPriorityLevel: "",
      note: "",
    };
  }),

  setShowUnservicablePopup: action((state, show) => {
    state.showUnservicablePopup = show;
  }),

  setReportingItemReq: action((state, itemReq) => {
    state.reportingItemReq = itemReq;
  }),
  setItemNoteAction: action((state, note) => {
    state.note = note;
  }),
};

export default stockItemsActions;
