import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";

const SelectGeography = (props) => {
  const { tenant, isTest } = useStoreState((state) => ({
    tenant: state.tenant,
    isTest: state.isTest,
  }));

  const { setTenant, setLocalStorageToken, setLogo, setFavicon, setSpinner } =
    useStoreActions((actions) => actions);

  const setGeography = (geo) => {
    if (!isTest) {
      //this is a production environment

      const usToken =
        /*production*/ "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InN0c1VTUHJvZHVjdGlvbkBjZmJzLXVzLmNvbSIsImlhdCI6MTYzMDI4NTE3Mn0.YiPwt8H-tcy4WNBIy3PhlKbzNfqlUp0R2bPCz8-KaLo";

      if (geo === "us") {
        setTenant(tenant);
        setLocalStorageToken(usToken);
        localStorage.setItem("autoLogout", true);
        localStorage.setItem("autoLogoutMinutes", 3);
        setFavicon("favicon_sts.ico");
        setLogo("logoModCenter.png");
        setSpinner("rotate_sts.gif");
        props.history.push("/");
      }

      const ukToken =
        /* production */ "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InN0c1VLUHJvZHVjdGlvbkBjZmJzLXVzLmNvbSIsImlhdCI6MTYzMDI4NTMxMH0.3yksEu2N3JNvj4tCRP8ac4h_zTscMNo898Rw21saql8";
      if (geo === "uk") {
        setTenant(tenant);
        setLocalStorageToken(ukToken);
        setFavicon("favicon_sts.ico");
        setLogo("logoModCenterUK.png");
        setSpinner("rotate_sts.gif");

        props.history.push("/");
      }

      const irelandToken =
        /* production */ "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN0c0lyZWxhbmRAY2Zicy11cy5jb20iLCJpZCI6InN0c0lyZWxhbmRAY2Zicy11cy5jb20iLCJpYXQiOjE2OTU3NDA1OTB9.WYMZ4TcUFVCHN3DVUyVTxbxvy1CY1MAIl3Amk8DBtrs";
      if (geo === "ireland") {
        setTenant(tenant);
        setLocalStorageToken(irelandToken);
        setFavicon("favicon_sts.ico");
        setLogo("logoModCenterIreland.png");
        setSpinner("rotate_sts.gif");

        props.history.push("/");
      }

      const dubaiToken =
        /* production */ "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN0c0R1YmFpQGNmYnMtdXMuY29tIiwiaWQiOiJzdHNEdWJhaUBjZmJzLXVzLmNvbSIsImlhdCI6MTY5NTY2NzIzOH0.t0PCXoxAMBaes4OYmvqZRsQXub5Us9xlt2P5w-nH2oY";
      if (geo === "dubai") {
        setTenant(tenant);
        setLocalStorageToken(dubaiToken);
        setFavicon("favicon_sts.ico");
        setLogo("logoModCenterDubai.png");
        setSpinner("rotate_sts.gif");

        props.history.push("/");
      }
    } else {
      //this is a test environment

      if (geo === "us") {
        const usToken =
          /*test 21R2 */ "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InVzMjFSMlRlc3RAY2Zicy11cy5jb20iLCJpYXQiOjE2NDkxNjM4NDV9.U2X0aed-H8NVq6IZIX8pHGXG-K0EeruRZyRVsDW7knQ";
        setTenant(tenant);
        setLocalStorageToken(usToken);
        localStorage.setItem("autoLogout", true);
        localStorage.setItem("autoLogoutMinutes", 3);
        setFavicon("favicon_sts.ico");
        setLogo("logoModCenterTest.png");
        setSpinner("rotate_sts.gif");
        props.history.push("/");
      }
      if (geo === "uk") {
        const ukToken =
          /*test 21R2 */ "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InVrMjFSMlRlc3RAY2Zicy11cy5jb20iLCJpYXQiOjE2NDkxNjM5MjV9.atIAJffp-Ib-ch05Jj8Q82nAbsmcp81EbmXDPNDHki0";
        setTenant(tenant);
        setLocalStorageToken(ukToken);
        setFavicon("favicon_sts.ico");
        setLogo("logoModCenterUKTest.png");
        setSpinner("rotate_sts.gif");

        props.history.push("/");
      }
      if (geo === "ireland") {
        const irelandToken =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN0c0lyZWxhbmQyMVIyVGVzdEBjZmJzLXVzLmNvbSIsImlkIjoic3RzSXJlbGFuZDIxUjJUZXN0QGNmYnMtdXMuY29tIiwiaWF0IjoxNjk1NjcwNTI3fQ.-U75HTZ_N3bIaPnB2XYFwNPAQDttiEI0Rm7TaMHE9jk";
        setTenant(tenant);
        setLocalStorageToken(irelandToken);
        setFavicon("favicon_sts.ico");
        setLogo("logoModCenterIrelandTest.png");
        setSpinner("rotate_sts.gif");

        props.history.push("/");
      }
      if (geo === "dubai") {
        const irelandToken =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN0c0R1YmFpMjFSMlRlc3RAY2Zicy11cy5jb20iLCJpZCI6InN0c0R1YmFpMjFSMlRlc3RAY2Zicy11cy5jb20iLCJpYXQiOjE2OTQ1MDQ0OTd9.Gwu1YD9XptNzRv5LgLRHtpkC5K2o0tI7SzVqcmtUBVg";
        setTenant(tenant);
        setLocalStorageToken(irelandToken);
        setFavicon("favicon_sts.ico");
        setLogo("logoModCenterDubaiTest.png");
        setSpinner("rotate_sts.gif");

        props.history.push("/");
      }

      //this is a QA environment
      if (geo === "usQA") {
        const usToken =
          /*qa 21R2 */ "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InN0c1VTUUFAY2Zicy11cy5jb20iLCJpYXQiOjE2NDkxNjM4NDV9.oukgZx1i9U4mKIa7VTooXOsZ5Yn0skMFEuR8DPm61CQ";
        setTenant(tenant);
        setLocalStorageToken(usToken);
        localStorage.setItem("autoLogout", true);
        localStorage.setItem("autoLogoutMinutes", 3);
        setFavicon("favicon_sts.ico");
        setLogo("logoModCenterQA.png");
        setSpinner("rotate_sts.gif");
        props.history.push("/");
      }
      if (geo === "ukQA") {
        const ukToken =
          /*qa 21R2 */ "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InN0c1VLUUFAY2Zicy11cy5jb20iLCJpYXQiOjE2NDkxNjM4NDV9.Ms2RaAcqrFOh6BvwAnEm7s3GvVhNgNx_X16Um1rQMro";
        setTenant(tenant);
        setLocalStorageToken(ukToken);
        setFavicon("favicon_sts.ico");
        setLogo("logoModCenterUKQA.png");
        setSpinner("rotate_sts.gif");
        props.history.push("/");
      }
      if (geo === "irelandQA") {
        const irelandToken =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN0c0lyZWxhbmRRQUBjZmJzLXVzLmNvbSIsImlkIjoic3RzSXJlbGFuZFFBQGNmYnMtdXMuY29tIiwiaWF0IjoxNjk1NzQwNDgwfQ.5kzGTaJEGl2TLmcaapaIHQyPZcGaViO_qUN6v50HUKM";
        setTenant(tenant);
        setLocalStorageToken(irelandToken);
        setFavicon("favicon_sts.ico");
        setLogo("logoModCenterIrelandQA.png");
        setSpinner("rotate_sts.gif");
        props.history.push("/");
      }
      if (geo === "dubaiQA") {
        const dubaiToken =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN0c0R1YmFpUUFAY2Zicy11cy5jb20iLCJpZCI6InN0c0R1YmFpUUFAY2Zicy11cy5jb20iLCJpYXQiOjE2OTU3NDAwOTZ9.1QUwA3xEYCi54V-UFCJl1munsmcQwp4St7MrYRqpUIk";
        setTenant(tenant);
        setLocalStorageToken(dubaiToken);
        setFavicon("favicon_sts.ico");
        setLogo("logoModCenterDubaiTest.png");
        setSpinner("rotate_sts.gif");
        props.history.push("/");
      }

      //this is a sandbox environment
      if (geo === "usSB") {
        const usToken =
          /*sandbox 21R2 */ "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InN0c1VTU2FuZGJveEBjZmJzLXVzLmNvbSIsImlhdCI6MTY0OTE2Mzg0NX0.MBbQytS4YCv2rLvct8FJM0P69x0V5F6O7gwVo-8hb14";
        setTenant(tenant);
        setLocalStorageToken(usToken);
        localStorage.setItem("autoLogout", true);
        localStorage.setItem("autoLogoutMinutes", 3);
        setFavicon("favicon_sts.ico");
        setLogo("logoModCenterSandbox.png");
        setSpinner("rotate_sts.gif");
        props.history.push("/");
      }
      if (geo === "ukSB") {
        const ukToken =
          /*sandbox 21R2 */ "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InN0c1VLU2FuZGJveEBjZmJzLXVzLmNvbSIsImlhdCI6MTY0OTE2Mzg0NX0.SIsSWR8cgxDnm54nrAbFeef1YiRnvVvj_cbT7Zg3WkU";
        setTenant(tenant);
        setLocalStorageToken(ukToken);
        setFavicon("favicon_sts.ico");
        setLogo("logoModCenterUKSandbox.png");
        setSpinner("rotate_sts.gif");
        props.history.push("/");
      }

      //   //this is an alpha environment
      if (geo === "usAL") {
        const usToken =
          /*sandbox 21R2 */ "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InN0c1VTQWxwaGFAY2Zicy11cy5jb20ifQ.C7iloz6pB9kSJPTaDJX13ppmIaf0K_ulmEpDFh7ZXgo";
        setTenant(tenant);
        setLocalStorageToken(usToken);
        localStorage.setItem("autoLogout", true);
        localStorage.setItem("autoLogoutMinutes", 3);
        setFavicon("favicon_sts.ico");
        setLogo("logoModCenterUSAlpha.png");
        setSpinner("rotate_sts.gif");
        props.history.push("/");
      }
      if (geo === "ukAL") {
        const ukToken =
          /*sandbox 21R2 */ "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InN0c1VLQWxwaGFAY2Zicy11cy5jb20ifQ.VE5gENE1c3FZ6RjSa3H3vEKe7la_wV55sDT68Kk-Nww";
        setTenant(tenant);
        setLocalStorageToken(ukToken);
        setFavicon("favicon_sts.ico");
        setLogo("logoModCenterUKAlpha.png");
        setSpinner("rotate_sts.gif");
        props.history.push("/");
      }
    }
  };

  return (
    <>
      <div className="jumbotron text-center">
        <h1 className="mb-4">Please select a Geography and Environment</h1>
        <ul>
          <li className="">
            <div
              onClick={() => setGeography("us")}
              className="text-primary cursor-pointer grid-2-66-33"
            >
              <h2 className="text-danger text-right">{isTest ? "TEST" : ""}</h2>
              <h2 className="text-left">
                US - STS {isTest ? "Mod Center" : "Aviation Services"}
              </h2>
            </div>
          </li>
          {isTest && (
            <>
              <li className="">
                <div
                  onClick={() => setGeography("usQA")}
                  className="text-primary cursor-pointer grid-2-66-33"
                >
                  <h2 className="text-danger text-right">
                    {isTest ? "QA" : ""}
                  </h2>
                  <h2 className="text-left">US - STS Mod Center</h2>
                </div>
              </li>
              <li className="">
                <div
                  onClick={() => setGeography("usSB")}
                  className="text-primary cursor-pointer grid-2-66-33"
                >
                  <h2 className="text-danger text-right">
                    {isTest ? "SANDBOX" : ""}
                  </h2>
                  <h2 className="text-left">US - STS Mod Center</h2>
                </div>
              </li>
              <li className="mb-4">
                <div
                  onClick={() => setGeography("usAL")}
                  className="text-primary cursor-pointer grid-2-66-33"
                >
                  <h2 className="text-danger text-right">
                    {isTest ? "ALPHA" : ""}
                  </h2>
                  <h2 className="text-left">US - STS Mod Center</h2>
                </div>
              </li>
            </>
          )}
          <li>
            <div
              onClick={() => setGeography("uk")}
              className="text-primary cursor-pointer grid-2-66-33"
            >
              <h2 className="text-danger text-right">{isTest ? "TEST" : ""}</h2>
              <h2 className="text-left">UK - STS Aviation Services</h2>
            </div>
          </li>
          {isTest && (
            <>
              <li>
                <div
                  onClick={() => setGeography("ukQA")}
                  className="text-primary cursor-pointer grid-2-66-33"
                >
                  <h2 className="text-danger text-right">
                    {isTest ? "QA" : ""}
                  </h2>
                  <h2 className="text-left">UK - STS Aviation Services</h2>
                </div>
              </li>
              <li>
                <div
                  onClick={() => setGeography("ukSB")}
                  className="text-primary cursor-pointer grid-2-66-33"
                >
                  <h2 className="text-danger text-right">
                    {isTest ? "SANDBOX" : ""}
                  </h2>
                  <h2 className="text-left">UK - STS Aviation Services</h2>
                </div>
              </li>
              <li className="mb-4">
                <div
                  onClick={() => setGeography("ukAL")}
                  className="text-primary cursor-pointer grid-2-66-33"
                >
                  <h2 className="text-danger text-right">
                    {isTest ? "ALPHA" : ""}
                  </h2>
                  <h2 className="text-left">UK - STS Aviation Services</h2>
                </div>
              </li>
            </>
          )}
          <li>
            <div
              onClick={() => setGeography("ireland")}
              className="text-primary cursor-pointer grid-2-66-33"
            >
              <h2 className="text-danger text-right">{isTest ? "TEST" : ""}</h2>
              <h2 className="text-left">Ireland - STS Aviation Services</h2>
            </div>
          </li>
          {isTest && (
            <li className="mb-4">
              <div
                onClick={() => setGeography("irelandQA")}
                className="text-primary cursor-pointer grid-2-66-33"
              >
                <h2 className="text-danger text-right">{isTest ? "QA" : ""}</h2>
                <h2 className="text-left">Ireland - STS Aviation Services</h2>
              </div>
            </li>
          )}
          <li>
            <div
              onClick={() => setGeography("dubai")}
              className="text-primary cursor-pointer grid-2-66-33"
            >
              <h2 className="text-danger text-right">{isTest ? "TEST" : ""}</h2>
              <h2 className="text-left">Dubai - STS Aviation Services</h2>
            </div>
          </li>
          {isTest && (
            <li>
              <div
                onClick={() => setGeography("dubaiQA")}
                className="text-primary cursor-pointer grid-2-66-33"
              >
                <h2 className="text-danger text-right">{isTest ? "QA" : ""}</h2>
                <h2 className="text-left">Dubai - STS Aviation Services</h2>
              </div>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default SelectGeography;
