import { useStoreActions, useStoreState } from 'easy-peasy';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Label, FormGroup, Button } from 'reactstrap';

const useFields = () => {
  const { email, password } = useStoreState((state) => ({
    email: state.loginInput.email,
    password: state.loginInput.password,
  }));
  const { updateLogin } = useStoreActions((actions) => actions);

  return [
    {
      label: 'Email',

      input: {
        type: 'email',
        placeholder: 'Enter email',
        value: email,
        onChange: (evt) => updateLogin({ email: evt.target.value }),
      },
    },
    {
      label: 'Password',
      input: {
        type: 'password',
        placeholder: 'Enter password',
        value: password,
        onChange: (evt) => updateLogin({ password: evt.target.value }),
      },
    },
  ];
};

const Login = () => {
  const { loginInput } = useStoreState((state) => ({
    loginInput: state.loginInput,
  }));
  const { getLogin } = useStoreActions((actions) => actions);
  const history = useHistory();

  const onSubmit = async (evt) => {
    evt.preventDefault();
    await getLogin({ loginInput, history });
  };

  return (
    <Form
      className="bg-light p-5 mx-auto"
      style={{ maxWidth: '500px', marginTop: '175px' }}
      onSubmit={onSubmit}
    >
      <img
        src="https://kioskbranding.blob.core.windows.net/images/promro_logo.png"
        alt="ProMROs"
      />
      <br /> <br />
      <h1 className="text-center">Login</h1>
      {useFields().map(({ label, input }, index) => (
        <FormGroup key={index}>
          <Label>{label}</Label>
          <Input {...input} />
          <br />
        </FormGroup>
      ))}
      <Button className="w-100" color="primary" type="submit">
        Login
      </Button>
    </Form>
  );
};

export default Login;
