import { centerText } from "../utilities/document";
import { DEFAULT_FONT_SIZE, drawBox } from "./utilities/document"

export const showMechanic = (
  doc,
  startX,
  startY,
  options = { fontSize: 7.5 }
) => {
  const boxWidth = (doc.page.width - 50 - startX) / 2;

  drawBox(doc, startX, startY, boxWidth, 185);

  doc.fontSize(8);
  centerText(doc, 'MECHANIC', boxWidth, startX, startY + 5, options);
  centerText(doc, '/ Date', boxWidth, startX, doc.y + 12, options);
  doc.fontSize(6);
  centerText(doc, '(DD/MM/YYYY)', boxWidth, startX, doc.y + 12, options);

  doc.fontSize(DEFAULT_FONT_SIZE);
};