import React, { Fragment } from 'react';
import { useStoreState } from 'easy-peasy';

const Spinner = () => {
  const { spinner } = useStoreState((state) => ({
    spinner: state.spinner,
  }));

  const spinnerURL = `https://kioskbranding.blob.core.windows.net/images/${spinner}`;

  return (
    <Fragment>
      <img
        src={spinnerURL}
        alt="Loading..."
        style={{
          width: '200px',
          margin: 'auto',
          display: 'block',
        }}
      />
    </Fragment>
  );
};

export default Spinner;
