import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ForcedLogout from '../../layout/ForcedLogout';
import { useStoreState, useStoreActions } from 'easy-peasy';

const TaskItem = ({ task }) => {
  const {
    ProjectCD,
    TaskCD,
    ProjectTaskDescription,
    TaskID,
    TaskStatus,
    qualified,
  } = task;

  const { projectTasks, user, queueLocked, showForcedLogoutModal } =
    useStoreState((state) => ({
      projectTasks: state.projectTasks,
      user: state.user,
      queueLocked: state.queueLocked,
      showForcedLogoutModal: state.showForcedLogoutModal,
    }));

  const {
    setLoading,
    clearTaskFilter,
    addTaskToProjectTasksByCode,
    setForcedLogoutModal,
  } = useStoreActions((actions) => ({
    setLoading: actions.setLoading,
    clearTaskFilter: actions.clearTaskFilter,
    addTaskToProjectTasksByCode: actions.addTaskToProjectTasksByCode,
    setForcedLogoutModal: actions.setForcedLogoutModal,
  }));

  const canAddTask =
    projectTasks &&
    projectTasks.length &&
    user.taskLimit &&
    projectTasks.length >= user.taskLimit
      ? false
      : true;

  const addTask = () => {
    if (queueLocked) {
      setForcedLogoutModal(true);
    } else {
      addTaskToProjectTasksByCode(task.TaskCD.trim());
      setLoading(true);
    }
    clearTaskFilter();
  };

  if (TaskID) {
    return (
      <Fragment>
        {showForcedLogoutModal ? (
          <ForcedLogout />
        ) : (
          <div
            className={
              qualified
                ? 'card-slim bg-light mt-1'
                : 'card-slim bg-warning mt-1'
            }
          >
            <div className="grid-2">
              <div>
                <span
                  className="m-2"
                  style={{ fontSize: '10px', position: 'relative' }}
                >
                  {ProjectCD}
                </span>
                <h5 className="text-primary text-left project-description">
                  Task {TaskCD}
                  <span className="smaller">
                    Status:{' '}
                    <span
                      className={
                        TaskStatus === 'Active'
                          ? 'text-success'
                          : TaskStatus === 'Inspection'
                          ? 'text-warning-invert'
                          : TaskStatus === 'Reject'
                          ? 'text-danger'
                          : 'text-dark'
                      }
                    >
                      {TaskStatus}
                    </span>
                  </span>
                </h5>
                <div className="task-description">
                  {ProjectTaskDescription} :{' '}
                </div>
              </div>
              <div className="grid-3 my-3 large">
                <div></div>
                <div></div>
                {canAddTask ? (
                  projectTasks
                    .map((p) => p.ProjectTaskIDInt.value)
                    .indexOf(TaskID) === -1 ? (
                    //not in my tasks but qualified
                    qualified ? (
                      <div onClick={addTask} value={TaskCD}>
                        <i
                          className="fas fa-plus-circle text-success text-center"
                          style={{ width: '100%' }}
                          value={TaskCD}
                        ></i>
                        <div className="icon-description text-center">
                          Add to My Tasklist
                        </div>
                      </div>
                    ) : (
                      //not in my tasks and not qualified
                      <div value={TaskCD}>
                        <i
                          className="fas fa-ban text-danger text-center"
                          style={{ width: '100%' }}
                          value={TaskCD}
                        ></i>
                        <div className="icon-description text-center">
                          Not Qualified
                        </div>
                      </div>
                    )
                  ) : (
                    //already in my tasks
                    <div value={TaskCD}>
                      <i
                        className="fas fa-check-circle text-warning text-center"
                        style={{ width: '100%' }}
                        value={TaskCD}
                      ></i>
                      <div className="icon-description text-center">
                        Checked Out
                      </div>
                    </div>
                  )
                ) : (
                  <div
                    value={TaskCD}
                    title={`Clock out of task ${projectTasks[0].ProjectTaskID.value}\nin order to clock into a new task`}
                  >
                    <i
                      className="fas fa-ban text-danger text-center"
                      style={{ width: '100%' }}
                      value={TaskCD}
                    ></i>
                    <div className="icon-description text-center">
                      Open Task Limit Reached
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  } else {
    return '';
  }
};

TaskItem.propTypes = {
  task: PropTypes.object.isRequired,
};

export default TaskItem;
