import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import { refreshTokenAPICall } from "./auth";

axios.defaults.withCredentials = true;

function interceptor(axios, store) {
  axios.interceptors.request.use(async (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.config.url.includes("token/refresh")) {
        store.getActions().setUser(null);
        localStorage.removeItem("user");
      } else if (error.response.status === 401) {
        // refresh token
        const newAccessTokenResponse = await refreshTokenAPICall();
        const newAccessToken = newAccessTokenResponse.data.accessToken;
        setAuthToken(newAccessToken);
        localStorage.setItem("token", newAccessToken);
        error.config.headers.Authorization = `Bearer ${newAccessToken}`;
        return Promise.resolve(await axios.request(error.config));
      }

      return Promise.reject(error);
    }
  );
}

export { axios, interceptor };
