import React, { useEffect, useRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';

const TaskFilter = () => {
  const { filteredTasks } = useStoreState((state) => ({
    filteredTasks: state.filteredTasks,
  }));

  const { clearTaskFilter, setFilteredTasks } = useStoreActions((state) => ({
    clearTaskFilter: state.clearTaskFilter,
    setFilteredTasks: state.setFilteredTasks,
  }));

  const text = useRef('');

  useEffect(() => {
    if (filteredTasks === null) {
      text.current.value = '';
    }
  });

  const onChange = (e) => {
    if (text.current.value !== '') {
      setFilteredTasks(e.target.value);
    } else {
      clearTaskFilter();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <input
        ref={text}
        type="text"
        placeholder="Filter Task"
        onChange={onChange}
      />
    </form>
  );
};

export default TaskFilter;
