import { axios } from ".";

const taskAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/task`,
});

async function toggleBillableTaskApiCall(data) {
  const response = await taskAxios({
    method: "put",
    url: "/billable/toggle",
    data,
  });
  return response.data;
}

export { taskAxios, toggleBillableTaskApiCall };
