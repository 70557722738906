import employeeStore from "./employee";
import projectStore from "./projects";
import stockItemStore from "./stockItems";
import clockInStore from "./clockin";
//import startUpVals from '../startUpVals';

//always use USPROMRO****** for test and dev
// console.log('App URL: ', process.env.REACT_APP_APIBASEURL);

const testSites = ["test", "dev", "localhost", "3000"];
const initialState = {
  apiBaseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  // /* Production */ "https://cfbskioskprod.azurewebsites.net",
  /* Developer Testing */ // "https://cfbskiosk.azurewebsites.net",

  // /* Docker */ 'https://https://stsshopdev.azurewebsites.net',

  // for STS Build comment out other urls and use the logic below.
  // window.location.href.includes("test") ||
  // window.location.href.includes("3000")
  //   ? "https://testKioskAPI.stsholding.com"
  //   : "https://kioskAPI.stsholding.com",

  // "http://localhost:8080/",

  isTest: testSites.some((site) => window.location.href.includes(site)),
  loginInput: {
    email: "",
    password: "",
  },
  usingCentrik: false,
  page: "home",
  user: null,
  showUserWarning: false,
  qualifications: {
    customer: null,
    personal: null,
    mechanicQualified: null,
    inspectorQualified: null,
    boroscopeQualified: null,
    failedQualification: null,
  },
  loading: false,
  favicon: localStorage.getItem("favicon"),
  logo: localStorage.getItem("logo"),
  spinner: localStorage.getItem("spinner"),
  tenant: process.env.REACT_APP_STS_TENANT || "",
  alerts: [],
  queueLocked: false,
  showForcedLogoutModal: false,
  queueScanLockSeconds: null,
  queueScanCountDownIsActive: false,
  projectTasks: null,
  taskLimit: false,
  filteredProjectTasks: null,
  projectSearchValue: "",
  foundProjects: null,
  filteredTasks: null,
  filteredFoundProjects: null,
  tasksByFilteredFoundProject: null,
  currentTask: null,
  showInspectorButtons: false,
  showNonRoutineButton: true,
  carouselItems: ["TaskItem", "PartsRequest", "NonRoutineItem"],
  activeCarouselIndex: 0,
  carouselAnimating: false,
  carouselInterval: false,
  stockItemSearchValue: "",
  showStockItemSearchResults: false,
  partRequestList: null,
  partRequestListComplete: false,
  reportingItemReq: null,
  priorityLevelOptions: [],
  showStockItemQty: false,
  stockItems: null,
  stockItemsComplete: false,
  filteredStockItems: null,
  stockItemToAdd: {
    Qty: 0,
    UsrNeedBy: "",
  },
  partNotFoundItem: {
    InventoryID: "",
    Description: "",
    Qty: 0,
    UsrNeedBy: "",
    UsrPriorityLevel: "",
    note: "",
  },
  enableStockItemAdd: false,
  showAddNewStockItem: false,
  showAddUnsvcStockItem: false,
  showInspectionRejectModal: false,
  isRejected: false,
  rejectedReason: "",
  nonRoutineTask: {
    title: "",
    detail: "",
    ataChhapterID: "",
    ataSubChapterID: "",
    zoneID: "",
    isNone: "",
    isEWIS: "",
    isRII: "",
    isCriticalTask: "",
    isCDCCL: "",
    isAWLI: "",
    hours: "",
  },
  nonRoutineOptions: null,
  showNonRoutineOptions: false,
  nonRoutineOptionType: "",
  optionsFilterText: "",
  filteredNonRoutineOptions: null,
  filteredNonRoutingSubOptions: null,
  ataChapterID: "",
  ataSubChapterID: "",
  zoneID: "",
  laborItemID: "",
  STSZone: "",
  correctiveAction: "",
  barCode: "",
  showUnservicablePopup: false,
  unservicable: {
    PartNbr: {
      InventoryCD: "",
      InventoryID: 0,
    },
    SerialNbr: "",
    StateShelfLife: "",
    Description: "",
    Quantity: 0,
    Date: "",
    Station: "",
    Owner: "",
    TechLogRef: "",
    Operator: "",
    ReasonforRemoval: "",
  },
  logoU8string: null,
  showCentrikButton: false,
  note: "",
  serviceType: "",
  servicePackageList: [],
  rootServiceProfileOptions: [],
  chosenRootServiceProfileOption: {
    Description: "",
    RootID: "",
    Sortrder: 0,
    ServiceProfileCategoryID: "",
  },
  childServiceProfileOptions: [],
  treeChildServiceProfileDropdowns: [],
  chosenTreeChildServiceProfileOptions: [],
  serviceProfileList: [],
  taskServerSideSearchValue: "",
  chosenProjectIdAction: null,
};

const stateStore = {
  ...initialState,
  ...employeeStore,
  ...projectStore,
  ...stockItemStore,
  ...clockInStore,
};

export default stateStore;
