import { centerText } from "../utilities/document";
import { drawBox } from "./utilities/document";

export const showEquipInfo = (
  doc,
  {
    EquipModel,
    EquipID,
    ZoneID,
    ATAChapterID,
    ATASubChapterID,
    Station,
    STSZone,
    stsZone,
  },
  startX,
  startY
) => {
  const columns = [
    {
      label: "A/C Model",
      value: EquipModel,
    },
    {
      label: "A/C Reg",
      value: EquipID,
    },
    {
      label: "MSN",
    },
    {
      label: "Station",
      value: `${Station ? Station : ""}`,
    },
    {
      label: "ATA Chap",
      value: `${ATAChapterID}${
        ATAChapterID.length > 0 && ATASubChapterID.length > 0 ? " - " : ""
      }${ATASubChapterID}`,
    },
    stsZone
      ? {
          label: "STS Zone",
          value: STSZone,
        }
      : {
          label: "Zone",
          value: ZoneID,
        },
  ];
  let cursorX = startX;

  drawBox(doc, startX, startY, 395, 30);

  cursorX += 5;

  columns.forEach(
    ({
      label,
      value = "",
      options = { bold: true },
      offset = 25,
      show = true,
    }) => {
      if (show) {
        const width = doc.widthOfString(
          label.length > value.length ? label : value
        );
        centerText(doc, label, width, cursorX, startY + 5);
        centerText(doc, value, width, cursorX, startY + 18, options);
        cursorX += width + offset;
      }
    }
  );
};
