import { axios } from ".";

const nonRoutineAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/nonRoutineTask`,
});

async function getServicePackagesApiCall() {
  const response = await nonRoutineAxios({
    method: "get",
    url: "/servicepackage/list",
  });
  return response.data;
}

function addServicePackageApiCall(data) {
  return nonRoutineAxios({ method: "post", url: "/servicepackage/add", data });
}

async function addServiceProfileApiCall(data) {
  const response = await nonRoutineAxios({
    method: "post",
    url: "/serviceprofile/add",
    data,
  });
  return response.data;
}

async function getTreeServiceProfileTopApiCall() {
  const response = await nonRoutineAxios({
    method: "get",
    url: "/dropdowns/serviceprofile/top",
  });
  return response.data;
}

async function getTreeServiceProfileBottomApiCall(RootId) {
  const response = await nonRoutineAxios({
    method: "get",
    url: `/dropdowns/serviceprofile/bottom/${RootId}`,
  });
  return response.data;
}

export {
  nonRoutineAxios,
  getServicePackagesApiCall,
  addServicePackageApiCall,
  addServiceProfileApiCall,
  getTreeServiceProfileTopApiCall,
  getTreeServiceProfileBottomApiCall,
};
