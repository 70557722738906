import { centerText } from "../utilities/document";
import { drawBox } from "./utilities/document";

export const showInfoWithCustomer = (
  doc,
  { ProjectTaskID, Qty, Customer, JobCode, RaisedBy, ServiceProfileCD },
  startX,
  startY
) => {
  const columns = [
    {
      label: "Customer",
      value: Customer,
    },
    {
      label: "Generating Item",
      value: ProjectTaskID,
      value2: ServiceProfileCD,
    },
    {
      label: "Raised By",
      value: RaisedBy,
    },
    {
      label: "Date Raised",
      value: new Date().toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      }),

      options: {
        bold: false,
        fontSize: 8.5,
      },
    },
    {
      label: "Est Man-Hrs",
      value: parseFloat(Qty).toFixed(2),
    },
    {
      label: "Job Code",
      value: JobCode,
    },
  ];
  let cursorX = startX;

  drawBox(doc, cursorX, startY, doc.page.width - 100, 40);

  cursorX += 5;

  columns.forEach(
    ({
      label,
      value,
      value2 = "",
      options = { bold: true, fontSize: 8.5 },
      offset = 10,
    }) => {
      value === null && (value = "");
      const width = doc.widthOfString(
        label.length > value.length ? label : value
      );
      centerText(doc, label, width, cursorX, startY + 5);
      centerText(doc, value, width, cursorX, startY + 18, options);
      value2 && centerText(doc, `(${value2})`, width, cursorX, startY + 30);
      cursorX += width + offset;
    }
  );
};
