import qs from 'qs';

export const createGetEmployeePayload = async (payload) => {
  return new Promise(async (resolve, reject) => {
    const body = {};
    let errorMessage = '';
    if (payload.scannedEmployee) {
      body.$filter = `EmployeeRefNo eq '${payload.scannedEmployee}'`;
    } else {
      errorMessage = 'Missing employee filter'
    }

    if (errorMessage) {
      return reject(errorMessage)
    }
    return resolve(qs.stringify(body));
  })
}