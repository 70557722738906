import { useStoreState } from 'easy-peasy';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { loading, tenant} = useStoreState(state => state);
  return (
    <Route
      {...rest}
      render={() => {
        if(tenant === "sts" && !localStorage.getItem("token") && !loading)
          return <Redirect to='/geography' />
        else if(!localStorage.getItem("token") && !loading && !localStorage.getItem("tenant"))
          return <Redirect to='/login' />
        else
          return<Component />
      }}
    />
  );
};

export default PrivateRoute;
