import React from 'react';
import { Chart } from 'react-google-charts';
// import { useStoreState } from 'easy-peasy';

const Schedule = () => {
  // const { loading } = useStoreState((state) => ({
  //   loading: state.loading,
  // }));

  const daysToMil = (d) => {
    return d * 24 * 60 * 60 * 1000;
  };

  return (
    <Chart
      width={'100%'}
      height={'400px'}
      chartType="Gantt"
      loader={<div>Loading Chart</div>}
      data={[
        [
          { type: 'string', label: 'Task ID' },
          { type: 'string', label: 'Task Name' },
          { type: 'date', label: 'Start Date' },
          { type: 'date', label: 'End Date' },
          { type: 'number', label: 'Duration' },
          { type: 'number', label: 'Percent Complete' },
          { type: 'string', label: 'Dependencies' },
        ],
        [
          '1',
          'N12345',
          new Date(2021, 6, 1),
          new Date(2021, 6, 5),
          daysToMil(3),
          100,
          null,
        ],
        ['2', 'N23456', null, new Date(2021, 6, 9), daysToMil(3), 65, '1,5'],
        ['3', 'N34567', null, new Date(2021, 6, 7), daysToMil(1), 20, '1'],
        ['4', 'N45678', null, new Date(2021, 6, 10), daysToMil(1), 0, '3,2'],
        ['5', 'N56789', null, new Date(2021, 6, 6), daysToMil(1), 100, '1'],
      ]}
      options={{
        gantt: {
          criticalPathEnabled: true,
          criticalPathStyle: {
            stroke: 'red',
            strokeWidth: 5,
          },
        },
        // animation: {
        //   startup: true,
        //   easing: 'linear',
        //   duration: 1500,
        // },
      }}
      // chartEvents={[
      //   {
      //     eventName: 'animationfinish',
      //     callback: () => {
      //       console.log('Animation Finished');
      //     },
      //   },
      // ]}
      rootProps={{ 'data-testid': '1' }}
    />
  );
};

export default Schedule;
