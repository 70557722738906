export const createAddTaskToProjectPayload = (payload) => {
  /*
See servier-side /utils/createAddTaskToProjectPayload.js 
These two processes need to yield same business rules
*/

  return new Promise(async (resolve, reject) => {
    const body = {
      BAccountID: {},
      ContactID: {},
      EmployeeCD: {},
      EmployeeRefNo: {},
      ACMVersion: {},
      WorkOrderID: {},
      ProjectIDInt: {},
      ProjectDescription: {},
      ProjectTaskID: {},
      ProjectTaskIDInt: {},
      ProjectTaskDescription: {},
      TrxDateTime: {},
    };
    let errorMessage = '';

    if (payload.accountId) {
      body.BAccountID.value = payload.accountId;
    } else {
      errorMessage = 'Missing user account ID';
    }
    if (payload.contactId) {
      body.ContactID.value = payload.contactId;
    }
    if (payload.employeeId) {
      body.EmployeeCD.value = payload.employeeId;
    } else {
      errorMessage = 'Missing user employee ID';
    }
    if (payload.employeeRefNo) {
      body.EmployeeRefNo.value = payload.employeeRefNo;
    } else {
      errorMessage = 'Missing user employee Ref No';
    }
    if (payload.acmVersion) {
      body.ACMVersion.value = payload.acmVersion;
    } else {
      errorMessage = 'Missing Acumatica Version in Employee Record';
    }

    if (payload.ProjectCD) {
      body.WorkOrderID.value = payload.ProjectCD;
    } else {
      errorMessage = 'Missing Project CD';
    }
    if (payload.ProjectID) {
      body.ProjectIDInt.value = payload.ProjectID;
    } else {
      errorMessage = 'Missing project ID';
    }
    if (payload.ProjectDescription) {
      body.ProjectDescription.value = payload.ProjectDescription;
    } else {
      errorMessage = 'Missing project description';
    }
    if (payload.TaskCD) {
      body.ProjectTaskID.value = payload.TaskCD;
    } else {
      errorMessage = 'Missing project task ID';
    }
    if (payload.TaskID) {
      body.ProjectTaskIDInt.value = payload.TaskID;
    } else {
      errorMessage = 'Missing task ID';
    }
    if (payload.ProjectTaskDescription) {
      body.ProjectTaskDescription.value = payload.ProjectTaskDescription;
    } else {
      errorMessage = 'Missing project task description';
    }
    if (payload.TrxDateTime) {
      body.TrxDateTime.value = payload.TrxDateTime;
    } else {
      errorMessage = 'Missing project transaction time';
    }

    if (errorMessage) {
      return reject(errorMessage);
    }
    return resolve(body);
  });
};

export const createRemoveTaskFromProjectPayload = async (payload) => {
  return new Promise(async (resolve, reject) => {
    const body = {};
    let errorMessage = '';

    if (payload.status) {
      body.status = payload.status;
    } else {
      errorMessage = 'Missing status';
    }
    if (payload.duration) {
      body.hoursAndMinutes = Math.round(payload.duration);
    } else {
      errorMessage = 'Missing time duration';
    }
    if (payload.noteId) {
      body.noteId = payload.noteId;
    } else {
      errorMessage = 'Missing a note ID';
    }
    if (payload.correctiveAction) {
      body.correctiveAction = payload.correctiveAction;
    }

    if (errorMessage) {
      return reject(errorMessage);
    }
    return resolve(body);
  });
};

export const createPutInspectionRejectionPayload = async (payload) => {
  return new Promise(async (resolve, reject) => {
    const body = {};
    let errorMessage = '';

    if (payload.rejectionDescription) {
      body.rejectionDescription = payload.rejectionDescription;
    } else {
      errorMessage = 'Missing rejection description';
    }

    if (payload.projectNoteId) {
      body.projectNoteId = payload.projectNoteId;
    } else {
      errorMessage = 'Missing project note ID';
    }

    if (payload.taskNoteId) {
      body.taskNoteId = payload.taskNoteId;
    } else {
      errorMessage = 'Missing task note ID';
    }

    if (errorMessage) {
      return reject(errorMessage);
    }
    return resolve(body);
  });
};
