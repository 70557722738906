import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import FirstScanTimer from "../pages/FirstScanTimer";

const Navbar = ({ title, icon }) => {
  const {
    user,
    logo,
    queueScanCountDownIsActive,
    queueLocked,
    mechanicQualified,
  } = useStoreState((state) => ({
    user: state.user,
    logo: state.logo,
    queueScanCountDownIsActive: state.queueScanCountDownIsActive,
    queueLocked: state.queueLocked,
    mechanicQualified: state.qualifications.mechanicQualified,
  }));

  return (
    <div className="navbar navbar-expand-sm navbar-light mb-1">
      <div className="navbar-brand">
        <img
          id="brandingImage"
          src={`https://kioskbranding.blob.core.windows.net/images/${logo}`}
          style={{ width: "250px" }}
          alt=""
        />
      </div>
      {queueScanCountDownIsActive || queueLocked ? (
        <FirstScanTimer />
      ) : (
        <Fragment />
      )}
      <div>
        <ul>
          <li>
            {user ? "Welcome " : ""}
            <br></br>
            <span
              className={
                user && user.validities && mechanicQualified
                  ? "text-success font-weight-bold"
                  : "text-danger font-weight-bold"
              }
              style={{ float: "right" }}
            >
              {user ? user.FirstName + " " + user.LastName : ""}
            </span>
            <br></br>
            <Link to="/schedule">Schedule</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

Navbar.defaultProps = {
  title: "STS Shop Floor Kiosk",
};

export default Navbar;
