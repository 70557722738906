import React, { Fragment } from "react";
import PartRequestListItem from "./partRequestListItem";
import { useStoreState } from "easy-peasy";
import { v4 } from "uuid";

const PartRequestList = () => {
  const { loading, partRequestList, None } = useStoreState((state) => ({
    loading: state.loading,
    partRequestList: state.partRequestList,
    None: state.user.customLabels.partRequest.None,
  }));

  return (
    <Fragment>
      {partRequestList !== null && partRequestList.length && (
        <div className="light card-slim bg-primary mt-3 pb-1">
          <div className="grid-5 my-3">
            <div className=" mt-3 ml-3 text-left">Inventory CD</div>
            <div className=" mt-3 ml-3 text-left">
              {!partRequestList[0].SimpleItemRequest ? "Priority Lvl." : null}
            </div>
            <div className=" mt-3 ml-3 text-left">
              {!partRequestList[0].SimpleItemRequest ? "Need By" : null}
            </div>
            <div className=" mt-3 ml-3 text-left">Quantity</div>
            <div className=" mt-3 ml-3 text-left">
              {!partRequestList[0].SimpleItemRequest ? "Status" : null}
            </div>
          </div>
        </div>
      )}
      <div>
        {!partRequestList && (
          <div>
            <div>
              <h2>{None}</h2>
            </div>
          </div>
        )}
        {partRequestList !== null && !loading && (
          <div>
            {partRequestList.length > 0 &&
              partRequestList.map((p) => (
                <div key={v4()} timeout={500} className="item">
                  <PartRequestListItem part={p} />
                </div>
              ))}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default PartRequestList;
