import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment";

const ProjectTaskItem = ({ task }) => {
  const {
    ProjectDescription,
    ProjectTaskID,
    ProjectTaskDescription,
    TimeUTC,
    TaskStatus,
  } = task;

  const { Title } = useStoreState((state) => state.user.customLabels.task);

  const {
    setPage,
    setCurrentTask,
    setActiveCarouselIndex,
    setPartRequestList,
  } = useStoreActions((actions) => ({
    setPage: actions.setPage,
    setCurrentTask: actions.setCurrentTask,
    setActiveCarouselIndex: actions.setActiveCarouselIndex,
    setPartRequestList: actions.setPartRequestList,
  }));

  const setTask = (e) => {
    setPage("task");
    const task = e.target.getAttribute("value");
    setCurrentTask(task);
    setActiveCarouselIndex(0);
    setPartRequestList(null);
  };

  const getTime = (timeInput) => {
    const offset = new Date().getTimezoneOffset();

    const offsetCorrectedTime = moment(
      new Date(timeInput).toUTCString()
    ).subtract(offset, "minutes");

    //return offsetCorrectedTime.format('dddd YYYY-MM-DD HH:mm a');
    return offsetCorrectedTime.format("llll");
  };

  return (
    <div className="card-slim bg-light mt-1">
      <Link to="/task" onClick={setTask} value={ProjectTaskID.value}>
        <div className="grid-3" value={ProjectTaskID.value}>
          <div className="center-items" value={ProjectTaskID.value}>
            <h4
              className="text-primary text-left project-description"
              value={ProjectTaskID.value}
            >
              {Title} {ProjectTaskID.value}
            </h4>
            <h6>
              Status:{" "}
              <span
                className={
                  TaskStatus.value === "Active"
                    ? "text-success"
                    : TaskStatus.value === "Inspection"
                    ? "text-warning"
                    : TaskStatus.value === "Reject"
                    ? "text-danger"
                    : "text-dark"
                }
              >
                {TaskStatus.value}
              </span>
            </h6>
          </div>
          <div
            className="my-2 center-items"
            style={{ gridColumn: "2/-1" }}
            value={ProjectTaskID.value}
          >
            <div className="my-2" value={ProjectTaskID.value}>
              {/* {ProjectDescription.value} - Open Since {getTime(Time.value)} */}
              {ProjectDescription.value} - Open Since {getTime(TimeUTC.value)}
            </div>

            <div className="my-2" value={ProjectTaskID.value}>
              {ProjectTaskDescription.value}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

ProjectTaskItem.propTypes = {
  task: PropTypes.object.isRequired,
};

export default ProjectTaskItem;
