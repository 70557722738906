import { centerText } from '../utilities/document';
import { DEFAULT_FONT_SIZE, drawBox } from './utilities/document';
import wrap from 'word-wrap';

export const showInspector = (
  doc,
  startX,
  startY,
  { ReleaseStatement },
  options = { fontSize: 7.5 }
) => {
  const boxWidth = doc.page.width - 50 - startX;
  const boxHeight = 183;

  drawBox(doc, startX, startY + 1, boxWidth, boxHeight, {
    lineWidth: 2,
  });

  doc.fontSize(8);
  centerText(doc, 'INSPECTOR', boxWidth, startX, startY + 5, options);
  centerText(doc, '/ Date', boxWidth, startX, doc.y + 12, options);
  doc.fontSize(6);
  centerText(doc, '(DD/MM/YYYY)', boxWidth, startX, doc.y + 12, options);

  doc.fontSize(DEFAULT_FONT_SIZE);

  drawBox(doc, 50.5, 485, doc.page.width - 100.5, 50, {
    lineWidth: 2,
  });

  // Wrap the title into multiple lines
  const title = wrap(ReleaseStatement.toUpperCase(), { width: 90 });
  const wordsArray = title.split('\n');
  let yIndex = 14;

  for (let i = 0; i < wordsArray.length; i++) {
    doc
      .fontSize(8.75)
      .text(wordsArray[i], 52, 478 + yIndex)
      .fontSize(DEFAULT_FONT_SIZE);
    yIndex += 10;
  }

  // doc.font('Helvetica-Bold').fontSize(8.75);
  // wordsArray.every((n) => {
  //   yIndex = yIndex + 0.1;
  //   return doc.text(n, 55, 490, doc.y + yIndex - 1);
  // });

  // doc
  //   .fontSize(8.75)
  //   .text(
  //     'THE WORK SPECIFIED EXCEPT AS OTHERWISE SPECIFIED HAS BEEN PERFORMED TO THE STANDARDS REQUIRED',
  //     55,
  //     490
  //   )
  //   .text(
  //     'BY STS MAINTENANCE ORGANISATION EXPOSITION REF. STS/MOE/01 UNDER EASA PART 145 APPROVAL',
  //     55,
  //     doc.y + 10
  //   )
  //   .text('EASA.UK.145.01251.', 55, doc.y + 10)
  //   .fontSize(DEFAULT_FONT_SIZE);

  drawBox(doc, startX + 3, startY + boxHeight + 1, boxWidth - 6, 1, {
    lineWidth: 4,
    strokeColor: 'white',
  });
};
