import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useStoreActions, useStoreState } from "easy-peasy";

const StockItemResultItem = ({ stockItem }) => {
  const { currentTask, company, user } = useStoreState((state) => ({
    currentTask: state.currentTask,
    company: state.user.CompanyAbrv,
    user: state.user,
  }));

  const customLabels = user && user.customLabels ? user.customLabels : null;

  const defineClass = (value) => {
    let textClass = "text-success";
    parseInt(value, 10) <= 0 && (textClass = "text-danger");
    return textClass;
  };

  const {
    setStockItemToAdd,
    setShowStockItemSearchResults,
    cleanupStockItemSearchResultsModal,
    setShowStockItemQty,
    updateUnservicable,
    setShowUnservicablePopup,
    searchItems,
  } = useStoreActions((actions) => ({
    setStockItemToAdd: actions.setStockItemToAdd,
    setShowStockItemSearchResults: actions.setShowStockItemSearchResults,
    cleanupStockItemSearchResultsModal:
      actions.cleanupStockItemSearchResultsModal,
    setShowStockItemQty: actions.setShowStockItemQty,
    updateUnservicable: actions.updateUnservicable,
    setShowUnservicablePopup: actions.setShowUnservicablePopup,
    searchItems: actions.searchItems,
  }));

  const addToStockItemRequest = (e) => {
    setStockItemToAdd(stockItem);
    setShowStockItemQty(true);
    toggle();
  };

  const toggle = () => {
    setShowStockItemSearchResults(false);
    cleanupStockItemSearchResultsModal();
  };

  const onShowUnserviceablePartForm = () => {
    const { InventoryCD, InventoryID, Description } = stockItem;
    updateUnservicable({
      PartNbr: {
        InventoryCD,
        InventoryID,
      },
      Description,
    });
    setShowUnservicablePopup(true);
  };

  const substituteLabels = (item) => {
    return (
      <span
        className="ml-2"
        onClick={() =>
          searchItems({
            searchValue: item.toString(),
            cust: currentTask.CustomerWarehouse.value,
            comp: currentTask.CompanyWarehouse.value,
          })
        }
        style={{ cursor: "pointer" }}
      >
        {item}
      </span>
    );
  };

  return (
    <Fragment>
      <li className="results-list">
        <div
          className={
            "mb-2 " +
            (currentTask.unserviceableParts ? "grid-3-btn" : "grid-1-btn")
          }
        >
          <div className="text-success mt-2 ml-2 text-left">
            {stockItem.InventoryCD.trim()}
            <span className="text-dark">{" - "}</span>

            {
              <span className="text-primary text-left project-description">
                {stockItem.Description.trim()} ({stockItem.BaseUnit})
                <br />
                {!stockItem.SimpleItemRequest ? (
                  <span className="ml-3 text-dark">
                    On Hand:
                    {" " + company}
                    {" ("}
                    <span className={defineClass(stockItem.CompanyQty)}>
                      {parseInt(stockItem.CompanyQty, 10) || 0}
                    </span>
                    {")"}
                    <br></br>
                    <span className="ml-4">
                      {currentTask.Customer.value}
                      {" ("}
                      <span className={defineClass(stockItem.CustomerQty)}>
                        {parseInt(stockItem.CustomerQty, 10) || 0}
                      </span>

                      {")"}
                    </span>
                    <br></br>
                    {currentTask.ShowTotalQty &&
                    currentTask.ShowTotalQty.value ? (
                      <span className="ml-4">
                        {"Total Available: ("}
                        <span className={defineClass(stockItem.TotalOnHand)}>
                          {parseInt(stockItem.TotalQty)}
                        </span>
                        {")"}
                      </span>
                    ) : null}
                  </span>
                ) : (
                  <span className="ml-3 text-dark">
                    On Hand:{"  "}
                    <span className={defineClass(stockItem.CompanyQty)}>
                      {parseInt(stockItem.CompanyQty, 10) || 0}
                    </span>
                  </span>
                )}
              </span>
            }

            {/* {currentTask.ShowTotalQty.value ===
          true ? (
            <>
              {' / Total Available: ('}
              <span className={defineClass(part.TotalQty)}>
                {parseInt(part.TotalQty) || 0}
              </span>
              {')'}
            </>
          ) : null}
        </div> */}

            {
              // If substitute parts equals 'NoSub' then don't show the button}
            }
            {currentTask.substituteParts &&
            stockItem.SubtituteItemCD &&
            typeof stockItem.SubtituteItemCD === "string" &&
            stockItem.SubtituteItemCD !== "NoSub" ? (
              <>
                <br />
                <h6
                  className="text mt-2 ml-2 text-left"
                  style={{ color: "grey" }}
                >
                  Substitute Parts:{" "}
                </h6>

                {stockItem.SubtituteItemCD.split(",").map((item, index) => (
                  <div key={index}>{substituteLabels(item)}</div>
                ))}
              </>
            ) : null}
          </div>
          <button
            className="btn btn-warning"
            onClick={addToStockItemRequest}
            value={stockItem.InventoryID}
          >
            Add To Request
          </button>
          {currentTask.unserviceableParts && (
            <button
              className="btn btn-primary"
              onClick={onShowUnserviceablePartForm}
            >
              {customLabels &&
              customLabels.unserviceablePart &&
              customLabels.unserviceablePart.Title
                ? "Add To " + customLabels.unserviceablePart.Title + " Request"
                : "Add To Unserviceable Part Request"}
            </button>
          )}
        </div>
      </li>
    </Fragment>
  );
};

StockItemResultItem.propTypes = {
  stockItem: PropTypes.object.isRequired,
};

export default StockItemResultItem;
