import React, { Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import StockItemResultItem from "./stockItemResultItem";
import { useStoreState, useStoreActions } from "easy-peasy";
//import { v4 } from 'uuid';

const PartResults = () => {
  const {
    filteredStockItems,
    loading,
    showStockItemSearchResults,
    user,
    currentTask,
    SearchTitle,
    Title,
  } = useStoreState((state) => ({
    filteredStockItems: state.filteredStockItems,
    loading: state.loading,
    showStockItemSearchResults: state.showStockItemSearchResults,
    user: state.user,
    currentTask: state.currentTask,
    SearchTitle: state.user.customLabels.partRequest.SearchTitle,
    Title: state.user.customLabels.addUnserviceablePart.Title,
  }));

  const {
    setShowStockItemSearchResults,
    cleanupStockItemSearchResultsModal,
    setShowAddNewStockItem,
    setShowAddUnsvcStockItem,
  } = useStoreActions((actions) => ({
    setShowStockItemSearchResults: actions.setShowStockItemSearchResults,
    cleanupStockItemSearchResultsModal:
      actions.cleanupStockItemSearchResultsModal,
    setShowAddNewStockItem: actions.setShowAddNewStockItem,
    setShowAddUnsvcStockItem: actions.setShowAddUnsvcStockItem,
  }));

  const toggle = () => {
    setShowStockItemSearchResults(false);
    cleanupStockItemSearchResultsModal();
  };

  const addNewStockItem = () => {
    toggle();
    setShowAddNewStockItem(true);
  };

  const addNewUnsvcStockItem = () => {
    toggle();
    setShowAddUnsvcStockItem(true);
  };

  return (
    <Fragment>
      {filteredStockItems !== null && !loading && (
        <Modal
          isOpen={showStockItemSearchResults}
          centered={true}
          size={"lg"}
          backdrop={true}
        >
          <ModalHeader>{SearchTitle} Search Results</ModalHeader>
          <ModalBody className="text-center">
            <div className="mb-4 text-primary">
              Select the part to add to the Parts Request List
            </div>
            <div style={{ height: "50vh", overflowY: "scroll" }}>
              <ul>
                {filteredStockItems.length ? (
                  filteredStockItems.map((p) => (
                    <StockItemResultItem stockItem={p} key={p.id} />
                  ))
                ) : (
                  <div className="mb-4 text-danger">
                    No Inventory Items Found
                  </div>
                )}
              </ul>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="grid-2 w-100">
              <Button
                color="light"
                style={{ display: "block", width: "100%" }}
                onClick={toggle}
              >
                Close
              </Button>
              {filteredStockItems &&
              filteredStockItems.length &&
              filteredStockItems[0].SimpleItemRequest ? (
                <div></div>
              ) : (
                <Button
                  color="danger"
                  style={{ display: "block", width: "100%" }}
                  onClick={addNewStockItem}
                  disabled={!user.PartNotFoundEnable}
                >
                  {user.PartNotFoundEnable
                    ? "Part Not Found Item Request"
                    : "Not Authorized"}
                </Button>
              )}
            </div>
            {user.PartNotFoundEnable && currentTask.unserviceableParts && (
              <Button
                color="warning"
                style={{ width: "100%" }}
                onClick={addNewUnsvcStockItem}
              >
                {Title}
              </Button>
            )}
          </ModalFooter>
        </Modal>
      )}
    </Fragment>
  );
};

export default PartResults;
