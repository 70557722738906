import { DEFAULT_FONT, DEFAULT_FONT_SIZE, drawBox } from "./utilities/document";

export const showOverview = (
  doc,
  { blob, barcodeBlob, NonRoutineTaskCD },
  startX,
  startY
) => {
  drawBox(doc, startX, startY, doc.page.width - 100, 70);

  doc.image(blob, startX - 20, startY + 15, {
    fit: [150, 150],
  });

  doc
    .fontSize(10)
    .text("NON ROUTINE TASK CARD", startX + 150, startY + 57)
    .fontSize(DEFAULT_FONT_SIZE);

  doc
    .text("Non Routine: ", startX + 325, startY + 7, {
      continued: true,
    })
    .font("Helvetica-Bold")
    .text(NonRoutineTaskCD, doc.x, doc.y)
    .font(DEFAULT_FONT);

  doc.image(barcodeBlob, startX + 295, startY + 25, {
    width: 215,
    height: 40,
  });
};
