import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';

const FirstScanTimer = () => {
  const { queueScanLockSeconds, queueScanCountDownIsActive, queueLocked } =
    useStoreState((state) => ({
      queueScanLockSeconds: state.queueScanLockSeconds,
      queueScanCountDownIsActive: state.queueScanCountDownIsActive,
      queueLocked: state.queueLocked,
    }));

  const {
    setQueueScanCountDownIsActive,
    setQueueScanLockSeconds,
    setQueueLocked,
  } = useStoreActions((actions) => ({
    setQueueScanCountDownIsActive: actions.setQueueScanCountDownIsActive,
    setQueueScanLockSeconds: actions.setQueueScanLockSeconds,
    setQueueLocked: actions.setQueueLocked,
  }));

  function toggle() {
    setQueueScanCountDownIsActive(!queueScanCountDownIsActive);
  }

  useEffect(() => {
    function reset() {
      setQueueScanLockSeconds(180);
      setQueueScanCountDownIsActive(false);
    }

    let interval = null;
    if (queueScanCountDownIsActive) {
      //debugger;
      interval = setInterval(() => {
        setQueueScanLockSeconds(queueScanLockSeconds - 1);
      }, 1000);
    } else if (!queueScanCountDownIsActive && queueScanLockSeconds !== null) {
      clearInterval(interval);
    }
    if (queueScanLockSeconds !== 0) {
      return () => clearInterval(interval);
    } else {
      reset();
      setQueueLocked();
      return () => clearInterval(interval);
    }
  }, [
    queueScanCountDownIsActive,
    queueScanLockSeconds,
    setQueueLocked,
    setQueueScanLockSeconds,
    setQueueScanCountDownIsActive,
  ]);

  return (
    <div className="mt-1 h2 text-danger" onClick={toggle}>
      {!queueLocked
        ? `Queue will lock in ${queueScanLockSeconds}s`
        : 'Queue is locked'}
    </div>
  );
};

export default FirstScanTimer;
