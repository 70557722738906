import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { Fragment } from 'react';
// import DatePicker from 'react-datepicker';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const AddUnsvcStockItem = () => {
  const {
    loading,
    priorityLevelOptions,
    showAddUnsvcStockItem,
    partNotFoundItem,
    user,
  } = useStoreState((state) => ({
    loading: state.loading,
    priorityLevelOptions: state.priorityLevelOptions,
    showAddUnsvcStockItem: state.showAddUnsvcStockItem,
    partNotFoundItem: state.partNotFoundItem,
    user: state.user,
  }));

  const {
    setPartNotFoundItem,
    setShowAddUnsvcStockItem,
    addUnsvcStockItemThunk,
  } = useStoreActions((actions) => ({
    setPartNotFoundItem: actions.setPartNotFoundItem,
    setShowAddUnsvcStockItem: actions.setShowAddUnsvcStockItem,
    addUnsvcStockItemThunk: actions.addUnsvcStockItemThunk,
  }));

  let { InventoryID, Description, /*Qty, UsrNeedBy,*/ note } = partNotFoundItem;

  const toggle = () => {
    setShowAddUnsvcStockItem(false);
  };

  const addUnsvcStockItem = () => {
    addUnsvcStockItemThunk(partNotFoundItem);
  };

  const customLabels =
    user && user.customLabels && user.customLabels.addUnserviceablePart
      ? user.customLabels.addUnserviceablePart
      : null;

  const onChange = (e) => {
    let value = e.target.value;

    if (e.target.name === 'UsrPriorityLevel') {
      const matchedOption = priorityLevelOptions.find(
        (plo) => plo.Description === value
      );

      if (matchedOption) {
        value = matchedOption.PriorityLevel;
      }
    }
    if (e.target.name === 'InventoryID') {
      //value = value.replace(/[^A-Za-z\d./\-"]/g, '');
      //let reqStr = '[^A-Za-zd./-]';
      //const regex = new RegExp(reqStr, 'g');
      value = value.replace(/[^A-Za-z\d./"-]/g, '');
    }
    setPartNotFoundItem({ key: e.target.name, value });
  };

  // const setDate = (e) => {
  //   setPartNotFoundItem({
  //     key: 'UsrNeedBy',
  //     value: e,
  //   });
  // };

  const onNotAllowed = (e) => {
    e.preventDefault();
    debugger;
    return false;
  };

  return (
    <Fragment>
      {!loading && (
        <Modal
          isOpen={showAddUnsvcStockItem}
          centered={true}
          size={'lg'}
          backdrop={true}
        >
          <ModalHeader>
            {customLabels && customLabels.Title
              ? customLabels.Title
              : 'Add Unserviceable Stock Item'}
          </ModalHeader>
          <ModalBody className="text-center">
            <div style={{ height: '50vh', overflowY: 'scroll' }}>
              <form>
                <label
                  htmlFor="inventoryID"
                  className="text-left"
                  style={{ width: '100%' }}
                >
                  <span className="text-danger">* </span>
                  Inventory ID
                </label>
                <input
                  className="mt-0"
                  name="InventoryID"
                  type="text"
                  placeholder="Inventory ID (required)"
                  value={InventoryID}
                  onChange={onChange}
                  onPaste={onNotAllowed}
                />
                <label
                  htmlFor="description"
                  className="text-left"
                  style={{ width: '100%' }}
                >
                  <span className="text-danger">* </span>
                  Description
                </label>
                <input
                  className="mt-0"
                  name="Description"
                  type="text"
                  placeholder="Description (required)"
                  value={Description}
                  onChange={onChange}
                  // ref={Description}
                  required={true}
                ></input>
                <label
                  htmlFor="notes"
                  className="text-left"
                  style={{ width: '100%' }}
                >
                  Notes for Purchasing
                </label>
                <textarea
                  placeholder="Notes"
                  value={note}
                  name="note"
                  className="mt-0 mb-4"
                  // ref={note}
                  onChange={onChange}
                />
                {/* <input
                      className="m-3 p-2 text-indent"
                      name="Qty"
                      type="number"
                      placeholder="Quantity (required)"
                      value={Qty}
                      onChange={onChange}
                      // ref={Qty}
                      required={true}
                    />
                    <div className="select-custom-wrapper">
                      <select
                        className="select-custom-large text-indent"
                        onChange={onChange}
                        name="UsrPriorityLevel"
                        defaultValue={'DEFAULT'}
                      >
                        <option value="DEFAULT" disabled>
                          Select Priority Level
                        </option>
                        {priorityLevelOptions.map((plo) => (
                          <option key={plo.PriorityLevel} value={plo.PriortyLevel}>
                            {plo.Description}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <DatePicker
                        className="datepicker-custom-large text-indent"
                        selected={UsrNeedBy}
                        onChange={setDate}
                        placeholderText="Need By Date"
                      />
                    </div> */}
              </form>
            </div>
          </ModalBody>
          <ModalFooter>
            <div
              className="grid-3"
              style={{
                width: '100%',
              }}
            >
              <Button
                color="success"
                style={{
                  display: 'block',
                  width: '100%',
                  gridColumn: '1 / span 2',
                }}
                onClick={addUnsvcStockItem}
                disabled={InventoryID === '' || Description === ''}
              >
                {customLabels && customLabels.Title
                  ? customLabels.Title
                  : 'Add Unserviceable Stock Item'}
              </Button>
              <Button
                color="danger"
                style={{ display: 'block', width: '100%' }}
                onClick={toggle}
              >
                Cancel
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </Fragment>
  );
};

export default AddUnsvcStockItem;
