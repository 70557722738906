import { centerText } from "../utilities/document";
import { drawBox } from "./utilities/document";

export const showCalibratedTools = (doc, startX, startY) => {
    doc.text("Calibrated Tool(s) Required:", startX + 5, startY);

    const [tableStartY, cellWidth, cellHeight] = [doc.y + 15, 128, 15];
    const tableCells = [
        ["Tool Description", "", ""],
        ["ID No.", "", ""],
        ["Calibration Due Date", "", ""],
    ];

    let tableCursorX = startX;
    doc.fontSize(9);
    tableCells.forEach(row => {
        let tableCursorY = tableStartY;
        row.forEach(textInCell => {
            drawBox(doc, tableCursorX, tableCursorY, cellWidth, cellHeight);
            centerText(doc, textInCell, cellWidth, tableCursorX, tableCursorY + 4);
            tableCursorY += cellHeight;
        });
        tableCursorX += cellWidth;
    });
    drawBox(doc, tableCursorX, tableStartY, cellWidth, cellHeight * tableCells[0].length);
    fillLastCell(doc, tableCursorX, tableStartY);
}

const fillLastCell = (doc, startX, startY) => {
    doc.text("Additional NRC Raised:", startX + 5, startY + 6);

    const checkboxLabels = [
        "No NRC Raised",
        "NRC Raised",
    ];

    let y = startY + 17;
    checkboxLabels.forEach(label => {
        drawBox(doc, startX + 3, y, 7, 7);
        doc.text(label, startX + 13, y);
        y += 12;
    })

}