const checkQualifications = (
  task,
  qualifications,
  qualificationName,
  inspectorQualified
) => {
  /*
See servier-side /utils/checkQualifications.js 
These two processes need to yield same business rules
*/
  qualifications = qualifications || [];
  const len = qualifications.length;
  const taskStatusToCheckTypes = ['Inspection', 'Supervisor'];
  qualificationName !== 'type' &&
    (task.Qualifications = task.Qualifications.split(','));

  task.Qualifications.forEach((q) => {
    qualificationName !== 'type' &&
      q.length &&
      qualifications.indexOf(q) === -1 &&
      q !== 'NONE' &&
      taskStatusToCheckTypes.includes(task.TaskStatus) &&
      (task.qualified = false);
  });

  if (
    qualificationName === 'type' &&
    taskStatusToCheckTypes.includes(task.TaskStatus)
  ) {
    for (let i = 0; i < len; i++) {
      let test = 0;
      const q = qualifications[i];

      /*  FOR TESTING STS Provided:
      (Not Expiring Records):
      Brian Adkins : 001e581c-da6e-48c9-85d0-3dd21e9cfee5
      Sam Hughes:  a834e660-fa07-4e68-a3e2-99d44626fe2a  

      (Expired Records):
      Walter Thompson: 802c4c4e-5ea7-4d5c-85e2-5cc87b7d053c  
      Mark Lacey (QA044 expired) : 18ed2205-1cb8-4aa4-a5b8-4f4735571052
      */

      test =
        test + q.make.toUpperCase().includes(task.aircraftMfg.toUpperCase());
      console.log(
        `Task: ${task.TaskCD.trim()} -"${q.make.toUpperCase()}".includes("${task.aircraftMfg.toUpperCase()}") : ${q.make
          .toUpperCase()
          .includes(task.aircraftMfg.toUpperCase())}`
      );

      test =
        test +
        q.model.toUpperCase().includes(task.aircraftModelCD.toUpperCase());
      console.log(
        `Task: ${task.TaskCD.trim()} - "${q.model.toUpperCase()}".includes("${task.aircraftModelCD.toUpperCase()}") :${q.model
          .toUpperCase()
          .includes(task.aircraftModelCD.toUpperCase())}`
      );

      test =
        test +
        q.engine.toUpperCase().includes(task.aircraftEngineCD.toUpperCase());
      console.log(
        `Task: ${task.TaskCD.trim()} - "${q.engine.toUpperCase()}".includes("${task.aircraftEngineCD.toUpperCase()}") :${q.engine
          .toUpperCase()
          .includes(task.aircraftEngineCD.toUpperCase())}`
      );

      if (task.TaskStatus === 'Supervisor') {
        //STOPPING HERE FOR SUPERVISOR TASKS.  NEED TO MAKE SURE B1 OR B2 RESPECTIVELY AND QA44 IS VALID
        const { B1, B2 } = inspectorQualified;
        const QA44 = inspectorQualified['Authorisation QA44'];
        //logic around what is B1 and B2 mandatory
        if (
          (task.InspectorRating.trim() === 'B1' && !B1) ||
          (task.InspectorRating.trim() === 'B2' && !B2) ||
          !QA44
        ) {
          test = 0;
        }
      }

      if (test === 3) {
        task.qualified = true;
        console.log(
          `Task: ${task.TaskCD.trim()} - matched Centrik qualifications`
        );
        break;
      }
    }
  }

  return task;
};

export default checkQualifications;
